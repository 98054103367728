import React from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";

function IdeasReason() {
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Why Erste Ideas?"} text={"The platform which enables you to " +
                "submit your own idea or collaborate with others."} progress={54} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="flex flex-col lg:grid lg:grid-cols-3 lg:gap-x-10 justify-items-center">
                        <div className="col-span-2">
                            <img src={envVariables.IMG_SRC+"/images/IdeasWhyMobile.png"} alt="Ideas guide"
                                 className="ideasWhyImage md:hidden"/>
                            <img src={envVariables.IMG_SRC+"/images/IdeasWhy.png"} alt="Ideas guide"
                                 className="ideasWhyImage hidden md:block"/>
                        </div>
                        <div>
                            <h1 className="entertainmentHead mb-2 mt-0 lg:mb-0 lg:mt-0">Reasons to try Erste Ideas:</h1>
                            <div className="flex items-center my-3 lg:my-2 1.5xl:mb-5 1.5xl:mt-10">
                                <img src={envVariables.IMG_SRC+"/images/warning.png"} alt="Ideas 1"
                                     className="w-[20px] h-[20px] lg:w-[40px] lg:h-[40px] mr-5"/>
                                <p className="m-0 text-sm lg:text-base">
                                    Only for Erste employees</p>
                            </div>
                            <p className="ideasWhyText">
                                - you will be able to create new ideas, see other, share, comment and like them
                            </p>
                            <p className="ideasWhyText">
                                - you will receive feedback
                            </p>
                            <p className="ideasWhyText">
                                - you will get the help from other people, who also want to contribute
                            </p>
                            <p className="ideasWhyText">
                                - you will see other people’s ideas
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <PageNavigation previous="learning" next="ideas"/>
        </div>
    )
}
export default IdeasReason;