import React, {useContext, useState} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {JigsawPuzzle} from "react-jigsaw-puzzle";
import 'react-jigsaw-puzzle/lib/jigsaw-puzzle.css';
import {Link} from "react-router-dom";
import {UserContext} from "../context/UserProvider";
import CustomStoryDialog from "../components/dialog/CustomStoryDialog";
import {envVariables} from "../constants/Variables";

function Puzzle() {
    const [difficulty, setDifficulty] = useState(1)
    const [finished, setFinished] = useState(false)
    const userContext = useContext(UserContext)

    const handleChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setDifficulty(Number(e.target.value))
        setFinished(false)
    }

    const finishGame = () => {
        setFinished(true)
    };

    return (
        <div className="pageWrapper bg-secondary">
            <CustomStoryDialog title="Play time!"
                               text="Puzzles are a great way to relax and to have fun. You’ll be able
                               to choose from 3 different modes! In the end, you’ll receive a small reward: background
                                for Microsoft Teams with Erste logo."/>
            <NavBar image={"/navbar/toDo.svg"} header={"Solve a puzzle"}
                    text={"Let’s be better prepared for solving riddles :D"} progress={39} category={"team"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner flex flex-col lg:grid grid-cols-5 1.5lg:grid-cols-6
                xl:grid-cols-5 2xl:grid-cols-4 place-content-start ">
                    {!finished ? (
                        <div className="col-span-3 order-2 lg:order-1" id="puzzle">
                            <JigsawPuzzle
                                imageSrc={envVariables.IMG_SRC+"/images/characters/puzzle/" + userContext.character + "/PuzzleImage.png"}
                                rows={2*difficulty}
                                columns={2*difficulty}
                                onSolved={finishGame}
                            />
                        </div>
                    ) : (
                        <div className="col-span-3 order-2 lg:order-1">
                            <img src={envVariables.IMG_SRC+"/images/characters/puzzle/" + userContext.character + "/PuzzleImage.png"} alt="Puzzle"
                                 className="max-w-[100%] lg:h-[60vh] xl:h-[70vh] 2xl:h-[73vh]"/>
                        </div>
                    )}
                    <div className="flex justify-between lg:block order-1 lg:order-2 mb-5 lg:mb-0 lg:ml-10 lg:col-span-2
                     1.5lg:col-span-3 xl:col-span-2 1.5xl:col-span-2 2xl:col-span-1">
                        <div>
                            <h3 className="mt-0">Change difficulty</h3>
                            <FormControl className="lg:w-52 flex self-center mb-5">
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select
                                    className="text-black"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={difficulty.toString()}
                                    label=""
                                    onChange={handleChange}
                                    color={"primary"}
                                >
                                    <MenuItem value={1}>Easy</MenuItem>
                                    <MenuItem value={2}>Medium</MenuItem>
                                    <MenuItem value={3}>Hard</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {finished ? (
                            <div>
                                <img src={envVariables.IMG_SRC+"/images/characters/puzzle/" + userContext.character + "/FinishedPuzzle.png"}
                                     alt="Finished puzzle dialog"
                                     className="puzzleCharacter"/>
                                <div className="flex justify-end mt-5">
                                    <Button component={Link} to={'/background'} variant="contained" color="warning" className={"teamButton"}>
                                        Get your background</Button>
                                </div>
                            </div>
                        ) : (
                            <img src={envVariables.IMG_SRC+"/images/characters/puzzle/" + userContext.character + "/UnfinishedPuzzle.png"}
                                 alt="Unfinished puzzle dialog"
                                 className="puzzleCharacter"/>
                        )}
                    </div>
                </div>
            </div>
            {userContext.isAuth ? (
                <PageNavigation skip={true} previous="team" next="employeeBenefits"/>
            ) : (
                <PageNavigation skip={true} previous="eat" next="employeeBenefits"/>
            )}
        </div>
    )
}
export default Puzzle;