import React from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";

export default function Offices() {
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Our other offices"}
                    text={"We also have offices in other places. Unfortunately VR is not available for them yet."}
                    progress={16} category={"start"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <div>
                            <div className="flex justify-center">
                                <img src={envVariables.IMG_SRC+"/images/offices/cassovar.png"} alt="Lakeside VR"
                                     className="officeImage"/>
                            </div>
                            <h1 className="vHead">Cassovar, Košice</h1>
                            <p className="officeText">We are proud to announce our growth and expansion to #makeITmatter in a new city! In April 2023, we celebrated another big step in the #ErsteDigital history - our new branch in Košice.</p>
                        </div>
                        <div className="mt-5 lg:mt-0">
                            <div className="flex justify-center">
                                <img src={envVariables.IMG_SRC+"/images/offices/prague.png"} alt="Campus VR"
                                     className="officeImage"/>
                            </div>
                            <h1 className="vHead">Antala Staška, Prague</h1>
                            <p className="officeText">A modern office building next to one of Europe’s most beautiful cities, Prague, surrounded by a variety of dining options and cafes.</p>
                        </div>
                    </div>
                </div>
            </div>
            <PageNavigation previous="vr" next="workplace"/>
        </div>
    )
}
