import React, {useContext, useState} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {UserContext} from "../context/UserProvider";
import {envVariables} from "../constants/Variables";
import {Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import {saveAs} from "file-saver";
const ReactPDF = require('react-pdf');

ReactPDF.pdfjs.GlobalWorkerOptions.workerSrc =`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${ReactPDF.pdfjs.version}/pdf.worker.js`;

export default function Documents() {
    const [currentFile, setCurrentFile] = useState<number>(0);
    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const listOfFiles = ["HR_Management_and_Compensation_Policy", "Pracovny_poriadok", "Riadenie_LZ_a_mzdova_politika", "skolenie_BOZP_OPP_ED",
        "Training_OPP_BOZP_English", "Work_regulations"];
    const userContext = useContext(UserContext)

    function onDocumentLoadSuccess(numPages : number) {
        setNumPages(numPages);
    }

    function changePage() {
        setPageNumber(pageNumber + 1);
    }

    const prevPage = () => {
        setPageNumber(pageNumber - 1)
    }

    const changeDocument = (e : { target: { value: React.SetStateAction<string>; }; }) => {
        setCurrentFile(parseInt(e.target.value.toString()));
        setPageNumber(1);
        console.log(numPages.valueOf())
    }

    const downloadDocument = () => {
        let imageUrl = envVariables.IMG_SRC+"/images/pdfFiles/" + listOfFiles[currentFile] + ".pdf"
        saveAs(imageUrl, listOfFiles[currentFile])
    }

    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Important documents"}
                    text={"You can choose to receive card for bicycle cage in the garage and/or card for parking with your car!"}
                    progress={25} category={"start"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className={"flex justify-center mb-5"}>
                        <h3>Select the document you want to read:</h3>
                        <FormControl className="ml-5">
                            <InputLabel id="demo-simple-select-label"></InputLabel>
                            <Select
                                className="text-black"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={currentFile.toString()}
                                label=""
                                onChange={changeDocument}
                                color={"primary"}
                            >
                                <MenuItem value={0}>{listOfFiles[0]}</MenuItem>
                                <MenuItem value={1}>{listOfFiles[1]}</MenuItem>
                                <MenuItem value={2}>{listOfFiles[2]}</MenuItem>
                                <MenuItem value={3}>{listOfFiles[3]}</MenuItem>
                                <MenuItem value={4}>{listOfFiles[4]}</MenuItem>
                                <MenuItem value={5}>{listOfFiles[5]}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className={"flex justify-between mb-5"}>
                        <Button variant="contained" color="warning" className={"startButton"} onClick={prevPage}>Previous</Button>
                        <Button className={"startButton"} onClick={downloadDocument}>Download</Button>
                        <Button className={"startButton"} onClick={changePage}>Next</Button>
                    </div>
                    <div className={"flex justify-center overflow-auto max-h-[50vh]"}>
                        <ReactPDF.Document file={envVariables.IMG_SRC+"/images/pdfFiles/" + listOfFiles[currentFile] + ".pdf"} onLoadSuccess={onDocumentLoadSuccess} >
                            <ReactPDF.Page pageNumber={pageNumber} height={800} renderTextLayer={false} />
                        </ReactPDF.Document>
                    </div>



                </div>
            </div>
            {userContext.isAuth ? (
                <PageNavigation previous="firstDay" next="foodBenefit"/>
            ) : (
                <PageNavigation previous="firstDay" next="eat"/>
            )}
        </div>
    )
}