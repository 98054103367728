import React, {createContext, Dispatch, ReactNode, SetStateAction, useState} from "react";
interface step {
    id: string;
    step_id:number;
    step_status:number;
    name:string;
}

interface UserContextInterface {
    userName: string;
    setUserName: Dispatch<SetStateAction<string>>;
    firstName: string;
    setFirstName: Dispatch<SetStateAction<string>>;
    isAuth: boolean;
    setIsAuth: Dispatch<SetStateAction<boolean>>;
    character: string;
    setCharacter: Dispatch<SetStateAction<string>>;
    role: string;
    setRole: Dispatch<SetStateAction<string>>;
    token: string;
    setToken: Dispatch<SetStateAction<string>>;
    id: string;
    setId: Dispatch<SetStateAction<string>>;
    couponUrl: string;
    setCouponUrl: Dispatch<SetStateAction<string>>;
    location: string;
    setLocation: Dispatch<SetStateAction<string>>;
    steps: Array<step>;
    setSteps: Dispatch<SetStateAction<Array<step>>>;
    skip: boolean;
    setSkip: Dispatch<SetStateAction<boolean>>;
}

const storageUserName = localStorage.getItem("userName");
const storageFirstName = localStorage.getItem("firstName");
const storageAuth = localStorage.getItem("isAuth");
const storageCharacter = localStorage.getItem("character");
const storageRole = localStorage.getItem("userRole");
const storageToken = localStorage.getItem("token");
const storageId = localStorage.getItem("id");
const storageCouponUrl = localStorage.getItem("couponUrl");
const storageLocation = localStorage.getItem("location");
const storageSteps = JSON.parse(localStorage.getItem("steps")!);
const storageSkip = localStorage.getItem("skip");

const userContextDefaultValue: UserContextInterface = {
    userName: storageUserName! ? storageUserName : "Guest",
    setUserName: () => "Guest",
    firstName: storageFirstName! ? storageFirstName : "Guest",
    setFirstName: () => "Guest",
    isAuth: storageAuth! ? (storageAuth === 'true') : false,
    setIsAuth: () => false,
    character: storageCharacter! ? storageCharacter : "unicorn",
    setCharacter: () => "unicorn",
    role: storageRole! ? storageRole : "other",
    setRole: () => "other",
    token: storageToken! ? storageToken : "0",
    setToken: () => "0",
    id: storageId! ? storageId : "0",
    setId: () => "0",
    couponUrl: storageCouponUrl! ? storageCouponUrl : "",
    setCouponUrl: () => "",
    location: storageLocation! ? storageLocation : "",
    setLocation: () => "",
    steps: storageSteps! ? storageSteps : [{id:"Default", step_id:99, step_status:99, name:"Default"}],
    setSteps: () => [{id:"Default", step_id:99, step_status:99, name:"Default"}],
    skip: storageSkip! ? (storageSkip === 'true') : false,
    setSkip: () => false,
}

export const UserContext = createContext<UserContextInterface>(userContextDefaultValue);


interface Props {
    children?: ReactNode
}

const UserProvider = ({ children }: Props) => {
    const [userName, setUserName] = useState(userContextDefaultValue.userName);
    const [firstName, setFirstName] = useState(userContextDefaultValue.firstName);
    const [isAuth, setIsAuth] = useState(userContextDefaultValue.isAuth);
    const [character, setCharacter] = useState(userContextDefaultValue.character)
    const [role, setRole] = useState(userContextDefaultValue.role)
    const [token, setToken] = useState(userContextDefaultValue.token)
    const [id, setId] = useState(userContextDefaultValue.id)
    const [couponUrl, setCouponUrl] = useState(userContextDefaultValue.couponUrl)
    const [location, setLocation] = useState(userContextDefaultValue.location)
    const [steps, setSteps] = useState(userContextDefaultValue.steps)
    const [skip, setSkip] = useState(false)

    const value = {
        userName,
        setUserName,
        firstName,
        setFirstName,
        isAuth,
        setIsAuth,
        character,
        setCharacter,
        role,
        setRole,
        token,
        setToken,
        id,
        setId,
        couponUrl,
        setCouponUrl,
        location,
        setLocation,
        steps,
        setSteps,
        skip,
        setSkip
    };

    return (
        <>
            <UserContext.Provider value={value}>
                {children}
            </UserContext.Provider>
        </>
    );
};

export default UserProvider;
