import React, {useContext, useEffect} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";
import {UserContext} from "../context/UserProvider";
import {enqueueSnackbar} from "notistack";

function Workplace() {
    const userContext = useContext(UserContext)

    useEffect(() => {
        enqueueSnackbar('You successfully came to the office. It’s a great time to check your place and to see what is' +
            ' available for you.', { variant: 'info' })
    }, [])
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Your workplace"}
                    text={"Horray you finally got to your table! Look at the equipment we have prepared for you."}
                    progress={18} category={"start"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="flex flex-col lg:flex-row lg:justify-center">
                        <div className="order-2 lg:order-1">
                            <img src={envVariables.IMG_SRC+"/images/workplace.png"} alt="Workplace"
                                 className="max-w-full lg:max-h-[70vh]
                                 1.5lg:max-h-[65vh] xl:max-h-[70vh] 2xl:h-[75vh]"/>
                        </div>
                        <div className="mb-5 lg:mb-0 lg:ml-5 order-1 lg:order-2">
                            <h1 className="mt-0 mb-0 md:mb-2 lg:mb-5 2xl:mb-12 text-lg md:text-2xl lg:text-3xl 2xl:text-5xl">
                                How do we work</h1>
                            <div className="wWrapper">
                                <img src={envVariables.IMG_SRC+"/images/time.png"} alt="tick" className="tickIcon"/>
                                <p className="wText">Usual co-working hours are between 9:00-15:00</p>
                            </div>
                            <div className="wWrapper">
                                <img src={envVariables.IMG_SRC+"/images/tick.png"} alt="tick" className="tickIcon"/>
                                <p className="wText">respect your colleagues</p>
                            </div>
                            <div className="wWrapper">
                                <img src={envVariables.IMG_SRC+"/images/tick.png"} alt="tick" className="tickIcon"/>
                                <p className="wText">for noisy discussions, it's better to use cozy kitchens or meeting rooms</p>
                            </div>
                            <div className="wWrapper">
                                <img src={envVariables.IMG_SRC+"/images/tick.png"} alt="tick" className="tickIcon"/>
                                <p className="wText">office supplies are available</p>
                            </div>
                            <div className="wWrapper">
                                <img src={envVariables.IMG_SRC+"/images/tick.png"} alt="tick" className="tickIcon"/>
                                <p className="wText">keep your mind open</p>
                            </div>
                            <div className="wWrapper">
                                <img src={envVariables.IMG_SRC+"/images/tick.png"} alt="tick" className="tickIcon"/>
                                <p className="wText">we also support Home-office ;)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {userContext.isAuth ? (
                <PageNavigation previous="vr" next="equipment"/>
            ) : (
                <PageNavigation previous="vr" next="firstDay"/>
            )}
        </div>
    )
}
export default Workplace;