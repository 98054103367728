import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from "@mui/material/DialogContentText";
import {envVariables} from "../../constants/Variables";
import {Link} from "react-router-dom";
import {useContext} from "react";
import {UserContext} from "../../context/UserProvider";
import {DialogTransition} from "../../helperFunctions/Transitions";

const Transition = React.forwardRef(DialogTransition);

export default function CustomOutfitDialog(props : any) {
    const [open, setOpen] = React.useState(false);
    const userContext = useContext(UserContext)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="h-[35vh] xs:h-[35vh] md:h-[35vh] lg:h-[50vh] 1.5lg:h-[55vh] xl:h-[60vh] 1.5xl:h-[60vh]
        2xl:h-[55vh] 3xl:h-[70vh]">
            {props.outfit === 1 &&
                <img src={envVariables.IMG_SRC+"/images/characters/outfits/" + userContext.character + "/1.png"} alt="Outfit 1"
                     onClick={handleClickOpen} className="outfitImage"/>
            }
            {props.outfit === 2 &&
                <img src={envVariables.IMG_SRC+"/images/characters/outfits/" + userContext.character + "/2.png"} alt="Outfit 2"
                     onClick={handleClickOpen} className="outfitImage"/>
            }
            {props.outfit === 3 &&
                <img src={envVariables.IMG_SRC+"/images/characters/outfits/" + userContext.character + "/3.png"} alt="Outfit 3"
                     onClick={handleClickOpen} className="outfitImage"/>
            }
            {props.outfit === 4 &&
                <img src={envVariables.IMG_SRC+"/images/characters/outfits/" + userContext.character + "/4.png"} alt="Outfit 4"
                     onClick={handleClickOpen} className="outfitImage"/>
            }
            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                <DialogTitle className="font-bold text-primary text-center">{"Good job!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" className="w-fit flex flex-col justify-center">
                        {props.correct ? (
                            <span className="text-primary text-center">You can go to the office in any clothes you are comfortable in,
                                but please remember whether you have an important meeting or an event to dress appropriately.
                            </span>
                        ) : (
                            <span className="text-primary text-center">Although we are not really strict about employee attire,
                                you should consider wearing something more formal.</span>
                        )}
                        <br/>
                        <span className="text-primary text-center">And now, let's check how to get to the office.</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="flex justify-center">
                    <Button component={Link} to={'/directions'} variant="contained" color="primary">Next</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
