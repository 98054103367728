import React, {createRef, LegacyRef, useContext, useState} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";
import RewardDialog from "../components/dialog/RewardDialog";
import {UserContext} from "../context/UserProvider";

function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
}
function Quiz() {
    const [finishedQuiz, setFinishedQuiz] = useState(false)
    const [question, setQuestion] = useState(1)
    const rightAnswers = [1, 2, 2, 1, 2, 1, 2, 2, 1, 2, 1]
    const questionText = ["Choose the right dresscode:", "Our firm is more about...", "Which language is used the most?",
        "Female employment in Erste Digital", "Total average age of employees?", "Most common employee name (men)?",
        "Most common job title?", "Most common employee name (women)?", "Choose countries where we have a branch:",
        "How many nationalities are in our company?", "How many employees do we have?"]
    const userContext = useContext(UserContext)

    const answer1 : LegacyRef<HTMLImageElement> | undefined = createRef()
    const answer2 : LegacyRef<HTMLImageElement> | undefined = createRef()
    const selectAnswer = async (e: any, answerNumber: number) => {
        if (rightAnswers[question - 1] === answerNumber) {
            answerNumber === 1 ? answer2.current?.classList.remove("wrongAnswer") : answer1.current?.classList.remove("wrongAnswer")
            answerNumber === 1 ? answer1.current?.classList.add("correctAnswer") : answer2.current?.classList.add("correctAnswer")
            await timeout(1000);
            answerNumber === 1 ? answer1.current?.classList.remove("correctAnswer") : answer2.current?.classList.remove("correctAnswer")
            if (question < 11) setQuestion(question + 1)
            if (question === 11) setFinishedQuiz(true)
        } else {
            //e.currentTarget.classList.add("wrongAnswer")
            answerNumber === 2 ? answer2.current?.classList.add("wrongAnswer") : answer1.current?.classList.add("wrongAnswer")
        }
    }

    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Quiz"}
                    text={"#believeinyourself"} progress={95} category={"primary"}/>
            <div className={`contentWrapper`}>
                {finishedQuiz && <RewardDialog type={"tree"} title={"Tree certificate"} color={"primary"} firstName={userContext.firstName} name={userContext.userName}/>}
                <div className="contentWrapperInner">
                    <h3 className={"text-center"}>Quiz time (just for fun)</h3>
                    <p className={"text-center"}>{question + ". " + questionText[question - 1]}</p>
                    <div className={"grid grid-cols-1 justify-items-center gap-2"}>
                        <img ref={answer1} id={"1"} src={envVariables.IMG_SRC+"/images/quiz/" + question + "/1.png"} alt="Quiz question 1"
                             className="max-w-full h-[30vh] cursor-pointer" onClick={(e) => selectAnswer(e, 1)}/>
                        <img ref={answer2} id={"2"} src={envVariables.IMG_SRC+"/images/quiz/" + question + "/2.png"} alt="Quiz question 2"
                             className="max-w-full h-[30vh] cursor-pointer" onClick={(e) => selectAnswer(e, 2)}/>
                    </div>
                </div>
            </div>
            <PageNavigation skip={false} previous="coloring" next="feedback"/>
        </div>
    )
}
export default Quiz;