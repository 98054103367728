import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {StepIconProps} from "@mui/material";
import {envVariables} from "../constants/Variables";
import {useContext} from "react";
import {UserContext} from "../context/UserProvider";
import {Link} from "react-router-dom";

const steps = [
    {
        label: '1. Enter the building',
        description: `Please arrive at specific time on your first day and approach the front desk and introduce
         yourself as the new employee. You will receive a guest card so that you can enter the building.`,
    },
    {
        label: '2. Use the elevator',
        description:
            'When you are in the elevator, select 9th floor.',
    },
    {
        label: '3. Use the telephone',
        description: `When you arrive at 9th floor, use the telephone and call reception to let you in.`,
    },
];

function CustomStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
    const userContext = useContext(UserContext)


    const icons: { [index: string]: React.ReactElement } = {
        1: <img src={active ? envVariables.IMG_SRC+"/images/gettingToWorkplace/Door.png" : envVariables.IMG_SRC+'/images/gettingToWorkplace/Door.png'} alt="step-icon" className={"sPlaceIcon"} />,
        2: <img src={active ? envVariables.IMG_SRC+"/images/gettingToWorkplace/Elevator.png" : envVariables.IMG_SRC+'/images/gettingToWorkplace/Elevator.png'} alt="step-icon" className={"sPlaceIcon"} />,
        3: <img src={active ? envVariables.IMG_SRC+"/images/gettingToWorkplace/OfficeDoor.png" : envVariables.IMG_SRC+'/images/gettingToWorkplace/OfficeDoor.png'} alt="step-icon" className={"sPlaceIcon"} />,
    };

    return (
        <div className={"flex items-center"}>
            {icons[String(props.icon)]}
            {props.active ? (<img src={envVariables.IMG_SRC+"/images/characters/mini/" + userContext.character + "/mini.png"} alt="Character" className="sCharacterIcon"/>) : (<></>)}
        </div>
    );
}

export default function VerticalStepper(props: any) {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        props.handlePosition((prevActiveStep: number) => prevActiveStep + 1)
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        props.handlePosition((prevActiveStep: number) => prevActiveStep - 1)

    };

    const handleReset = () => {
        setActiveStep(0);
        props.handlePosition(1);
    };

    return (
        <Box sx={{ maxWidth: '100%' }}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {props.data.map((step : any, index : any) => (
                    <Step key={step.title}>
                        <StepLabel StepIconComponent={CustomStepIcon}
                            optional={
                                index === 2 ? (
                                    <Typography variant="caption">Last step</Typography>
                                ) : null
                            }
                        >
                            {step.title}
                        </StepLabel>
                        <StepContent>
                            <Typography>{step.description}</Typography>
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                        className={"startButton"}
                                    >
                                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                    </Button>
                                    <Button
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}
                                        className={"text-start"}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>Welcome to the office. Go to the next page to have a look at how some of our other offices look in VR.</Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }} className={"text-start"}>
                        Reset
                    </Button>
                    <Button component={Link} to={'/vr'} variant="contained" color="warning" className={"startButton"}>Next</Button>
                </Paper>
            )}
        </Box>
    );
}