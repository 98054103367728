import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import './index.css';
import './tailwind.css';
import reportWebVitals from './reportWebVitals';
import {styled, StyledEngineProvider, ThemeProvider} from "@mui/material";
import {muiTheme} from './themes/mui-theme';
import UserProvider from "./context/UserProvider";
import {closeSnackbar, MaterialDesignContent, SnackbarProvider} from "notistack";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
/*
const basename = document.querySelector('base')?.getAttribute('href') ?? '/'
*/
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-info': {
        backgroundColor: '#2870ED',
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: '#970C0C',
    },
}));

root.render(
    /*<React.StrictMode>*/
        <BrowserRouter basename="/companyoverview">
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={muiTheme}>
                    <UserProvider>
                        <SnackbarProvider
                            Components={{
                                info: StyledMaterialDesignContent,
                                error: StyledMaterialDesignContent,
                            }}
                            action={(snackbarId) => (
                                <HighlightOffIcon onClick={() => closeSnackbar(snackbarId)} className={"cursor-pointer py-1.5 pr-1.5"}/>
                            )}
                        >
                            <App />
                        </SnackbarProvider>
                    </UserProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </BrowserRouter>
    /*</React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
