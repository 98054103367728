import React, {useContext, useEffect} from 'react'
import NavBar from "../components/NavBar";
import {Button, IconButton, TextField} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import PageNavigation from "../components/PageNavigation";
import {PhotoCamera} from "@mui/icons-material";
import {completeUserStep, getTeamInfo, setUserInfo} from "../helperFunctions/UserInput";
import {UserContext} from "../context/UserProvider";
import {envVariables} from "../constants/Variables";
import RewardDialog from "../components/dialog/RewardDialog";

function Team() {
    const userContext = useContext(UserContext)
    const[currentFile, setCurrentFile] = React.useState<File>()
    const [hobby, setHobby] = React.useState("")
    const [funFact, setFunFact] = React.useState("")
    const [team, setTeam] = React.useState<any>(undefined)
    const [teamMembers, setTeamMembers] = React.useState<any>(undefined)
    const [finished, setFinished] = React.useState(false)

    useEffect(() => {
        enqueueSnackbar('Make sure you provided us with any information that may help your team to get to know you.', { variant: 'info' })
        console.log(userContext.couponUrl);
        getTeamData()
        if(userContext.steps[2] !== undefined) {
            completeUserStep(userContext.steps[2].id)
        }
    }, [])

    const getTeamData = async () => {
        let teamData = await getTeamInfo(userContext.id)
        console.log(teamData)
        setTeam(teamData.team)
        fixUrl(teamData.members)
    }

    const selectFile = (e: any) => {
        setCurrentFile(e.target.files[0])
        console.log(e.target.files[0].name)
    }

    const submitForm = async () => {
        if (currentFile !== undefined) {
            let response = await setUserInfo(currentFile!, hobby, funFact, userContext.token)
            //console.log(response)
            setFinished(true)
        } else {
            enqueueSnackbar('File upload failed and form could not be sent!', {variant: 'error'})
        }
    }

    const fixUrl = (members : any) => {
        members.forEach((member: any) => {
            if(member.url === undefined || member.url === null) return
            let fixed = member.url.split(/(https:\/\/)/d)
            if(fixed.length === 1) {
                member.url = "https://" + fixed[0];
            }
            //console.log(fixed);
        })
        setTeamMembers(members)
    }

    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Team & I"}
                    text={"Communication is a very important  thing to work in team."}
                    progress={36} category={"team"}/>
            <div className="contentWrapper">
                {finished && <RewardDialog type={"beer"} title={"Beer coupon"} url={userContext.couponUrl} color={"primary"}/>}
                <div className="contentWrapperInner">
                    <div className={"grid grid-cols-1 lg:grid-cols-2 gap-10"}>
                        <div className={"flex flex-col overflow-auto max-h-[60vh]"}>
                            <h2>{team === undefined ? ("Failed to load data") : team.name}</h2>
                            <p>{team === undefined ? ("Failed to load data") : team.description}</p>
                            <h4>Team members: </h4>
                            <div className={"grid grid-cols-1 gap-5"}>
                                {teamMembers === undefined ? ("Failed to load data") : teamMembers.map((member: any) => {
                                    return (
                                        <div className={"grid grid-cols-3 bg-team p-4 rounded-2xl"} key={member.name}>
                                            <div>
                                                <img src={"https://newcomerjourney.com:8443" + member.image} alt="Member" className="w-[70%]"/>
                                            </div>
                                            <div className={"col-span-2"}>
                                                <h3 className={"teamText"}>{member.name}</h3>
                                                <p className={"teamText underline"}>{member.position}</p>
                                                <p className={"teamText italic"}>{member.description}</p>
                                                <a href={member.url} target="_blank" rel="noreferrer">
                                                    <img src={envVariables.IMG_SRC+'/images/LinkedIn.svg'} alt="Member" className="cursor-pointer"/>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className={"grid grid-cols-1 gap-5"}>
                            <h3>Your turn!</h3>
                            <div className={"flex items-center"}>
                                <label htmlFor="formImage">Upload your photo</label>
                                <IconButton id={"formImage"} color="primary" aria-label="upload picture" component="label">
                                    <input hidden accept="image/*" type="file" onChange={(e) => selectFile(e)}/>
                                    <PhotoCamera className={""} color={"primary"}/>
                                </IconButton>
                                <p className={"underline"}>{currentFile?.name}</p>
                            </div>
                            <TextField id="outlined-basic"
                                       label="Hobby"
                                       variant="outlined"
                                       color="primary"
                                       className="pr-10"
                                       onChange={(event) => setHobby(event.target.value)}
                                       size="small"/>
                            <TextField id="outlined-basic"
                                       label="Sentence that describes your life"
                                       variant="outlined"
                                       color="primary"
                                       className="pr-10"
                                       onChange={(event) => setFunFact(event.target.value)}
                                       size="small"
                                       multiline
                                       rows={3}/>
                            <div>
                                <Button variant="contained" color="warning" className={"teamButton"} onClick={submitForm}>
                                    Submit</Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <PageNavigation previous="eat" next="puzzle" />
        </div>
    )
}
export default Team;