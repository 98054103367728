import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from "@mui/material/DialogContentText";
import {DialogTransition} from "../../helperFunctions/Transitions";

const Transition = React.forwardRef(DialogTransition);

export default function CustomStoryDialog(props : any) {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                <DialogTitle className="text-black text-center font-bold">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" className="text-black w-fit text-center">
                        {props.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="flex justify-center">
                    <Button variant="contained" color="warning" onClick={handleClose} className="mb-5">Continue</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
