import React, {useEffect, useState} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {GoogleMap, useLoadScript} from "@react-google-maps/api";
import {customMapId, envVariables} from "../constants/Variables";
import VerticalStepper from "../components/VerticalStepper";
import {enqueueSnackbar} from "notistack";
import {directionsDataBratislava, directionsDataKosice, directionsDataVienna} from "../data/directionsData";
import LocationPicker from "../components/LocationPicker";
const StreetViewPanorama = require('@react-google-maps/api').StreetViewPanorama

function StreetView() {
    const [position, setPosition] = useState(1)
    const [location, setLocation] = useState("bratislava")
    const [directionsData, setDirectionsData] = useState(directionsDataBratislava)

    useEffect(() => {
        enqueueSnackbar('When you successfully arrive at the building, let’s go through the way to your workplace.', { variant: 'info' })
    }, [])

    const handlePosition = (value:number) => {
        setPosition(value)
    }

    const handleLocation = (value: string) => {
        setLocation(value)
        if(value === "bratislava") {
            setDirectionsData(directionsDataBratislava)
        } else if(value === "kosice") {
            setDirectionsData(directionsDataKosice)
        } else if(value === "vienna") {
            setDirectionsData(directionsDataVienna)
        }
    }

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: envVariables.GOOGLE_API_KEY,
        libraries: ["places"],
    });

    if(!isLoaded) {
        return (
            <div>Loading...</div>
        )
    }

    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Getting to your workplace"}
                    text={"We are glad that you have arrived at the Lakeside. Lets see how you can get to your table." +
                        " Click on the images on the left side."}
                    progress={12} category={"start"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <LocationPicker handleLocation={handleLocation}/>
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div>
                            <VerticalStepper handlePosition={handlePosition} location={location} data={directionsData}/>
                        </div>
                        <div>
                            {position === 1 &&
                                <div className="w-[100%] h-[70vh] xs:h-[70vh] md:h-[60vh] lg:h-[55vh] 1.5xl:h-[60vh] 2xl:h-[60vh]">
                                    <GoogleMap
                                        zoom={directionsData[0].gMapZoom}
                                        center={{lat: directionsData[0].gMapLat, lng: directionsData[0].gMapLng}}
                                        mapContainerClassName='map-container'
                                        mapContainerStyle={{ height: "100%", width: "100%" }}
                                        options={{ mapId: customMapId }}
                                    >
                                        <StreetViewPanorama
                                            position={{lat: directionsData[0].gMapLat, lng: directionsData[0].gMapLng}}
                                            visible={true}
                                            pov={{
                                                heading: directionsData[0].gMapHeading,
                                                pitch: directionsData[0].gMapPitch
                                            }}
                                        />
                                    </GoogleMap>
                                </div>
                            }
                            {position === 2 &&
                                <div className="flex justify-center lg:justify-start">
                                    <img src={envVariables.IMG_SRC+"/images/office/" + directionsData[1].image} alt="Elevator"
                                         className="sPlaceImage"/>
                                </div>
                            }
                            {position === 3 &&
                                <div className="flex justify-center lg:justify-start">
                                    <img src={envVariables.IMG_SRC+"/images/office/" + directionsData[2].image} alt="Entrance"
                                        className="sPlaceImage"/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <PageNavigation previous="directions" next="vr"/>
        </div>
    )
}
export default StreetView;