import React, {useContext, useEffect} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";
import {UserContext} from "../context/UserProvider";
import {enqueueSnackbar} from "notistack";

function Metaverse() {
    const userContext = useContext(UserContext)
    useEffect(() => {
        enqueueSnackbar('If you want to join us during short presentation in metaverse, write to this email address for more details: Erstedigitalinnovations@erstegroup.com', { variant: 'info' })
        enqueueSnackbar('We are constantly trying to find new methods to learn information, especially in an engaging way.', { variant: 'info' })
    }, [])
    return (
        <div className="pageWrapper bg-secondary">
            {/*<CustomStoryDialog title="Meetings in metaverse"
                               text="We are constantly trying to find new methods to learn information, especially in an
                                engaging way. If you want to join us during short presentation in metaverse, write to
                                this email address for more details: Erstedigitalinnovations@erstegroup.com"/>
            */}<NavBar image={"/navbar/toDo.svg"} header={"Metaverse Immersion?"}
                    text={"You can check our online meeting room. If you want to talk, drop us a message at ErsteDigitalInnovations@erstegroup.com"}
                    progress={63} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-14">
                        <div className={"col-span-1 lg:col-span-2"}>
                            <a href="https://framevr.io/ally" target="_blank" rel="noreferrer" className="flex justify-center">
                                <img src={envVariables.IMG_SRC+"/images/characters/metaverse/" + userContext.character + "/metaverse.png"} alt="Metaverse"
                                     className="max-w-[100%] lg:h-[60vh] 1.5lg:h-[65vh] 2xl:h-[70vh]" />
                            </a>
                        </div>
                        <div className={""}>
                            <h1>Available spaces:</h1>
                            <p>
                                <a href="https://framevr.io/flip" target="_blank" rel="noreferrer"
                                   className="customLink">https://framevr.io/flip</a>
                            </p>
                            <p>
                                <a href="https://framevr.io/iday" target="_blank" rel="noreferrer"
                                   className="customLink">https://framevr.io/iday</a>
                            </p>
                            <p>
                                <a href="https://framevr.io/jku" target="_blank" rel="noreferrer"
                                   className="customLink">https://framevr.io/jku</a>
                            </p>
                            <p>
                                <a href="https://framevr.io/ally" target="_blank" rel="noreferrer"
                                   className="customLink">https://framevr.io/ally</a>
                            </p>
                            <p>
                                Soon you will be able to also see the culture of our online meetings. Stay tuned :)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <PageNavigation previous="usefulLinks" next="entertainment"/>
        </div>
    )
}
export default Metaverse;