import React, {useContext} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";
import {UserContext} from "../context/UserProvider";

function FirstDay() {
    const userContext = useContext(UserContext)

    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"First day at work"}
                    text={"Program that you can expect on your first day"}
                    progress={24} category={"start"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="hidden lg:flex lg:flex-col justify-center">
                        <img src={envVariables.IMG_SRC+"/images/firstDay/FirstDay.png"} alt="First day info" className="max-w-full mb-5"/>
                        <div>
                            <p className={"text-center"}>
                                Overall, your first day of work will be a mix of learning, orientation and starting to
                                contribute to the company. Be open-minded, take notes and be sure to ask questions.
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:hidden justify-items-center gap-8">
                        {(() => {
                            let images = []
                            for (let i = 1; i < 7; i++) {
                                images.push(<img src={envVariables.IMG_SRC+"/images/firstDay/" + i + ".png"}
                                                 alt="First day step"
                                                 className="max-w-[100%] w-[60%] md:w-[80%]"
                                                 key={i}/>)
                            }
                            return images
                        })()}
                        <div className={"col-span-1 md:col-span-2"}>
                            <p className={"text-center"}>
                                Overall, your first day of work will be a mix of learning, orientation and starting to
                                contribute to the company. Be open-minded, take notes and be sure to ask questions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {userContext.isAuth ? (
                <PageNavigation previous="equipment" next="documents"/>
            ) : (
                <PageNavigation previous="workplace" next="documents"/>
            )}
        </div>
    )
}
export default FirstDay;