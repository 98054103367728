import React from 'react';

function ColorPalette(props: { currentColor: string; changeColor: (arg0: string) => void; }) {

    const colors = ['red', 'orange', 'yellow', 'green', 'blue', 'turquoise', 'purple', 'pink', 'deeppink']

    return(
        <div className="color-palette lg:h-52 grid grid-cols-3 gap-0">
            {colors.map(color => {
                const activeClass = props.currentColor === color ? "color-swatch-active" : "";
                return (
                    <div onClick={() => {props.changeColor(color)}}>
                        <div className={`color-swatch ${activeClass}`}  style={{backgroundColor: color}}></div>
                    </div>
                )
            })}
        </div>
    )
}

export default ColorPalette