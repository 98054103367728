import React, {useContext} from "react";
import NavBar from "../components/NavBar";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import {envVariables} from "../constants/Variables";
import CustomStoryDialog from "../components/dialog/CustomStoryDialog";
import {saveAs} from "file-saver";
import {UserContext} from "../context/UserProvider";

function Feedback() {
    const userContext = useContext(UserContext);

    const downloadDocument = () => {
        let imageUrl = envVariables.IMG_SRC+"/images/summary/Onboarding_summary.pdf";
        saveAs(imageUrl, "Onboarding_summary");
    }

    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Your Feedback :)"} text={"We’d  really appreciate your opinion" +
                " on our onboarding portal "} progress={100} category={"primary"} />
            <CustomStoryDialog title="Congratulations!"
                               text="You have successfully finished the onboarding process! We hope you are excited and
                                ready to start! We will appreciate your feedback."/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className={"flex flex-col md:grid grid-cols-3 justify-items-end"}>
                        <div className={"col-span-2 flex flex-col justify-center order-2 md:order-1"}>
                            <h2>
                                You reached the <b>last step</b> in the Onboarding process. This is the beginning of a new chapter for
                                all of us, and we're excited to embark on this journey together. See you in the office. :)
                            </h2>
                        </div>
                        <div className={"flex justify-center order-1 md:order-2"}>
                            <img src={envVariables.IMG_SRC+"/images/FeedbackNew.png"} alt="Feedback" className="w-max"/>
                        </div>
                    </div>
                    <div className={"flex flex-col md:grid grid-cols-3 md:justify-items-start"}>
                        <div className={"flex justify-center"}>
                            <img src={envVariables.IMG_SRC+"/images/FeedbackMail.png"} alt="Feedback mail" className="w-max"/>
                        </div>
                        <div className={"col-span-2 flex flex-col justify-center"}>
                            <div className={"flex flex-col lg:flex-row justify-between items-center"}>
                                <h3 className={"m-0 mr-2"}>Download summary of important information found on this page: </h3>
                                <Button className={"companyButton"} onClick={downloadDocument}>Download</Button>
                            </div>
                            <h2 className="text-sm md:text-base text-center">Please give us your feedback by writing email on this address <a
                                href="mailto:ErsteDigitalInnovations@erstegroup.com">ErsteDigitalInnovations@erstegroup.com</a></h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:absolute lg:bottom-[0] w-full flex justify-center mt-5 mb-20 lg:mb-5">
                <div className="w-11/12 2xl:w-9/12 flex justify-between">
                    {userContext.skip ? (
                        <Button component={Link} to={'/foodBenefit'} variant="outlined" color="warning">Back</Button>
                    ) : (
                        <Button component={Link} to={'/quiz'} variant="outlined" color="warning">Back</Button>
                    )}
                    <Button component={Link} to={'/'} variant="contained" color="warning">Finish & restart</Button>
                </div>
            </div>
        </div>
    )
}
export default Feedback;