export const equipmentAccessoriesDataOld = [
    {
        category: 'mouses',
        members: ['wirelessKeyboardMouse', 'appleMouse', 'wirelessMouse']
    },
    {
        category: 'keyboards',
        members: ['wirelessKeyboard', 'wirelessKeyboardMouse', 'appleKeyboard']
    },
    {
        category: 'headphones',
        members: ['microsoftHeadset', 'earpods', 'earphones']
    },
    {
        category: 'bags',
        members: ['backpack', 'briefcase']
    }
]

export const equipmentAccessoriesData = [
    {
        category: 'mouses',
        members: ['wirelessKeyboardMouse', 'appleMouse', 'Wireless Mouse']
    },
    {
        category: 'keyboards',
        members: ['wirelessKeyboard', 'wirelessKeyboardMouse', 'appleKeyboard']
    },
    {
        category: 'headphones',
        members: ['microsoftHeadset', 'earpods', 'earphones']
    },
    {
        category: 'bags',
        members: ['backpack', 'briefcase']
    }
]

const equipmentImages : Record<string, string> = {
    "Dell Latitude 5340 SUB i5 (sNice)": "laptops/subLaptop.png",
    "Dell Latitude 5540 STD i5 (sNice)": "laptops/standardLaptop.png",
    "Dell Precision 7680 ADV PRO i7 (sNice)": "laptops/advancedLaptopSNice.png",
    "Dell Precision 7680 ADV PRO i7 (Flex)": "laptops/advancedLaptopFlex.png",
    "Advanced Pro Laptop": "laptops/advancedProLaptop.png",
    "MacBook Dyn (Flex)": "laptops/macDyn.png",
    "MacBook Pro (Flex)": "laptops/macPro.png",
    "MacBook Pro+ (Flex)": "laptops/macProPlus.png",

    "iPhone XR": "phones/iphoneXr.png",
    "iPhone SE": "phones/iphoneSe.png",
    "Magic mouse": "laptopAccessories/appleMouse.png",
    "Magic keyboard": "laptopAccessories/appleKeyboard.png",
    "Headphones (Apple)": "laptopAccessories/microsoftHeadset.png",
    "Earpods": "laptopAccessories/earpods.png",
    "Earphones": "laptopAccessories/earphones.png",

    "iPhone 13": "iphone13.png",
    "Mouse": "mouse.png",
    "Keyboard (US layout)": "keyboard.png",
    "Headphones": "headset.png",

    "Dell Essential Briefcase": "briefcase.png",
    "Dell Essential Backpack": "backpack.png",
    "Backpack": "backpack.png",

    "eStuff": "estuff.png",
    "Panzer glass 5D screen protector ": "screenProtector.png",
    "Sketch crystal cover for iPhone": "estuff.png",
    "20W charge brick": "chargingBrick.png",
    "Charging cable": "chargingCable.png",
    "Sim card (physical)": "sim.png",
}

export function getEquipmentImage(key: string) {
    return equipmentImages[key];
}
