import React, {useContext} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";
import {UserContext} from "../context/UserProvider";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";

export default function Parking() {
    const userContext = useContext(UserContext)

    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Get you parking card(s)"}
                    text={"You can choose to receive card for bicycle cage in the garage and/or card for parking with your car!"}
                    progress={25} category={"start"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <h2>Please choose which cards do you want to get:</h2>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox />} label="Card for bicycle cage" />
                        <FormControlLabel control={<Checkbox />} label="Card for car parking" />
                    </FormGroup>
                </div>
            </div>
            <PageNavigation previous="directions" next="directionsOffice"/>
        </div>
    )
}