import {
    mapCenterDesktop, mapCenterDesktopKosice, mapCenterDesktopVienna,
    mapCenterMobile,
    mapCenterMobileKosice, mapCenterMobileVienna,
    mapCenterSmallDesktop, mapCenterSmallDesktopKosice, mapCenterSmallDesktopVienna,
    mapCenterTablet, mapCenterTabletKosice, mapCenterTabletVienna
} from "../constants/Variables";

export default function GetMapSetupData(windowWidth : number, location : string) {
    let data: { lng: number; lat: number, zoom: number }
    if (windowWidth < 768) {
        // Mobiles
        switch (location) {
            case "bratislava":
                data = {...mapCenterMobile, zoom: 16}
                return data
            case "kosice":
                data = {...mapCenterMobileKosice, zoom: 16}
                return data
            case "vienna":
                data = {...mapCenterMobileVienna, zoom: 17}
                return data
            default:
                data = {...mapCenterMobile, zoom: 16}
                return data
        }
    } else if(windowWidth < 1024) {
        // Tablets
        switch (location) {
            case "bratislava":
                data = {...mapCenterTablet, zoom: 16}
                return data
            case "kosice":
                data = {...mapCenterTabletKosice, zoom: 16}
                return data
            case "vienna":
                data = {...mapCenterTabletVienna, zoom: 17}
                return data
            default:
                data = {...mapCenterTablet, zoom: 16}
                return data
        }
    } else if(windowWidth < 1650) {
        // Small Desktops
        switch (location) {
            case "bratislava":
                data = {...mapCenterSmallDesktop, zoom: 16}
                return data
            case "kosice":
                data = {...mapCenterSmallDesktopKosice, zoom: 16}
                return data
            case "vienna":
                data = {...mapCenterSmallDesktopVienna, zoom: 17}
                return data
            default:
                data = {...mapCenterSmallDesktop, zoom: 16}
                return data
        }
    } else {
        // Desktops
        switch (location) {
            case "bratislava":
                data = {...mapCenterDesktop, zoom: 16}
                return data
            case "kosice":
                data = {...mapCenterDesktopKosice, zoom: 16}
                return data
            case "vienna":
                data = {...mapCenterDesktopVienna, zoom: 17}
                return data
            default:
                data = {...mapCenterDesktop, zoom: 16}
                return data
        }
    }
}