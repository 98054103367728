export const officePlacesBratislava = [
    {
        name: "Kitchen #1",
        floor: "9th floor, Lakeside",
        description: "We offer a communal place for food and drink, complete with amenities.",
        availability: "always",
        image: "9kitchen.png",
        characterLocation: {
            x: "left-[52%]",
            y: "bottom-[53%]"
        }
    },
    {
        name: "Chill zone #1",
        floor: "9th floor, Lakeside",
        description: "Place where you can relax and let go of the stress or have a little chat with your colleagues.",
        availability: "always",
        image: "9chillZone.png",
        characterLocation: {
            x: "left-[58%]",
            y: "bottom-[37%]"
        }
    },
    {
        name: "Conference room #1",
        floor: "9th floor, Lakeside",
        description: "One of our new modern conference rooms. Just connect you notebook and enjoy your meetings on big TV, with good camera and microphone.",
        availability: "always",
        image: "9conferenceRoom.png",
        characterLocation: {
            x: "left-[29%]",
            y: "bottom-[86%]"
        }
    },

    {
        name: "Chill zone #1",
        floor: "11th floor, Lakeside",
        description: "Place where you can relax and let go of the stress or have a little chat with your colleagues.",
        availability: "always",
        image: "14chillZone.png",
        characterLocation: {
            x: "left-[35%]",
            y: "bottom-[50%]"
        }
    },
    {
        name: "Conference room #1",
        floor: "11th floor, Lakeside",
        description: "One of our new modern conference rooms. Just connect you notebook and enjoy your meetings on big TV, with good camera and microphone.",
        availability: "always",
        image: "14conferenceRoom.png",
        characterLocation: {
            x: "left-[65%]",
            y: "bottom-[35%]"
        }
    },
    {
        name: "Kitchen #1",
        floor: "11th floor, Lakeside",
        description: "We offer a communal place for food and drink, complete with amenities.",
        availability: "always",
        image: "14kitchen1.png",
        characterLocation: {
            x: "left-[10%]",
            y: "bottom-[20%]"
        }
    },
    {
        name: "Kitchen #2",
        floor: "11th floor, Lakeside",
        description: "We offer a communal place for food and drink, complete with amenities.",
        availability: "always",
        image: "14kitchen2.png",
        characterLocation: {
            x: "left-[71%]",
            y: "bottom-[82%]"
        }
    },
    {
        name: "Reading room #1",
        floor: "11th floor, Lakeside",
        description: "Great place to relax, read a book and drink your tea.",
        availability: "always",
        image: "14readingRoom.png",
        characterLocation: {
            x: "left-[45%]",
            y: "bottom-[50%]"
        }
    },
    {
        name: "Talking room #1",
        floor: "11th floor, Lakeside",
        description: "If you want to talk with your colleague or have a phone call, you can use this room to not disturb others.",
        availability: "always",
        image: "14talkingRoom.png",
        characterLocation: {
            x: "left-[75%]",
            y: "bottom-[5%]"
        }
    }
]

export const officePlacesCampus = [
    {
        name: "Atrium",
        floor: "1st floor, Campus",
        description: "Atrium is located at the center of the building, and designed to provide natural light, ventilation, and a sense of openness to the surrounding areas.",
        availability: "always",
        image: "vienna/atrium.png",
        characterLocation: {
            x: "left-[46%]",
            y: "bottom-[53%]"
        }
    },
    {
        name: "Rooftop",
        floor: "Roof, Campus",
        description: "Rooftop is designed to provide a relaxing environment for employees to take a break, socialize, or collaborate with colleagues.",
        availability: "always",
        image: "vienna/rooftop.png",
        characterLocation: {
            x: "left-[20%]",
            y: "bottom-[10%]"
        }
    },
    {
        name: "Meeting room,",
        floor: "12th floor, Campus",
        description: "They are often reserved in advance using a scheduling system, and may be used for a variety of purposes, such as team meetings,  presentations, and training sessions",
        availability: "check outlook",
        image: "vienna/meeting_room.png",
        characterLocation: {
            x: "left-[90%]",
            y: "bottom-[85%]"
        }
    },
    {
        name: "Garden deck,",
        floor: "Outside, Campus",
        description: "Access to outdoor spaces can have a positive impact on employee well-being, reducing stress and improving mental health. It provides a relaxing space for employees to take a break, unwind, and recharge.",
        availability: "always",
        image: "vienna/garden.png",
        characterLocation: {
            x: "left-[78%]",
            y: "bottom-[25%]"
        }
    },
    {
        name: "Homebase,",
        floor: "12th floor, Campus",
        description: "Another place, where you can work or have a chat with your colleagues.",
        availability: "always",
        image: "vienna/homebase.png",
        characterLocation: {
            x: "left-[62%]",
            y: "bottom-[53%]"
        }
    },

]

export const officePlacesKosice = [
    {
        name: "Kitchen",
        floor: "6th floor, Cassovar",
        description: "We offer a communal place for food and drink, complete with amenities.",
        availability: "always",
        image: "kosice/kitchen.png",
        characterLocation: {
            x: "left-[10%]",
            y: "bottom-[20%]"
        }
    },
    {
        name: "Dining area",
        floor: "6th floor, Cassovar",
        description: "Dining area is designed to provide a relaxing environment for employees to take a break, socialize, or collaborate with colleagues.",
        availability: "always",
        image: "kosice/diningArea.png",
        characterLocation: {
            x: "left-[20%]",
            y: "bottom-[10%]"
        }
    },
]