import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from "@mui/material/DialogContentText";
import {Link} from "react-router-dom";
import {useContext} from "react";
import {UserContext} from "../../context/UserProvider";
import {DialogTransition} from "../../helperFunctions/Transitions";
import BootstrapDialogTitle from "./BootstrapDialogTitle";

const Transition = React.forwardRef(DialogTransition);

export default function FoundCharactersDialog() {
    const userContext = useContext(UserContext)
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Congratulations!
                </BootstrapDialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" className="w-fit flex flex-col justify-center">
                        <span className="font-bold text-center">You have found your character in all places and
                            now you also know where you can eat & drink.</span>
                        <br/>
                        <span className="font-bold text-center">Our company provides card or money extra benefit.
                            In the following step please choose what do you prefer.</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="flex justify-center">
                    {userContext.isAuth ? (
                        <Button component={Link} to={'/team'} variant="contained" color="warning">Next</Button>
                    ) : (
                        <Button component={Link} to={'/puzzle'} variant="contained" color="warning">Next</Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}
