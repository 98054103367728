import React, {useContext} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";
import { saveAs } from 'file-saver';
import {Button} from "@mui/material";
import {UserContext} from "../context/UserProvider";

function BackgroundSelect() {
    const userContext = useContext(UserContext)

    const downloadImage = () => {
        let imageUrl = envVariables.IMG_SRC+"/images/characters/backgrounds/" + userContext.character + "/teamsBackgrounds.zip"
        saveAs(imageUrl, 'MicrosoftTeamsBackgrounds.zip')
    }

    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Get your background now"} text={"You can use this " +
                "wallpaper as your background in MS Teams"} progress={42} category={"team"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="flex items-center justify-center mb-5">
                        <img src={envVariables.IMG_SRC+"/images/hint.png"} alt="Hint"
                             className="w-[50px] h-[50px] mr-2"/>
                        <h3 className="text-sm md:text-xl lg:text-base 1.5lg:text-lg 1.5xl:text-xl 2xl:text-xl">
                            Click on the image to download your background or click on Download all backgrounds button
                            to get them all in .zip
                        </h3>
                    </div>
                    <div className="md:grid md:grid-cols-2 gap-4 lg:gap-8 place-items-center md:mb-10 lg:mb-5 2xl:mb-10">
                        <a href={envVariables.IMG_SRC+"/images/characters/backgrounds/" + userContext.character + "/teamsBg1.png"} download
                           className="flex justify-center">
                            <img src={envVariables.IMG_SRC+"/images/characters/backgrounds/" + userContext.character + "/teamsBg1.png"} alt="Background 1"
                                 className="backgroundImage"/>
                        </a>
                        <a href={envVariables.IMG_SRC+"/images/characters/backgrounds/" + userContext.character + "/teamsBg2.png"} download
                           className="flex justify-center">
                            <img src={envVariables.IMG_SRC+"/images/characters/backgrounds/" + userContext.character + "/teamsBg2.png"} alt="Background 2"
                                 className="backgroundImage"/>
                        </a>
                        <a href={envVariables.IMG_SRC+"/images/characters/backgrounds/teamsBg3.png"} download
                           className="flex justify-center">
                            <img src={envVariables.IMG_SRC+"/images/characters/backgrounds/teamsBg3.png"} alt="Background 3"
                                 className="backgroundImage"/>
                        </a>
                        <a href={envVariables.IMG_SRC+"/images/characters/backgrounds/" + userContext.character + "/teamsBg4.png"} download
                           className="flex justify-center">
                            <img src={envVariables.IMG_SRC+"/images/characters/backgrounds/" + userContext.character + "/teamsBg4.png"} alt="Background 4"
                                 className="backgroundImage"/>
                        </a>
                    </div>
                    <div className="flex justify-end">
                        <Button variant="contained" color="warning" className="mt-5 teamButton"
                                onClick={downloadImage}>Download all backgrounds</Button>
                    </div>
                </div>
            </div>
            <PageNavigation previous="puzzle" next="employeeBenefits"/>
        </div>
    )
}
export default BackgroundSelect;