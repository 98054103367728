import React from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";

function CompanyStrategy() {

    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Company strategy"}
                    text={"#believeinyourself"} progress={50} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className={"flex justify-center"}>
                        <img src={envVariables.IMG_SRC+"/images/CompanyStrategyPlaceholder.png"} alt={"Company strategy"}
                             className={"max-w-[100%] lg:w-[70%] 1.5lg:w-[60%] xl:w-[65%] 2xl:w-[80%] 3xl:w-[65%]"}/>
                    </div>
                </div>
            </div>
            <PageNavigation skip={false} previous="values" next="expectations"/>
        </div>
    )
}
export default CompanyStrategy;