import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from "@mui/material/DialogContentText";
import {envVariables} from "../../constants/Variables";
import {useContext} from "react";
import {UserContext} from "../../context/UserProvider";
import {DialogTransition} from "../../helperFunctions/Transitions";

const Transition = React.forwardRef(DialogTransition);

export default function CustomGamesDialog() {
    const [open, setOpen] = React.useState(true);
    const userContext = useContext(UserContext)

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                <DialogTitle className="text-center font-bold">{"Before you go any further"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" className="w-fit text-center">
                        To make your journey more enjoyable, we prepared some small games for you.
                        If you are not interested in them, you can press the Skip button, however you won't have as much fun.
                        <img src={envVariables.IMG_SRC+"/images/characters/gamesDialog/" + userContext.character + "/GamesDialogImage.png"} alt="Game dialog" className="mt-2 max-w-[80%]"/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="flex justify-center">
                    <Button variant="contained" color="warning" onClick={handleClose} className="mb-5">Got it, let's go</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
