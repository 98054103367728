import React, {useEffect} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {Link} from "react-router-dom";
import {envVariables} from "../constants/Variables";
import {enqueueSnackbar} from "notistack";

function Entertainment() {
    useEffect(() => {
        enqueueSnackbar('To know more about facilities, click on the item you’re the most interested in!', { variant: 'info' })
        enqueueSnackbar('Besides virtual perks, you can also have a great time in our office!', { variant: 'info' })
    }, [])
    return (
        <div className="pageWrapper bg-secondary">
{/*            <CustomStoryDialog title="Office entertainment!"
                               text="Besides virtual perks, you can also have a great time in our office!
                                To know more about facilities, click on the item you’re the most interested in!"/>*/}
            <NavBar image={"/navbar/toDo.svg"} header={"Entertainment in the office"}
                    text={"We dont only work all day. We also have some fun."} progress={66} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner flex items-start lg:items-center">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 md:gap-y-10 lg:gap-6 lg:justify-items-center">
                        <Link to={'/print'} className="flex justify-center">
                            <img src={envVariables.IMG_SRC+"/images/Entertainment3d.png"} alt="3D printing"
                                 className="eGridImage"/>
                        </Link>
                        <Link to={'/football'} className="flex justify-center">
                            <img src={envVariables.IMG_SRC+"/images/EntertainmentFootball.png"} alt="Table football"
                                 className="eGridImage"/>
                        </Link>
                        <Link to={'/xbox'} className="flex justify-center">
                            <img src={envVariables.IMG_SRC+"/images/EntertainmentXbox.png"} alt="Xbox"
                                 className="eGridImage"/>
                        </Link>
                    </div>
                </div>
            </div>
            <PageNavigation previous="metaverse" next="structure" />
        </div>
    )
}
export default Entertainment;