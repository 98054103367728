import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from "@mui/material/DialogContentText";
import {DialogTransition} from "../../helperFunctions/Transitions";
import {envVariables} from "../../constants/Variables";
import {useNavigate} from "react-router-dom";

const Transition = React.forwardRef(DialogTransition);

export default function RewardDialog(props : any) {
    const [open, setOpen] = React.useState(true);
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    };

    const openBeerCoupon = () => {
/*        if(props.url !== "" || props.url !== undefined) {
            window.open(props.url, '_blank', 'noopener,noreferrer');
        } else {
            navigate('/beerError', {replace: true})
        }*/
        navigate('/puzzle', {replace: true})
    }

    const downloadCertificate = () =>{
        const newWindow = window.open("https://www.newcomerjourney.com/companyoverview/certificateDownload", '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
        handleClose();
        navigate('/feedback', {replace: true});
    }

    return (
        <div>
            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                {props.type === 'tree' ? (
                    <>
                        <DialogTitle className="text-secondary text-center font-bold bg-primary">{props.title}</DialogTitle>
                        <DialogContent className={"bg-primary text-secondary"}>
                            <DialogContentText id="alert-dialog-slide-description" className="w-fit text-center">
                                <img src={envVariables.IMG_SRC+"/images/rewards/TreeCertificate.png"} alt="Tree certificate" className="max-w-full"/>
                                <p className={"text-secondary"}>
                                    Congratulations! You successfully completed the quiz.
                                    We are going to plant a tree in your name!
                                </p>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className="flex justify-center bg-primary">
                            <Button variant="outlined" color="secondary" onClick={handleClose} className="mb-5">Skip</Button>
                            <Button variant="contained" color="secondary" onClick={downloadCertificate} className="mb-5">Download certificate</Button>

                            {/*<Button variant="contained" color="secondary" onClick={downloadImage} className="mb-5">Download</Button>*/}

                        </DialogActions>
                    </>
                ) : (
                    <>
                        <DialogTitle className="text-secondary text-center font-bold bg-team">{props.title}</DialogTitle>
                        <DialogContent className={"bg-team text-secondary"}>
                            <DialogContentText id="alert-dialog-slide-description" className="w-fit text-center">
                                <img src={envVariables.IMG_SRC+"/images/rewards/BeerCertificate.png"} alt="Beer certificate" className="max-w-full"/>
                                <p className={"text-secondary"}>
                                    {/*With this coupon you can choose a time to have some beer with your manager.*/}
                                    Great job! Contact your manager to arrange a time to have some beer together. Cheers!
                                </p>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className="flex justify-center bg-team">
                            {/*<Button variant="outlined" color="secondary" onClick={handleClose} className="mb-5">Skip</Button>*/}
                            {/*<Button variant="contained" color="secondary" onClick={openBeerCoupon} className="mb-5">Select Date</Button>*/}
                            <Button variant="contained" color="secondary" onClick={openBeerCoupon} className="mb-5">Continue</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </div>
    );
}
