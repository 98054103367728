import React, {useState} from 'react';
import {GoogleMap, InfoWindow, MarkerF, useLoadScript} from "@react-google-maps/api";
import NavBar from "../components/NavBar";
import {
    edMarker, edMarkerKosice, edMarkerVienna,
    markersBicycle, markersBicycleKosice, markersBicycleVienna,
    markersBus, markersBusKosice, markersBusVienna,
    markersCar, markersCarKosice, markersCarVienna, markersCombined,
    markersScooter, markersScooterKosice, markersScooterVienna,
    markersTrain, markersTrainKosice, markersTrainVienna,
    markersTram, markersTramKosice, markersTramVienna
} from "../data/mapData";
import {Button} from "@mui/material";
import PageNavigation from "../components/PageNavigation";
import parse from 'html-react-parser';
import {customMapId, envVariables} from "../constants/Variables";
import GetMapSetupData from "../helperFunctions/MapSetup";
import {directionsDataBratislava, directionsDataVienna} from "../data/directionsData";
import LocationPicker from "../components/LocationPicker";

function Map() {
    const [markers, setMarkers] = useState(edMarker.concat(markersTram));
    const [activeMarker, setActiveMarker] = useState(null);
    const [location, setLocation] = useState("bratislava")
    const [directionsData, setDirectionsData] = useState(directionsDataBratislava)
    const [mapSetup, setMapSetup] = useState(GetMapSetupData(window.innerWidth, location))


    const handleLocation = (value: string) => {
        setLocation(value)
        setMapSetup(GetMapSetupData(window.innerWidth, value))
        if(value === "bratislava") {
            setDirectionsData(directionsDataBratislava)
            setMarkers(edMarker.concat(markersTram))
        } else if(value === "kosice") {
            setDirectionsData(directionsDataBratislava)
            setMarkers(edMarkerKosice.concat(markersTramKosice))
        } else if(value === "vienna") {
            setDirectionsData(directionsDataVienna)
            setMarkers(edMarkerVienna.concat(markersTramVienna))
        }
    }

    const handleActiveMarker = (marker : any) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: envVariables.GOOGLE_API_KEY,
        libraries: ["places"],
    });

    if(!isLoaded) {
        return (
            <div>Loading...</div>
        )
    }

    const handleClickBicycle = () => {
        if(location === "bratislava") {
            setMarkers(edMarker.concat(markersBicycle, markersCombined))
        } else if(location === "kosice") {
            setMarkers(edMarkerKosice.concat(markersBicycleKosice))
        } else if(location === "vienna") {
            setMarkers(edMarkerVienna.concat(markersBicycleVienna))
        }
    };
    const handleClickBus = () => {
        if(location === "bratislava") {
            setMarkers(edMarker.concat(markersBus))
        } else if(location === "kosice") {
            setMarkers(edMarkerKosice.concat(markersBusKosice))
        } else if(location === "vienna") {
            setMarkers(edMarkerVienna.concat(markersBusVienna))
        }
    };
    const handleClickTrain = () => {
        if(location === "bratislava") {
            setMarkers(edMarker.concat(markersTrain))
        } else if(location === "kosice") {
            setMarkers(edMarkerKosice.concat(markersTrainKosice))
        } else if(location === "vienna") {
            setMarkers(edMarkerVienna.concat(markersTrainVienna))
        }
    };
    const handleClickScooter = () => {
        if(location === "bratislava") {
            setMarkers(edMarker.concat(markersScooter, markersCombined))
        } else if(location === "kosice") {
            setMarkers(edMarkerKosice.concat(markersScooterKosice))
        } else if(location === "vienna") {
            setMarkers(edMarkerVienna.concat(markersScooterVienna))
        }
    };
    const handleClickCar = () => {
        if(location === "bratislava") {
            setMarkers(edMarker.concat(markersCar))
        } else if(location === "kosice") {
            setMarkers(edMarkerKosice.concat(markersCarKosice))
        } else if(location === "vienna") {
            setMarkers(edMarkerVienna.concat(markersCarVienna))
        }
    };
    const handleClickTram = () => {
        if(location === "bratislava") {
            setMarkers(edMarker.concat(markersTram))
        } else if(location === "kosice") {
            setMarkers(edMarkerKosice.concat(markersTramKosice))
        } else if(location === "vienna") {
            setMarkers(edMarkerVienna.concat(markersTramVienna))
        }
    };
    const showAllMarkers = () => {
        if(location === "bratislava") {
            setMarkers([...edMarker, ...markersTram, ...markersBus, ...markersBicycle, ...markersScooter,
                ...markersCar, ...markersTrain])
        } else if(location === "kosice") {
            setMarkers([...edMarkerKosice, ...markersTramKosice, ...markersBusKosice, ...markersBicycleKosice, ...markersScooterKosice,
                ...markersCarKosice, ...markersTrainKosice])
        } else if(location === "vienna") {
            setMarkers([...edMarkerVienna, ...markersTramVienna, ...markersBusVienna, ...markersBicycleVienna, ...markersScooterVienna,
                ...markersCarVienna, ...markersTrainVienna])
        }
    }

    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Directions to the office"}
                    text={"We support many ways of transport. Consider the impact on the mother Earth ;)"}
                    progress={9} category={"start"}/>
            <div className="contentWrapper ">
                <div className="contentWrapperInner">
                    <LocationPicker handleLocation={handleLocation}/>
                    <div className="flex flex-col lg:flex-row">
                        <div className="w-[100%] h-[60vh] xs:h-[60vh] sm:h-[60vh] md:h-[60vh] lg:h-[65vh] xl:h-[68vh]
                         2xl:h-[73vh]">
                            <GoogleMap
                                zoom={mapSetup.zoom}
                                center={{lat: mapSetup.lat, lng: mapSetup.lng}}
                                mapContainerClassName='map-container'
                                mapContainerStyle={{ height: "100%", width: "100%" }}
                                options={{ mapId: customMapId }}
                                onClick={() => setActiveMarker(null)}
                            >
                                {markers.map(({ id, title, text, textImg, position,
                                                  pinImg }) => (
                                <MarkerF
                                    title={title}
                                    icon={{
                                        url: (envVariables.IMG_SRC+pinImg),
                                        scaledSize: new google.maps.Size(37, 37)
                                    }}
                                    key={id}
                                    position={position}
                                    onClick={() => handleActiveMarker(id)}
                                >
                                    {activeMarker === id ? (
                                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                            <div className="w-[300px]">
                                                <h3 className="">{title}</h3>
                                                <p className="">{parse(text)}</p>
                                                <img src={envVariables.IMG_SRC+"/images/mapDataImages/" + textImg}
                                                     alt="Place view" className="w-[300px]"/>
                                            </div>
                                        </InfoWindow>
                                    ) : null}
                                </MarkerF>
                                ))}
                            </GoogleMap>
                        </div>
                        <div>
                            <h1 className="mb-3 mt-3 md:mb-6 md:mt-6 lg:mt-0 lg:ml-10 text-sm xs: sm: md:text-3xl
                             2xl:text-4xl">
                                Find nearby services by clicking on their image</h1>
                            <div className="mb-5 md:mb-6 lg:ml-10 lg:mb-5 2xl:mb-10">
                                <Button variant="contained" color="warning" onClick={showAllMarkers} className={"startButton"}>Show all</Button>
                            </div>
                            <div className="justify-items-center place-items-center grid
                            grid-cols-3 xs:grid-cols-3 lg:grid-cols-2
                            gap-y-3 md:gap-y-4 lg:gap-8 2xl:gap-8 ">
                                <img className="transitIcon" src={envVariables.IMG_SRC+"/images/mapIcons/tramIcon.png"}
                                     alt="Tram" onClick={handleClickTram}/>
                                <img className="transitIcon" src={envVariables.IMG_SRC+"/images/mapIcons/busIcon.png"}
                                     alt="Bus" onClick={handleClickBus}/>
                                <img className="transitIcon" src={envVariables.IMG_SRC+"/images/mapIcons/bicycleIcon.png"}
                                     alt="Bicycle" onClick={handleClickBicycle}/>
                                <img className="transitIcon" src={envVariables.IMG_SRC+"/images/mapIcons/scooterIcon.png"}
                                     alt="Scooter" onClick={handleClickScooter}/>
                                <img className="transitIcon" src={envVariables.IMG_SRC+"/images/mapIcons/carIcon.png"}
                                     alt="Car" onClick={handleClickCar}/>
                                <img className="transitIcon" src={envVariables.IMG_SRC+"/images/mapIcons/trainIcon.png"}
                                     alt="Train" onClick={handleClickTrain}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageNavigation previous="outfit" next="directionsOffice" />
        </div>
    )
}
export default Map;