import React, {useContext, useState} from 'react'
import { Link } from 'react-router-dom'
import NavBar from "../components/NavBar";
import {Button} from "@mui/material";
import {characterData} from "../data/characterData";
import {envVariables} from "../constants/Variables";
import {UserContext} from "../context/UserProvider";

function CharacterSelect() {
    const [character, setCharacter] = useState(0)
    const userContext = useContext(UserContext)
    const [windowWidth] = useState(window.innerWidth);
    const [background, setBackground] = useState(windowWidth < 1024 ? characterData[character].mobileBackground :
        characterData[character].background)

    const nextCharacter = () => {
        if(character === characterData.length - 1) {
            setCharacter(0)
            setBackground(windowWidth < 1024 ? characterData[0].mobileBackground :
                characterData[0].background)
        } else {
            setCharacter(character + 1)
            setBackground(windowWidth < 1024 ? characterData[character+1].mobileBackground :
                characterData[character+1].background)
        }

    };

    const previousCharacter = () => {
        if(character === 0) {
            setCharacter(characterData.length - 1)
            setBackground(windowWidth < 1024 ? characterData[characterData.length - 1].mobileBackground :
                characterData[characterData.length - 1].background)
        } else {
            setCharacter(character - 1)
            setBackground(windowWidth < 1024 ? characterData[character-1].mobileBackground :
                characterData[character-1].background)
        }
    };

    const chooseCharacter = (value:any) => {
        switch(value) {
            case 0:
                userContext.setCharacter('unicorn')
                localStorage.setItem('character', 'unicorn');
                return
            case 1:
                userContext.setCharacter('sloth')
                localStorage.setItem('character', 'sloth');
                return
            case 2:
                userContext.setCharacter('bee')
                localStorage.setItem('character', 'bee');
                return
            default:
                userContext.setCharacter('unicorn')
                localStorage.setItem('character', 'unicorn');
                return
        }
    }

    return (
        <div className={`pageWrapper ${background}`}>
            <img className="navigationIcon left-[2%]" src={envVariables.IMG_SRC+"/images/back.png"} alt="Go back"
                 onClick={() => previousCharacter()}/>
            <img className="navigationIcon right-[2%]" src={envVariables.IMG_SRC+"/images/front.png"} alt="Go next"
                 onClick={() => nextCharacter()}/>
            <NavBar image={"/navbar/toDo.svg"} header={"CHOOSE YOUR CHARACTER"} text={"Use the arrows to" +
                " select the character which will represent you! You will not be judged by your choice. Its just for fun!"} progress={3} category={"welcome"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner flex lg:grid lg:grid-cols-2 gap-2 justify-items-center">
                    <div className={"relative place-self-start"}>
                        <p className={"absolute bg-black/60 rounded-md w-16 text-center text-white"}>{character + 1} / 3</p>
                    </div>
                    <div className="2xl:pl-48">
                        <h1 className={`${characterData[character].titleColor} mt-0 lg:mt-10 text-center lg:text-left
                         text-6xl font-bold mb-3`}>
                            {characterData[character].title}
                        </h1>
                        <div className={"w-[70%] md:w-[100%] px-[15%] md:px-0"}>
                            <h3 className={`${characterData[character].descriptionColor} text-center lg:text-left 
                            text-3xl mb-0`}>
                                {characterData[character].description}
                            </h3>
                            <p className={`${characterData[character].textColor} text-center lg:text-left text-2xl
                             pb-2 m-0 my-3`}>
                                “{characterData[character].text}” <br/>
                                {characterData[character].author}
                            </p>
                        </div>
                        <div className="flex justify-center lg:justify-start mb-5">
                            <Button component={Link} to={'/outfit'} variant="contained" color="warning" size="large"
                                    onClick={() => chooseCharacter(character)}>Select</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CharacterSelect;