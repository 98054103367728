import React from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";
import {useNavigate} from "react-router-dom";

export default function Learning() {
    const navigate = useNavigate()
    const redirect = (target : string) => {
        console.log("redirect")
        navigate('/'+target, {replace: false})
    }

    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Knowledge sharing"} text={"Various channels to share your ideas " +
                "or to gain new knowledge. Click on respective image to learn more."} progress={50} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="hidden lg:grid grid-cols-3 gap-2 justify-items-center">

                        <img src={envVariables.IMG_SRC+"/images/learning/Learning1.png"} alt="Learning 1" className="learningImage" onClick={() => redirect("ideasReason")}/>
                        <img src={envVariables.IMG_SRC+"/images/learning/Learning2.png"} alt="Learning 2" className="learningImage" onClick={() => redirect("infoSessions")}/>
                        <img src={envVariables.IMG_SRC+"/images/learning/Learning3.png"} alt="Learning 3" className="learningImage" onClick={() => redirect("courses")}/>

                        <h3>Erste Ideas</h3>
                        <h3>Info sessions</h3>
                        <h3>Courses</h3>

                        <p>Portal where employees share their ideas</p>
                        <p>Presentations that  educate and inform about a particular innovative topic</p>
                        <p>A wide range of courses to employees, including training courses related to job-specific skills</p>
                    </div>

                    <div className={"lg:hidden grid grid-cols-1 gap-4"}>
                        <div>
                            <div className={"flex justify-center"}>
                                <img src={envVariables.IMG_SRC+"/images/learning/Learning1.png"} alt="Learning 1" className="learningImageMobile" onClick={() => redirect("ideasReason")}/>
                            </div>
                            <h3 className={"learningText"}>Erste Ideas</h3>
                            <p className={"learningText"}>Portal where employees share their ideas</p>
                        </div>
                        <div>
                            <div className={"flex justify-center"}>
                                <img src={envVariables.IMG_SRC+"/images/learning/Learning2.png"} alt="Learning 2" className="learningImageMobile" onClick={() => redirect("infoSessions")}/>
                            </div>
                            <h3 className={"learningText"}>Info sessions</h3>
                            <p className={"learningText"}>Presentations that  educate and inform about a particular innovative topic</p>
                        </div>
                        <div>
                            <div className={"flex justify-center"}>
                                <img src={envVariables.IMG_SRC+"/images/learning/Learning3.png"} alt="Learning 3" className="learningImageMobile" onClick={() => redirect("courses")}/>
                            </div>
                            <h3 className={"learningText"}>Courses</h3>
                            <p className={"learningText"}>A wide range of courses to employees, including training courses related to job-specific skills</p>
                        </div>
                    </div>
                </div>
            </div>
            <PageNavigation previous="breakfast" next="ideasReason"/>
        </div>
    )
}