import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {TextField} from "@mui/material";
import {useContext, useState} from "react";
import {UserContext} from "../../context/UserProvider";
import {CheckSurnameInput, loginUser} from "../../helperFunctions/UserInput";
import {DialogTransition} from "../../helperFunctions/Transitions";
import LoginFeedbackDialog from "./LoginFeedbackDialog";
import EditIcon from '@mui/icons-material/Edit';

const Transition = React.forwardRef(DialogTransition);

export default function ChangeNameDialog(props : any) {
    const [open, setOpen] = React.useState(false);
    const userContext = useContext(UserContext)
    const [tmpUser, setTmpUser] = useState("")
    const [openAlert, setOpenAlert] = useState(false)
    const [openLoginFeedback, setOpenLoginFeedback] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = async () => {
        // let result = await ChangeSurname(tmpUser)
        // userContext.setUserName(result.name)
        // userContext.setIsAuth(result.isAuth)
        // userContext.setRole(result.role)
        // setOpen(false)
        // setOpenLoginFeedback(true)
        let axiosResult = await loginUser(tmpUser)
        console.log(axiosResult)
        userContext.setUserName(axiosResult.name)
        userContext.setIsAuth(axiosResult.isAuth)
        userContext.setRole(axiosResult.role)
        userContext.setId(axiosResult.id)
        userContext.setToken(axiosResult.token)
        userContext.setCouponUrl(axiosResult.couponUrl)
        userContext.setLocation(axiosResult.location!)
        //userContext.setLocation("vienna")
        userContext.setSteps(axiosResult.steps)
        setOpen(false)
        setOpenLoginFeedback(true)
    }

    const getNameInput = (e: any) => {
        setTmpUser(e.target.value)
    }

    const onChangeHandler = (event : any) => {
        getNameInput(event)
        if(CheckSurnameInput(event)) {
            setOpenAlert(false)
        } else {
            event.preventDefault()
            setOpenAlert(true)
        }
    }
    return (
        <div onClick={props.customClickEvent}>
            {/*<Button sx={{ border: 1 }} color={"info"} variant="contained" onClick={handleClickOpen}*/}
            {/*        className="text-secondary hover:text-primary border-solid border-5 border-white">*/}
            {/*    Change surname*/}
            {/*</Button>*/}
            <EditIcon onClick={handleClickOpen} className={"cursor-pointer text-white"}/>
            {openLoginFeedback && <LoginFeedbackDialog open={true} validated={userContext.isAuth}/>}
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className="">{"Change your surname"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <span className="">If you are our future employee, be sure to type in your <b>surname</b>.</span>
                    </DialogContentText>
                    <TextField
                        error={openAlert}
                        helperText={openAlert ? "This is input can contain only letters!" : ""}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="New surname"
                        type="text"
                        fullWidth
                        variant="outlined"
                        color="primary"
                        onChange={(event) => onChangeHandler(event)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color={"warning"} onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" disabled={openAlert} onClick={handleConfirm} color={"warning"}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
