import React, {useContext, useEffect, useState} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import CustomConfirmationDialog from "../components/dialog/CustomConfirmationDialog";
import {envVariables} from "../constants/Variables";
import {getMealAllowances, loginUser} from "../helperFunctions/UserInput";
import {useParams} from "react-router-dom";
import {UserContext} from "../context/UserProvider";
import LoginFeedbackDialog from "../components/dialog/LoginFeedbackDialog";

function Tickets() {
    const [selected, setSelected] = useState(0)
    const routeParams = useParams() as any;
    const userContext = useContext(UserContext)
    const [openLoginFeedback, setOpenLoginFeedback] = useState(false)
    //const [mealAllowances, setMealAllowances] = useState<any>([])
    const [financialContribution, setFinancialContribution] = useState(0)
    const [ticketRestaurant, setTicketRestaurant] = useState(0)

    useEffect(() => {
        checkParams()
        getData()
    }, []);

    const checkParams = async () => {
        if (routeParams.surname !== undefined) {
            console.log("Found params!")
            console.log(routeParams.surname)

            let axiosResult = await loginUser(routeParams.surname)
            userContext.setUserName(axiosResult.name)
            userContext.setFirstName(axiosResult.firstName)
            userContext.setIsAuth(axiosResult.isAuth)
            userContext.setRole(axiosResult.role)
            userContext.setId(axiosResult.id)
            userContext.setToken(axiosResult.token)
            userContext.setCouponUrl(axiosResult.couponUrl)
            userContext.setLocation(axiosResult.location!)
            userContext.setSteps(axiosResult.steps)
            setOpenLoginFeedback(true)
        } else {
            console.log("No params found!")
        }
    }

    const getData = async () => {
        let mealAllowances = await getMealAllowances();
        console.log(mealAllowances)
        mealAllowances.forEach((item: any) => {
            if(item.name === "Financial contribution") {
                setFinancialContribution(item.id)
            } else {
                setTicketRestaurant(item.id)
            }
        })

    }

    const handleChange = (e: any) => {
        setSelected(e.target.value)
    }

    return (
        <div className="pageWrapper bg-secondary">
            {openLoginFeedback ? <LoginFeedbackDialog open={true} validated={userContext.isAuth} redirect={false}/> : null}
            <NavBar image={"/navbar/toDo.svg"} header={"Card vs Money"} text={"Would you prefer to recieve money" +
                " for food on card or included in your paycheck?"} progress={27} category={"start"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <h1 className="text-center m-0 mb-2 md:mb-5 2xl:mb-10
                    text-lg md:text-2xl 1.5xl:text-3xl 2xl:text-4xl">
                        As we are talking about the food, please choose what
                        you prefer</h1>
                    <div className="grid grid-cols-1 justify-items-center lg:grid-cols-2 gap-2 mb-2 md:mb-5 2xl:mb-10 w-full">
                        <img src={envVariables.IMG_SRC+"/images/cardImg.png"} alt="Card"
                             className="ticketImage lg:place-self-start"/>
                        <img src={envVariables.IMG_SRC+"/images/moneyImg.png"} alt="Money"
                             className="ticketImage lg:place-self-end"/>
                    </div>
                    <div className="flex w-full">
                        <FormControl className={"w-full"}>
                            <FormLabel id="radio-buttons-group-label" className="formLabel" color={"warning"}>
                                Choose* which type of food benefit do you want</FormLabel>
                            <RadioGroup row aria-labelledby="radio-buttons-group-label"
                                        name="row-radio-buttons-group" className="flex content-between mb-5 lg:mb-0"
                                        onChange={handleChange} defaultValue="card">
                                <FormControlLabel value={ticketRestaurant} control={<Radio color={"warning"} />} label="I want to
                                receive meal vouchers (provided via Ticket Restaurant card)"
                                                  className="formControlLabel"/>
                                <FormControlLabel value={financialContribution} control={<Radio color={"warning"} />} label="I want financial contribution for meals" className="formControlLabel"/>
                            </RadioGroup>
                            <div>
                                <p className={"text-warning ml-5"}>*The employee is tied by his/her choice till 31st December of actual year. Both choices have equal value.</p>
                            </div>
                            <div className={"flex justify-end"}>
                                <CustomConfirmationDialog radio={true}
                                                          buttonText="Select"
                                                          title="Confirm that you have entered correct surname"
                                                          text="After you confirm your surname, your choice will be sent to our HR team. You will be
                                 asked to formally confirm this choice by email or in person."
                                                          item="food"
                                                          selected={selected}
                                                          redirect={"foodBenefitWait"}
                                />
                            </div>
                        </FormControl>
                    </div>
                </div>
            </div>
            {userContext.skip ?
                <PageNavigation choice={true} skip={true} previous="equipment" next="feedback"/> :
                <PageNavigation choice={true} skip={true} previous="documents" next="eat"/>
            }
        </div>
    )
}
export default Tickets;