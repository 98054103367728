import React from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";

function Football() {
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Table football"} text={"You can play table football with your" +
                " colleagues"} progress={72} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="entertainmentTopicWrapper">
                        <div className="col-span-2">
                            <img src={envVariables.IMG_SRC+"/images/football.png"} alt="Football"
                                 className="entertainmentImage"/>
                        </div>
                        <div>
                            <h1 className="entertainmentHead">It Gets Your Mind Moving</h1>
                            <p className="entertainmentText">
                                Table football can be a mentally active game, even if you don’t use the proper scoring.
                                You have to focus on the ball and work on your reaction time in order to score a point.
                                It’s nice to use table football as a way to get your mind moving without having to think
                                directly about a work problem. A table football can be a particularly good tool for
                                creatives whose minds might wander while engaged in a rousing match.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <PageNavigation previous="entertainment" next="xbox"/>
        </div>
    )
}
export default Football;