import React from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";

function BenefitsSecond() {
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Employee benefits"}
                    text={"Benefits provided after probation period"} progress={48} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className={"hidden lg:flex justify-center"}>
                        <img src={envVariables.IMG_SRC+"/images/benefits/Benefits2.png"} alt="Employee benefits" className="max-h-[70vh] max-w-full"/>
                    </div>
                    <div className={"grid grid-cols-2 gap-4 lg:hidden"}>
                        <div className={"flex justify-center col-span-2"}>
                            <img src={envVariables.IMG_SRC+"/images/benefits/second/BenefitsHeader2.png"} alt="Employee benefits" className="max-h-[70vh] max-w-full"/>
                        </div>
                        {(() => {
                            let images = []
                            for (let i = 1; i < 10; i++) {
                                images.push(<img src={envVariables.IMG_SRC+"/images/benefits/second/" + i + ".png"}
                                                 alt="Benefit"
                                                 className="max-w-[100%] lg:w-[75%] 1.5xl:w-[80%] 2xl:w-[100%] 3xl:w-[80%]" key={i}/>)
                            }
                            return images
                        })()}

                    </div>
                </div>
            </div>
            <PageNavigation previous="employeeBenefits" next="breakfast"/>
        </div>
    )
}
export default BenefitsSecond;