import React, {useEffect} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";
import {enqueueSnackbar} from "notistack";

function Structure() {
    useEffect(() => {
        enqueueSnackbar('Our company is flat. No necessary structures to move faster. Take a look.', { variant: 'info' })
    }, [])
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Organizational Structure"}
                    text={"Flat structure of our organization."} progress={78} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="flex flex-col justify-center lg:grid lg:grid-cols-3 lg:gap-2 justify-items-center place-items-center">
                        <div className="col-span-2">
                            <img src={envVariables.IMG_SRC+"/images/OrganizationalStructure.png"} alt="Structure"
                                 className="max-w-[100%] lg:h-[60vh] 1.5lg:h-[65vh] 2xl:h-[70vh]"/>
                        </div>
                        <div>
                            <h1 className="text-center lg:text-start text-base md:text-xl 1.5lg:text-2xl 1.5xl:text-3xl 2xl:text-4xl">In our company we use agile methodology for managing projects</h1>
                            <div className="flex justify-center">
                                <img src={envVariables.IMG_SRC+"/images/Agile.png"} alt="Agile"
                                     className="max-w-[100%] lg:w-[90%] h-[25vh] md:h-[35vh] lg:h-[38vh] 1.5xl:h-[45vh] 2xl:h-[50vh]"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageNavigation previous="entertainment" next="values"/>
        </div>
    )
}
export default Structure;