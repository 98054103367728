import React from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";

function Xbox() {
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Xbox"} text={"Games can create an environment of trust."}
                    progress={75} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="entertainmentTopicWrapper">
                        <div className="col-span-2">
                            <img src={envVariables.IMG_SRC+"/images/xbox.png"} alt="Xbox"
                                 className="entertainmentImage"/>
                        </div>
                        <div>
                            <h1 className="entertainmentHead">Xbox games for fun</h1>
                            <p className="entertainmentText">
                                Many people think that video games are a solitary activity, but that's definitely not
                                the case, especially with today's powerful consoles. Just play with your colleagues and
                                relax. And, if you want to move more, use Kinect and play some sports or dance.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <PageNavigation previous="entertainment" next="structure"/>
        </div>
    )
}
export default Xbox;