import { createTheme } from '@mui/material/styles';

export const muiTheme = createTheme({
    palette: {
        primary: {
            light: '#fff',
            main: '#2870ED',
            dark: '#000',
            contrastText: '#fff'
        },
        secondary: {
            main: '#fff',
        },
        warning: {
            main: '#FF6130',
            contrastText: '#fff'
        },

        info: {
            light: '#fff',
            main: '#2870ED',
            dark: '#fff',
            contrastText: '#2870ED',
            text: '#fff',
        },
        success: {
            light: '#fff',
            main: '#A3B5C9',
            dark: '#fff',
            contrastText: '#2870ED',
        }

    },
    styleOverrides: {
        button: {
            color: '#FF6130',
        }
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                color: '#FF6130',
            }
        }

    },
});