import axios from 'axios';

const axiosClient = axios.create({
    baseURL: 'https://newcomerjourney.com:8443/api/',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});
//Authorization: 'Bearer' + localStorage.getItem("token")//'Bearer':  localStorage.getItem('token'),
axiosClient.interceptors.request.use(
    (config) => {
        const newConfig = { ...config };
        const token = localStorage.getItem('token');
        if (token) {
            //newConfig!.headers!.Authorization = `Bearer ${token}`;
            newConfig!.headers!.Authorization = `${token}`;
            //newConfig!.headers!.Authorization = `X-Auth-Token ${token}`;
        }
        //console.log(token)
        return newConfig;
    },

    (error) => Promise.reject(error),
);
/*axiosClient.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error)
});*/

const ax = { axiosClient };

export default ax;