const prod = {
    IMG_SRC: "/companyoverview",
    GOOGLE_API_KEY: "AIzaSyByobAa9O7KgR0haeafb1vjr8F_ZshMio0",
    LINKEDIN_CLIENT_ID: "7891c0snsrz8lj",
    LINKEDIN_CLIENT_SECRET: "Cjg7A9aUzcqpkCbl",
    LINKEDIN_GRANT_TYPE: "client_credentials"
}

const dev = {
    IMG_SRC: "/companyoverview",
    GOOGLE_API_KEY: "AIzaSyByobAa9O7KgR0haeafb1vjr8F_ZshMio0",
    LINKEDIN_CLIENT_ID: "7891c0snsrz8lj",
    LINKEDIN_CLIENT_SECRET: "Cjg7A9aUzcqpkCbl",
    LINKEDIN_GRANT_TYPE: "client_credentials"
};

export const envVariables = process.env.NODE_ENV === 'development' ? dev : prod;

export const mapCenterMobile = {
    lat: 48.17119172725368,
    lng: 17.142153719792724
}
export const mapCenterMobileKosice = {
    lat: 48.718753116917014,
    lng: 21.246524547057298
}
export const mapCenterMobileVienna = {
    lat: 48.18640014102546,
    lng: 16.38139931636231
}

export const mapCenterTablet = {
    lat: 48.16907745022273,
    lng: 17.143009331108733
}
export const mapCenterTabletKosice = {
    lat: 48.718753116917014,
    lng: 21.246524547057298
}
export const mapCenterTabletVienna = {
    lat: 48.18640014102546,
    lng: 16.38139931636231
}

export const mapCenterSmallDesktop = {
    lat: 48.17119172725368,
    lng: 17.142153719792724
}
export const mapCenterSmallDesktopKosice = {
    lat: 48.718753116917014,
    lng: 21.246524547057298
}
export const mapCenterSmallDesktopVienna = {
    lat: 48.18640014102546,
    lng: 16.38139931636231
}
export const mapCenterDesktop = {
    lat: 48.16907745022273,
    lng: 17.143009331108733
}
export const mapCenterDesktopKosice = {
    lat: 48.718753116917014,
    lng: 21.246524547057298
}
export const mapCenterDesktopVienna = {
    lat: 48.18640014102546,
    lng: 16.38139931636231
}
export const customMapId = "23cd3e8c1b160dcc";