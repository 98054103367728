import React, {useContext, useEffect, useState} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {UserContext} from "../context/UserProvider";
import {GoogleMap, InfoWindow, MarkerF, useLoadScript} from "@react-google-maps/api";
import {Button} from "@mui/material";
import {edMarker, edMarkerVienna, markersEat, markersEatKosice, markersEatVienna} from "../data/mapData";
import {officePlacesBratislava, officePlacesCampus, officePlacesKosice} from "../data/officeData";
import parse from "html-react-parser";
import {envVariables} from "../constants/Variables";
import FoundCharactersDialog from "../components/dialog/FoundCharactersDialog";
import GetMapSetupData from "../helperFunctions/MapSetup";
import {enqueueSnackbar} from "notistack";
import LocationPicker from "../components/LocationPicker";

function Eat() {
    const userContext = useContext(UserContext)
    const [map, setMap] = useState(false)
    const [markers, setMarkers] = useState(edMarker.concat(markersEat));
    const [activeMarker, setActiveMarker] = useState(null);
    const [currentPlace, setCurrentPlace] = useState(0)
    const [score, setScore] = useState(0)
    const [foundAllCharacters, setFoundAllCharacters] = useState(false)
    const [location, setLocation] = useState("bratislava")
    const [mapSetup, setMapSetup] = useState(GetMapSetupData(window.innerWidth, location))
    const [officePlaces, setOfficePlaces] = useState(officePlacesBratislava)
    const numberOfPlaces = officePlaces.length
    const [foundCharacters, setFoundCharacters] = useState(new Array(numberOfPlaces).fill(0))

    useEffect(() => {
        enqueueSnackbar('Let’s see where you can relax and have a snack before the meetings.', { variant: 'info' })
        setUpOfficePlaces()
    }, [])

    const setUpOfficePlaces = () => {
        console.log(userContext.location)
        if(userContext.location === "vienna") {
            setOfficePlaces(officePlacesCampus)
        } else {
            setOfficePlaces(officePlacesBratislava)
        }
    }

    const handleLocation = (value: string) => {
        setLocation(value)
        setMapSetup(GetMapSetupData(window.innerWidth, value))
        if(value === "bratislava") {
            setMarkers(edMarker.concat(markersEat))
            setOfficePlaces(officePlacesBratislava)
            setScore(0)
            setFoundCharacters(new Array(officePlacesBratislava.length).fill(0))
            setCurrentPlace(0)
        } else if(value === "kosice") {
            setMarkers(edMarker.concat(markersEatKosice))
            setOfficePlaces(officePlacesKosice)
            setScore(0)
            setFoundCharacters(new Array(officePlacesKosice.length).fill(0))
            setCurrentPlace(0)
        } else if(value === "vienna") {
            setMarkers(edMarkerVienna.concat(markersEatVienna))
            setOfficePlaces(officePlacesCampus)
            setScore(0)
            setFoundCharacters(new Array(officePlacesCampus.length).fill(0))
            setCurrentPlace(0)
        }
    }

    const foundCharacter = (position : number) => {
        foundCharacters[position] = 1
        countScore()
        if(score === numberOfPlaces-1) {
            setFoundAllCharacters(true)
        }
    }

    const countScore = () => {
        let result = 0
        foundCharacters.forEach((value) => {
            result += value;
        })
        setScore(result)
    }

    const changeViewToMap = () => {
        setMap(true)
    }

    const changeViewToInside = () => {
        setMap(false)
    }

    const previousPlace = () => {
        if(currentPlace === 0) {
            setCurrentPlace(officePlaces.length - 1)
        } else {
            setCurrentPlace(currentPlace - 1)
        }
    }

    const nextPlace = () => {
        if(currentPlace === officePlaces.length - 1) {
            setCurrentPlace(0)
        } else {
            setCurrentPlace(currentPlace + 1)
        }
    }

    const handleActiveMarker = (marker : any) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: envVariables.GOOGLE_API_KEY,
        libraries: ["places"],
    });

    if(!isLoaded) {
        return (
            <div>Loading...</div>
        )
    }

    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Eating & chilling"} text={"By now you must be very hungry" +
                " or at least want to have a little rest. Let's look at some of the places designed for it."}
                    progress={33} category={"start"}/>
            {foundAllCharacters ? (
                <FoundCharactersDialog/>
            ) : (
                <></>
            )}
            <div className={`contentWrapper text-xl`} >
                <div className="contentWrapperInner">
                    <div className="mb-5">
                        {map ? (
                            <>
                                <Button variant="outlined" color="warning" className="mr-10"
                                        onClick={changeViewToInside}>Inside</Button>
                                <Button variant="contained" color="warning"
                                        onClick={changeViewToMap} className={"mr-4"}>Outside</Button>
                            </>
                        ) : (
                            <>
                                <Button variant="contained" color="warning" className="mr-10"
                                        onClick={changeViewToInside}>Inside</Button>
                                <Button variant="outlined" color="warning"
                                        onClick={changeViewToMap} className={"mr-4"}>Outside</Button>
                            </>
                        )}
                        <LocationPicker handleLocation={handleLocation}/>
                    </div>
                    <div className="flex">
                        {map ? (
                            <div className="h-[70vh] md:h-[70vh] lg:h-[55vh] xl:h-[60vh] 2xl:h-[65vh] w-[100%]">
                                <GoogleMap
                                    zoom={mapSetup.zoom}
                                    center={{lat: mapSetup.lat, lng: mapSetup.lng}}
                                    mapContainerClassName='map-container'
                                    mapContainerStyle={{ height: "100%", width: "100%" }}
                                    options={{ mapId: "23cd3e8c1b160dcc" }}
                                    onClick={() => setActiveMarker(null)}
                                >
                                    {markers.map(({ id, title, text, textImg, position, pinImg }) => (
                                        <MarkerF
                                            title={title}
                                            icon={{
                                                url: (envVariables.IMG_SRC+pinImg),
                                                scaledSize: new google.maps.Size(37, 37)
                                            }}
                                            key={id}
                                            position={position}
                                            onClick={() => handleActiveMarker(id)}
                                        >
                                            {activeMarker === id ? (
                                                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                                    <div className="w-[300px]">
                                                        <h3 className="text-primary">{title}</h3>
                                                        <p className="text-primary">{parse(text)}</p>
                                                        <img src={envVariables.IMG_SRC+"/images/mapDataImages/" + textImg}
                                                             alt="Place view"
                                                             className="w-[300px]"/>
                                                    </div>
                                                </InfoWindow>
                                            ) : null}
                                        </MarkerF>
                                    ))}
                                </GoogleMap>
                            </div>
                        ) : (
                            <div className="grid grid-cols-1 lg:grid-cols-2">
                                <div className="flex justify-center relative h-[40vh] eWrapper">
                                    <div className={"absolute top-5 left-5 bg-white/50 rounded-lg"}>
                                        <p className={"m-1 text-white/100"}>{currentPlace+1}/{numberOfPlaces}</p>
                                    </div>
                                    <img className="navigationIcon left-[5%]"
                                         src={envVariables.IMG_SRC+"/images/backWhite.png"} alt="Go back"
                                         onClick={previousPlace}/>
                                    <img className="navigationIcon right-[5%]"
                                         src={envVariables.IMG_SRC+"/images/frontWhite.png"} alt="Go next"
                                         onClick={nextPlace}/>
                                    <img className={`characterMini ${officePlaces[currentPlace].characterLocation.x} 
                                    ${officePlaces[currentPlace].characterLocation.y} absolute cursor-pointer 
                                    w-6 md:w-10 lg:w-8 1.5lg:w-8 xl:w-10 2xl:w-10`}
                                         src={envVariables.IMG_SRC+"/images/characters/mini/" + userContext.character + "/mini.png"}
                                         alt="Character" onClick={() => foundCharacter(currentPlace)}/>
                                    <img src={envVariables.IMG_SRC+"/images/office/"+officePlaces[currentPlace].image}
                                         alt="Office place"
                                         className="eWrapper"/>
                                </div>

                                <div className="flex flex-col lg:pl-10 2xl:pl-20">
                                    <div className="relative order-2 lg:order-1 mt-5 lg:mt-0">
                                        <img src={envVariables.IMG_SRC+"/images/characters/eat/" + userContext.character + "/EatGameInstructions.png"}
                                             alt="Instructions"
                                             className="max-w-[100%] lg:block lg:w-[21vh] xl:w-[25vh] 1.5xl:w-[30vh] 2xl:w-[30vh]"/>
                                        <h2 className="text-center hidden lg:block absolute left-0 top-[65%] bottom-[10%]
                                         text-warning text-xl xl:text-2xl 2xl:text-3xl">
                                            Found: <br/> {score}/{numberOfPlaces}</h2>
                                    </div>
                                    <div className={"order-1 lg:order-2 relative"}>
                                        <h3 className="text-center block lg:hidden absolute right-0 top-[10%] right-[6%]
                                         text-warning">Found: <br/> {score}/{numberOfPlaces}</h3>
                                        <h1 className="eHeadMain">{officePlaces[currentPlace].name}</h1>
                                        <p className="eText">{officePlaces[currentPlace].floor}</p>
                                        <h2 className="eHead">Description</h2>
                                        <p className="eText">{officePlaces[currentPlace].description}</p>
                                        <h2 className="eHead">Availability: {officePlaces[currentPlace].availability}</h2>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {userContext.isAuth ? (
                <PageNavigation skip={true} previous="foodBenefit" next="team"/>
            ) : (
                <PageNavigation skip={true} previous="documents" next="puzzle"/>
            )}
        </div>
    )
}
export default Eat;