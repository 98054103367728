import React, {useContext, useState} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import Flower from "../components/coloring/Flower";
import ColorPalette from "../components/coloring/ColorPalette";
import {UserContext} from "../context/UserProvider";
import {envVariables} from "../constants/Variables";

function Coloring() {
    const [fillColors, setFillColors] = useState(Array(22).fill('white'))
    const [currentColor, setCurrentColor] = useState('blue')
    const userContext = useContext(UserContext)
    const [chosenPicture, setChosenPicture] = useState(false)

    const onFillColor = (i: any) => {
        let newFillColors = fillColors.slice(0)
        newFillColors[i] = currentColor
        setFillColors(newFillColors)
    }

    const choosePicture = () => {
        setChosenPicture(true)
    }

    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Coloring therapy"}
                    text={"If one of our chill zones weren't enough, try to clear your mind with simple coloring activity."}
                    progress={84} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    {chosenPicture ? (
                        <div className="lg:grid lg:grid-cols-2">
                            <div className="flower">
                                <Flower fillColors={fillColors} onFill={onFillColor}/>
                            </div>
                            <div className="lg:ml-20 flex-col justify-center items-center">
                                <div className="flex justify-center mb-10">
                                    <ColorPalette currentColor={currentColor} changeColor={setCurrentColor} />
                                </div>
                                <img src={envVariables.IMG_SRC+"/images/characters/coloring/" + userContext.character +
                                    "/ColoringInfo.png"} alt="Coloring info"
                                     className="max-w-[100%] h-[10vh] lg:h-[auto]"/>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col lg:grid lg:grid-cols-2">
                            <div className= "flower cursor-pointer order-2 lg:order-1" onClick={choosePicture}>
                                <Flower fillColors={fillColors} onFill={onFillColor}/>
                            </div>
                            <div>
                                <img src={envVariables.IMG_SRC+"/images/characters/coloring/" + userContext.character +
                                    "/ColoringInstructions.png"} alt="Coloring info"
                                     className="max-w-[100%] lg:h-[60vh] 1.5xl:h-[70vh] order-1 lg:order-2"/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <PageNavigation skip={true} previous="values" next="quiz"/>
        </div>
    )
}
export default Coloring;