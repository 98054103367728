import React, {useEffect, useState} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {infoSessions} from "../data/infoSessionsData";
import {envVariables} from "../constants/Variables";
import {enqueueSnackbar} from "notistack";

function InfoSessions() {
    const [currentTopic, setCurrentTopic] = useState(0)

    useEffect(() => {
        enqueueSnackbar('Let’s look at some stuff we discuss internally. Each month we have at least one short presentation on interesting topic. You can read their summary here.', { variant: 'info' })
    }, [])

    const changeTopic = (value : any) => {
        setCurrentTopic(value)
    }

    return (
        <div className="pageWrapper bg-secondary">
{/*            <CustomStoryDialog title="We hope you are relaxed now!"
                               text="After short break of coloring, let’s look at some stuff we discuss internally.
                               Each month we have at least one short presentation on interesting topic. You can read
                               their summary here."/>*/}
            <NavBar image={"/navbar/toDo.svg"} header={"Info Sessions"} text={"Serves as an informal educational" +
                " platform for our employees."} progress={57} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="lg:grid lg:grid-cols-2 lg:gap-20 lg:justify-items-center ">
                        <div>
                            <div className="flex justify-center">
                                <img src={envVariables.IMG_SRC+"/images/infoSessions/"+infoSessions[currentTopic].image}
                                     alt="Session illustration" className="selectedTopicImage"/>
                            </div>

                            <p className="selectedTopicText">{infoSessions[currentTopic].date}</p>
                            <h1 className="selectedTopicHead">{infoSessions[currentTopic].title}</h1>
                            <p className="selectedTopicText">{infoSessions[currentTopic].text}</p>
                        </div>
                        <div className={"mt-2 md:mt-5 lg:mt-0"}>
                            <div className="miniTopicWrapper" onClick={() => changeTopic(0)}>
                                <img src={envVariables.IMG_SRC+"/images/infoSessions/"+infoSessions[0].image}
                                     alt="Session illustration" className="miniTopicImage"/>
                                <div className="miniTopicTextWrapper">
                                    <p className="selectedTopicText">{infoSessions[0].date}</p>
                                    <h1 className="selectedTopicHead">{infoSessions[0].title}</h1>
                                </div>
                            </div>
                            <div className="miniTopicWrapper" onClick={() => changeTopic(1)}>
                                <img src={envVariables.IMG_SRC+"/images/infoSessions/"+infoSessions[1].image}
                                     alt="Session illustration" className="miniTopicImage"/>
                                <div className="miniTopicTextWrapper">
                                    <p className="selectedTopicText">{infoSessions[1].date}</p>
                                    <h1 className="selectedTopicHead">{infoSessions[1].title}</h1>
                                </div>
                            </div>
                            <div className="miniTopicWrapper" onClick={() => changeTopic(2)}>
                                <img src={envVariables.IMG_SRC+"/images/infoSessions/"+infoSessions[2].image}
                                     alt="Session illustration" className="miniTopicImage"/>
                                <div className="miniTopicTextWrapper">
                                    <p className="selectedTopicText">{infoSessions[2].date}</p>
                                    <h1 className="selectedTopicHead">{infoSessions[2].title}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageNavigation previous="ideas" next="usefulLinks"/>
        </div>
    )
}
export default InfoSessions;