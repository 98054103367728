import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {createTheme, ThemeProvider} from "@mui/material/styles";

const { palette } = createTheme();
const theme = createTheme({
    palette: {
        primary: palette.augmentColor({
            color : {
                main: '#254375'
            }})
    },
});

export default function LocationPicker(props : any) {
    const [value, setValue] = React.useState('bratislava');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        props.handleLocation((event.target as HTMLInputElement).value)
    };

    return (
        <ThemeProvider theme={theme}>
        <FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
            >
                <FormControlLabel value="bratislava" control={<Radio/>} label="Bratislava" />
                <FormControlLabel value="kosice" control={<Radio />} label="Košice" />
                <FormControlLabel value="vienna" control={<Radio />} label="Vienna" />
            </RadioGroup>
        </FormControl>
        </ThemeProvider>
    );
}