import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from "@mui/material/DialogContentText";
import {envVariables} from "../../constants/Variables";
import {DialogTransition} from "../../helperFunctions/Transitions";
import BootstrapDialogTitle from "./BootstrapDialogTitle";

const Transition = React.forwardRef(DialogTransition);

export default function CustomBoxDialog(props : any) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="flex justify-center">
            {props.box === 1 ? (
                <img src={envVariables.IMG_SRC+"/images/Box1Img.png"} alt="Box with lego" onClick={handleClickOpen} className="boxImage" />
            ) : (
                <img src={envVariables.IMG_SRC+"/images/Box2Img.png"} alt="Box with anti-stress enter button" onClick={handleClickOpen} className="boxImage"/>
            )}

            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description" fullWidth maxWidth="lg">
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}></BootstrapDialogTitle>
                <DialogContent>

                    <DialogContentText id="alert-dialog-slide-description" className="w-fit">
                        {props.box === 1 ? (
                            <span><img src={envVariables.IMG_SRC+"/images/boxContent/boxContent1.png"} alt="Box 1" className="max-w-[100%] w-[100%]"/></span>
                        ) : (
                            <span><img src={envVariables.IMG_SRC+"/images/boxContent/boxContent2.png"} alt="Box 2" className="max-w-[100%]"/></span>
                        )}

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
);
}
