import React from "react";
import NavBar from "../components/NavBar";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import {envVariables} from "../constants/Variables";

function BoxArrived() {
    return (
        <div className="pageWrapper bg-[url('/public/images/backgrounds/WaitBgImg.png')] lg:overflow-hidden bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Box has arrived"}
                    text={""} progress={6}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="flex justify-center h-1/3 xs:mb-10">
                        <img className="" src={envVariables.IMG_SRC+"/images/BoxInstructions.png"} alt="Box instructions"/>
                    </div>
                    <h1 className="text-center text-xl xs:text-2xl">
                        Great! Follow all the instructions in your box and you will be redirected back
                    </h1>
                    <p className="text-center text-sm xs:text-xl">
                        If you are still curious, just go farther and get to know more about the company
                    </p>
                    <div className="flex justify-center md:mt-10">
                        <Button component={Link} to={'/character'} variant="outlined" color="warning" className="mr-10" >
                            I am curious</Button>
                        <Button component={Link} to={'/character'} variant="contained" color="warning" >
                            I have finished</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BoxArrived;