import React from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";
import Tooltip from "@mui/material/Tooltip";

function Vr() {
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Office in VR"}
                    text={"Let's check VR premises to know better how our real offices look like."}
                    progress={15} category={"start"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="flex flex-col lg:flex-row lg:justify-between">
                        <div>
                            <div className="flex justify-center relative">
                                <img src={envVariables.IMG_SRC+"/images/VrLakeside.png"} alt="Lakeside VR"
                                     className="vrImage"/>
                                <Tooltip title="The architectural concept of Lakeside Park 01 and the esthetic environment
                                 was taken care of by experts from the Canadian studio Adamson Associates Architects in
                                   cooperation with the Dutch garden architecture studio Copjin Landscape architects.">
                                    <img src={envVariables.IMG_SRC+"/images/info.png"} alt="Lakeside VR"
                                         className="absolute top-2 right-2 cursor-pointer"/>
                                </Tooltip>
                            </div>
                            <h1 className="vHead">Bratislava, Lakeside</h1>
                            <h3 className="vText">
                                <a href="https://my.matterport.com/show/?m=t3ZrbnuRUN5" target="_blank"
                                   rel="noreferrer" className="customLink">Visit</a> LSP office in Bratislava (11th floor)</h3>
                        </div>
                        <div className="mt-5 lg:mt-0">
                            <div className="flex justify-center relative">
                                <img src={envVariables.IMG_SRC+"/images/VrCampus.png"} alt="Campus VR"
                                     className="vrImage"/>
                                <Tooltip title="With a surface area of 25,000 m² and a gross usable floor space of 165,000 m²,
                                 Erste Campus stands out through its open, flowing architecture that fits smoothly into
                                  the historic and natural spatial context of the city. The ground floor areas are open
                                   to the public and the variety of places to eat and drink (restaurants and cafes) ensures
                                    that the Campus is different from the usual hermetic design of bank headquarters.
                                    The headquarters was designed by Henke Schreieck Architects.">
                                    <img src={envVariables.IMG_SRC+"/images/info.png"} alt="Lakeside VR"
                                         className="absolute top-2 right-2 cursor-pointer"/>
                                </Tooltip>
                            </div>
                            <h1 className="vHead">Vienna, Campus</h1>
                            <h3 className="vText">
                                <a href="https://www.360perspektiven.com/projekte/erste-bank/" target="_blank"
                                   rel="noreferrer" className="customLink">Visit</a> Erste Campus office in Vienna</h3>
                        </div>
                    </div>
                </div>
            </div>
            <PageNavigation previous="directionsOffice" next="offices"/>
        </div>
    )
}
export default Vr;