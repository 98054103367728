import NavBar from "../components/NavBar";
import React, {useState} from "react";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import PageNavigation from "../components/PageNavigation";
import CustomBoxDialog from "../components/dialog/CustomBoxDialog";
import CustomConfirmationDialog from "../components/dialog/CustomConfirmationDialog";

function Box() {
    const [selectedBox, setSelectedBox] = useState("lego")

    const handleChange = (e: any) => {
        setSelectedBox(e.target.value)
    }

    return (
        <div className="pageWrapper bg-[url('/public/images/backgrounds/BoxBgImg.png')] bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"CHOOSE YOUR BOX"} text={"Select your preferred type of box and" +
                " press “Order the box” button"} progress={4}/>
            <div className="contentWrapper">
                <div className="contentWrapperInner">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mb-4 lg:mb-10">
                        <div className="lg:place-self-start">
                            <CustomBoxDialog box={1}/>
                        </div>
                        <div className="lg:place-self-end">
                            <CustomBoxDialog box={2}/>
                        </div>
                    </div>
                    <div>
                        <FormControl>
                            <FormLabel id="radio-buttons-group-label" className="formLabel" color={"warning"}>Choose which type of box do
                                you want</FormLabel>
                            <RadioGroup row aria-labelledby="radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        className="block md:flex md:content-between mb-5 lg:mb-0"
                                        onChange={handleChange} defaultValue="lego">
                                <FormControlLabel value="lego" control={<Radio color={"warning"} />}
                                                  label="I want box with lego"
                                                  className="formControlLabel"/>
                                <FormControlLabel value="enter" control={<Radio color={"warning"}/>}
                                                  label="I want box with anti-stress enter button"
                                                  className="formControlLabel"/>
                            </RadioGroup>
                            <CustomConfirmationDialog radio={true}
                                buttonText="Order the box"
                                title="Confirm that you have entered correct surname"
                                text="After you confirm your surname, request will be sent to our HR
                                    team and you should receive your box in next couple of days."
                                item="box"
                                selected={selectedBox}
                                redirect={"boxWait"}/>
                        </FormControl>
                    </div>
                </div>
            </div>
            <PageNavigation skip={true} previous="journey" next="character" />
        </div>
    )
}
export default Box;