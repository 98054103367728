import React from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";

function Ideas() {
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Erste Ideas"} text={"The platform which enables you to submit" +
                " your own idea or ideate with others."} progress={51} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="flex flex-col lg:grid lg:grid-cols-3 lg:gap-2 justify-items-center">
                        <div className="col-span-2 flex flex-col order-2 lg:order-1">
                            <img src={envVariables.IMG_SRC+"/images/Ideas1.png"} alt="Ideas 1"
                                 className="ideasImage mb-5"/>
                            <img src={envVariables.IMG_SRC+"/images/Ideas2.png"} alt="Ideas 2"
                                 className="ideasImage"/>
                        </div>
                        <div className="order-1 lg:order-2">
                            <h1 className="ideasHead">How to use it?</h1>
                            <div className="flex items-center">
                                <img src={envVariables.IMG_SRC+"/images/warning.png"} alt="Ideas 1"
                                     className="w-[30px] h-[30px] lg:w-[40px] lg:h-[40px] mr-5"/>
                                <p className="m-0">Only for Erste employees</p>
                            </div>
                            <p className="ideasText">
                                Just open the <a href="https://ersteideas.ersteapps.com/" target="_blank" rel="noreferrer"
                                                 className="customLink">link</a> through your company laptop and choose your space.
                                You will see different ideas with description and additional files. You can like, comment
                                and follow an idea  if you are interested. To see more details about an idea, click on
                                it, and the will expand. And, crucial part - register your ideas how we can improve
                                internally or bring something for our clients!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <PageNavigation previous="ideasReason" next="infoSessions"/>
        </div>
    )
}
export default Ideas;