import React from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";

function Expectations() {
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"First day at work"}
                    text={"On your first day of work, you can expect the following"} progress={50} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner flex flex-col">
                    <div className="valuesWrapper order-2 lg:order-1">
                        {(() => {
                            let images = []
                            for (let i = 1; i < 7; i++) {
                                images.push(<img src={envVariables.IMG_SRC+"/images/expectations/" + i + ".png"}
                                                 alt="Benefit"
                                                 className="max-w-[100%] lg:w-[75%] 1.5xl:w-[80%] 2xl:w-[100%] 3xl:w-[80%]"
                                                 key={i}/>)
                            }
                            return images
                        })()}
                    </div>
                    <h3 className={"text-center italic order-1 lg:order-2 text-xl 1.5xl:text-2xl 2xl:text-3xl"}>
                        Overall, your first day of work will be a mix of learning, orientation, and starting to contribute
                        to the company. Be open-minded, take notes, and be sure to ask questions.</h3>
                </div>
            </div>

            <PageNavigation skip={false} previous="strategy" next="employeeBenefits"/>

        </div>
    )
}
export default Expectations;