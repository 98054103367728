export const edMarker = [
    {
        id: 0,
        title: "Lakeside - Erste Digital",
        text: "Seat of our office. Go to 9th floor.",
        textImg: "Reception.png",
        position: { lat: 48.17237272501446, lng: 17.14272799278202 },
        pinImg: '/images/mapIcons/edPin.png'
    }
];

export const markersTram = [
    {
        id: 1,
        title: "Kuchajda A/B",
        text: "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/4/Kuchajda/smer-Stanica-Nov%C3%A9-Mesto-Zlat%C3%A9" +
            "-piesky/bd807c807f847f7f837c8375c17f75b683828575be7f\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Tram no. 4 - direction Zlaté piesky/ Stn. Nové Mesto</a> <br>" +
            "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/4/Kuchajda/smer-D%C3%BAbravka/bd807c807f847f7f837" +
            "c8375c18075b683828575be7f\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Tram no. 4 - direction Dúbravka</a>",
        textImg: "KuchajdaTram.png",
        position: { lat: 48.17206800092624, lng: 17.141385130871004 },
        pinImg: '/images/mapIcons/tramPin.png'
    },
    {
        id: 2,
        title: "Stn. Nové Mesto A/B",
        text: "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/4/Stn-Nov%C3%A9-Mesto/smer-D%C3%BAbravka/" +
            "bd807c807f847f7f837c8375c18075b683858075be7f\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Tram no. 4 - direction Dúbravka</a>",
        textImg: "MestoTram.png",
        position: { lat: 48.17148675505089, lng: 17.145353636547306 },
        pinImg: '/images/mapIcons/tramPin.png'
    },

];

export const markersBus = [
    {
        id: 3,
        title: "Stn. Nové Mesto C/D",
        text: "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/50/Stn-Nov%C3%A9-Mesto/smer-Nov%C3%A9-" +
            "Mesto-Kuku%C4%8D%C3%ADnova/bd807c807f847f847f7c847f75c18075b683858075be7f\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Bus no. 50 - direction Kukučínova</a> <br>" +
            "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/50/Stn-Nov%C3%A9-Mesto/smer-Petr%C5%BEalka-" +
            "Aupark/bd807c807f847f847f7c847f75c17f75b683858075be7f\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Bus no. 50 - direction Aupark</a> <br>" +
            "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/196/Stn-Nov%C3%A9-Mesto/smer-Stn-Nov%C3%A9-" +
            "Mesto/bd807c807f848088857c80888575c17f75b683858075be8075c98085\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Bus no. 196 - direction Depo Jurajov Dvor</a> <br>" +
            "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/196/Stn-Nov%C3%A9-Mesto/smer-Petr%C5%BEalka-" +
            "Prokofievova/bd807c807f848088857c80888575c18075b683858075be7f\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Bus no. 196 - direction Prokofievova</a>",
        textImg: "MestoBus.png",
        position: { lat: 48.17107786530368, lng: 17.146392212574014 },
        pinImg: '/images/mapIcons/busPin.png'
    },
    {
        id: 4,
        title: "Kuchajda C/D",
        text: "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/51/Kuchajda/smer-Depo-Kras%C5%88any/" +
            "bd807c807f847f84807c848075c17f75b683828575be7f\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Bus no. 51 - direction Depo Krasňany</a> <br>" +
            "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/51/Kuchajda/smer-Mi%C3%9A-Nov%C3%A9-Mesto" +
            "-Trnavsk%C3%A9-m%C3%BDto/bd807c807f847f84807c848075c18075b683828575be7f\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Bus no. 51 - direction Trnavské mýto</a>",
        textImg: "KuchajdaBus.png",
        position: { lat: 48.17310542873429, lng: 17.14246908749454 },
        pinImg: '/images/mapIcons/busPin.png'
    }
];

export const markersBicycle = [
    {
        id: 5,
        title: "Slovnaft BAjk - Vajnorská/Jarošova",
        text: "<a href=\"https://slovnaftbajk.sk/mapa-stanic\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Station's number: 57</a>",
        textImg: "VajnorskaBike.png",
        position: { lat: 48.16569063401738, lng: 17.13587118309948 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    },
    {
        id: 6,
        title: "Slovnaft BAjk - VIVO! Vajnorská",
        text: "<a href=\"https://slovnaftbajk.sk/mapa-stanic\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Station's number: 107</a>",
        textImg: "VivoBike.png",
        position: { lat: 48.16814224479095, lng: 17.138192709391948 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    },
    {
        id: 7,
        title: "Slovnaft BAjk - VIVO! Junácka",
        text: "<a href=\"https://slovnaftbajk.sk/mapa-stanic\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Station's number: 108</a>",
        textImg: "JunackaBike.png",
        position: { lat: 48.16755920864754, lng: 17.14085309803718 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    },
    {
        id: 8,
        title: "Slovnaft BAjk - Tomášikova/Trnavská",
        text: "<a href=\"https://slovnaftbajk.sk/mapa-stanic\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Station's number: 60</a>",
        textImg: "TrnavskaBike.png",
        position: { lat: 48.16520651908371, lng: 17.14941078428906 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    }
];

export const markersScooter = [
    {
        id: 9,
        title: "Bolt scooters - Vajnorská",
        text: "<a href=\"https://bolt.eu/sk-sk/scooters/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Parking for Bolt electric scooters</a>",
        textImg: "VajnorskaScooter.png",
        position: { lat: 48.17260189445104, lng: 17.14225936547798 },
        pinImg: '/images/mapIcons/scooterPin.png'
    },
    {
        id: 10,
        title: "Bolt scooters - Tomášikova",
        text: "<a href=\"https://bolt.eu/sk-sk/scooters/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Parking for Bolt electric scooters</a>",
        textImg: "TomasikovaScooter.png",
        position: { lat: 48.17201298900876, lng: 17.14265129523116 },
        pinImg: '/images/mapIcons/scooterPin.png'
    }
];

export const markersCar = [
    {
        id: 11,
        title: "Parking - garage",
        text: "You can register you car's licence number to be automatically let in the garage.",
        textImg: "Garage.png",
        position: { lat: 48.17312206435306, lng: 17.143101564975552 },
        pinImg: '/images/mapIcons/carPin.png'
    },
    {
        id: 12,
        title: "Parking - Kuchajda",
        text: "Unofficial parking at Kuchajda, opens at 9:00.",
        textImg: "KuchajdaCar.png",
        position: { lat: 48.171672437905336, lng: 17.142283134889706 },
        pinImg: '/images/mapIcons/carPin.png'
    },
    {
        id: 13,
        title: "Parking - In front of the building",
        text: "Temporary parking for maximum of 20 minutes. Cabs also park here.",
        textImg: "RoundaboutCar.png",
        position: { lat: 48.17234592952962, lng: 17.142449737047667 },
        pinImg: '/images/mapIcons/carPin.png'
    }
];

export const markersTrain = [
    {
        id: 14,
        title: "Železničná stanica Nové Mesto",
        text: "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/S55/Stn-Nov%C3%A9-Mesto-%C5%BDST-Nov%C3%A9-" +
            "Mesto/smer-Trnava/bd877f7ca27f7f7fa2847f7ca2848475c18075b683858075be7f\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Train no. S55 - direction Trnava</a> <br>" +
            "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/S65/Stn-Nov%C3%A9-Mesto-%C5%BDST-Nov%C3%A9-" +
            "Mesto/smer-Bratislava-Petr%C5%BEalka/bd877f7ca27f7f7fa2857f7ca2858475c17f75b683858075be7f\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Train no. S65 - direction Bratislava-Petržalka</a> <br>" +
            "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/S65/Stn-Nov%C3%A9-Mesto-%C5%BDST-Nov%C3%A9-" +
            "Mesto/smer-%C5%A0a%C4%BEa/bd877f7ca27f7f7fa2857f7ca2858475c18075b683858075be7f\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Train no. S65 - direction Šaľa</a> <br>" +
            "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/S70/Stn-Nov%C3%A9-Mesto-%C5%BDST-Nov%C3%A9-" +
            "Mesto/smer-Bratislava-hlavn%C3%A1-stanica/bd877f7c7f7fa38082807ca2867f75c17f75b683858075be7f\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Train no. S70 - direction Bratislava hlavná stanica</a> <br>" +
            "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/S70/Stn-Nov%C3%A9-Mesto-%C5%BDST-Nov%C3%A9-" +
            "Mesto/smer-Kom%C3%A1rno/bd877f7c7f7fa38082807ca2867f75c18075b683858075be7f\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Train no. S70 - direction Komárno</a>",
        textImg: "MestoTrain.png",
        position: { lat: 48.17158187512985, lng: 17.146529255989126 },
        pinImg: '/images/mapIcons/trainPin.png'
    }
];

export const markersCombined = [
    {
        id: 15,
        title: "Bolt scooters - Tomášikova",
        text: "Storage for bicycles and scooters in the garage. Charging options available.",
        textImg: "BikeStorage.png",
        position: { lat: 48.17289442202736, lng: 17.143184495881716 },
        pinImg: '/images/mapIcons/scooterPin.png'
    }
]

export const markersEat = [
    {
        id: 16,
        title: "Chinese restaurant - Pri jazere Kuchajda",
        text: "<a href=\"https://www.cina-kuchajda.sk/#ponuka\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Menu</a>",
        textImg: "ChinaEat.png",
        position: { lat: 48.171329745232, lng: 17.143110758054764 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    },
    {
        id: 17,
        title: "Canteen - Lanogi Lake",
        text: "<a href=\"https://lake.lanogi.sk/denne-menu/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Menu</a>",
        textImg: "LanogiEat.png",
        position: { lat: 48.17261876776863, lng: 17.143133191243493 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    },
    {
        id: 18,
        title: "Italian restaurant - Bella Napoli",
        text: "<a href=\"https://www.bellanapoli.sk/menu-kuchajda/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Menu</a>",
        textImg: "NapoliEat.png",
        position: { lat: 48.171540757734164, lng: 17.142466540020244 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    },
    {
        id: 19,
        title: "Fast food - Vivo",
        text: "<a href=\"https://vivo-shopping.com/sk/bratislava/restauracie\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Various restaurants and fast foods</a>",
        textImg: "VivoEat.png",
        position: { lat: 48.16850021557508, lng: 17.139245531943015 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    },
    {
        id: 20,
        title: "Fresh market",
        text: "<a href=\"https://www.freshmarket.sk/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Specialties from whole world</a>",
        textImg: "MarketEat.png",
        position: { lat: 48.16761355154923, lng: 17.153489231184132 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    }
];

export const edMarkerVienna = [
    {
        id: 0,
        title: "Campus - Erste Digital",
        text: "Headquarters of whole Erste Group",
        textImg: "vienna/reception.png",
        position: { lat: 48.18665511556793, lng: 16.38172352887322 },
        pinImg: '/images/mapIcons/edPin.png'
    }
];

export const markersTramVienna = [
    {
        id: 1,
        title: "Quartier Belvedere Station",
        text: "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/4/Kuchajda/smer-Stanica-Nov%C3%A9-Mesto-Zlat%C3%A9" +
            "-piesky/bd807c807f847f7f837c8375c17f75b683828575be7f\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Tram no. 4 - direction Zlaté piesky/ Stn. Nové Mesto</a> <br>" +
            "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/4/Kuchajda/smer-D%C3%BAbravka/bd807c807f847f7f837" +
            "c8375c18075b683828575be7f\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Tram no. 4 - direction Dúbravka</a>",
        textImg: "KuchajdaTram.png",
        position: { lat: 48.18782862746079, lng: 16.380067123187565 },
        pinImg: '/images/mapIcons/tramPin.png'
    },
    {
        id: 2,
        title: "Hauptbahnhof Ost Station",
        text: "<a href=\"https://imhd.sk/ba/cestovny-poriadok/linka/4/Stn-Nov%C3%A9-Mesto/smer-D%C3%BAbravka/" +
            "bd807c807f847f7f837c8375c18075b683858075be7f\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Tram no. 4 - direction Dúbravka</a>",
        textImg: "MestoTram.png",
        position: { lat: 48.18458647193994, lng: 16.38055265489182 },
        pinImg: '/images/mapIcons/tramPin.png'
    },

];

export const markersBusVienna = [
    {
        id: 3,
        title: "Geetrude-Fröhlich-Sandner-Straße",
        text: "<a href=\"https://www.wienerlinien.at/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Bus no. 69A</a> <br>",
        textImg: "MestoBus.png",
        position: { lat: 48.17107786530368, lng: 17.146392212574014 },
        pinImg: '/images/mapIcons/busPin.png'
    },
    {
        id: 4,
        title: "Karl Popper Straße",
        text: "<a href=\"https://www.wienerlinien.at/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Bus no. 13A</a> <br>",
        textImg: "KuchajdaBus.png",
        position: { lat: 48.18744057141436, lng: 16.37900408570545 },
        pinImg: '/images/mapIcons/busPin.png'
    }
];

export const markersBicycleVienna = [
    {
        id: 5,
        title: "Placeholder bike marker",
        text: "<a href=\"https://slovnaftbajk.sk/mapa-stanic\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Station's number: 57</a>",
        textImg: "VajnorskaBike.png",
        position: { lat: 48.18801481313989, lng: 16.381026465877795 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    },
];

export const markersScooterVienna = [
    {
        id: 9,
        title: "Placeholder scooter marker",
        text: "<a href=\"https://bolt.eu/sk-sk/scooters/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Parking for Bolt electric scooters</a>",
        textImg: "VajnorskaScooter.png",
        position: { lat: 48.186874976474414, lng: 16.37993877750616 },
        pinImg: '/images/mapIcons/scooterPin.png'
    }
];

export const markersCarVienna = [
    {
        id: 11,
        title: "Parkgarage Erste Campus",
        text: "Open 24 hours.",
        textImg: "Garage.png",
        position: { lat: 48.18610692589619, lng: 16.3807728335715 },
        pinImg: '/images/mapIcons/carPin.png'
    }
];

export const markersTrainVienna = [
    {
        id: 14,
        title: "Wien Hbf",
        text: "",
        textImg: "MestoTrain.png",
        position: { lat: 48.18474755216666, lng: 16.37921102789416 },
        pinImg: '/images/mapIcons/trainPin.png'
    }
];


export const markersEatVienna = [
    {
        id: 16,
        title: "IKI Restaurant",
        text: "<a href=\"https://iki-restaurant.at\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Menu</a>",
        textImg: "ChinaEat.png",
        position: { lat: 48.186671351776134, lng: 16.380464979855564 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    },
    {
        id: 17,
        title: "Cafe George",
        text: "<a href=\"https://cafegeorge.at/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Menu</a>",
        textImg: "LanogiEat.png",
        position: { lat: 48.18674755464819, lng: 16.381375279271424 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    }
];

export const edMarkerKosice = [
    {
        id: 0,
        title: "Cassovar",
        text: "New office of Erste Digital in Kosice",
        textImg: "kosice/reception.png",
        position: { lat: 48.71843456352207, lng: 21.246331896805565 },
        pinImg: '/images/mapIcons/edPin.png'
    }
];

export const markersTramKosice = [
    {
        id: 1,
        title: "Jánošíkova",
        text: "<a href=\"https://www.dpmk.sk/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Tram no. 6</a>",
        textImg: "KuchajdaTram.png",
        position: { lat: 48.71280088127524, lng: 21.24605138669176 },
        pinImg: '/images/mapIcons/tramPin.png'
    },
    {
        id: 2,
        title: "Hlavná pošta",
        text: "<a href=\"https://www.dpmk.sk/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Trams no. 2, 4 and 7</a>",
        textImg: "MestoTram.png",
        position: { lat: 48.72177760744666, lng: 21.249151445403403 },
        pinImg: '/images/mapIcons/tramPin.png'
    },
    {
        id: 7,
        title: "Krajský súd",
        text: "<a href=\"https://www.dpmk.sk/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Trams no. 2, 4, 6 and 7</a>",
        textImg: "MestoTram.png",
        position: { lat: 48.71643646758103, lng: 21.249485843656096 },
        pinImg: '/images/mapIcons/tramPin.png'
    }
];

export const markersBusKosice = [
    {
        id: 3,
        title: "Hlavná pošta",
        text: "<a href=\"https://www.dpmk.sk/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Buses no. 12, 17, 36</a> <br>",
        textImg: "MestoBus.png",
        position: { lat: 48.721665864015584, lng: 21.249175711090523 },
        pinImg: '/images/mapIcons/busPin.png'
    },
    {
        id: 4,
        title: "Cassovar",
        text: "<a href=\"https://www.dpmk.sk/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Buses no. 12, 17, 36</a> <br>",
        textImg: "KuchajdaBus.png",
        position: { lat: 48.719688322075854, lng: 21.245430831949704 },
        pinImg: '/images/mapIcons/busPin.png'
    },
    {
        id: 5,
        title: "Krajský súd",
        text: "<a href=\"https://www.dpmk.sk/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Buses no. 10, 11, 20L, 21, 23, 25, 32, 52, 71, 72</a> <br>",
        textImg: "KuchajdaBus.png",
        position: { lat: 48.71628925918015, lng: 21.249642459758977 },
        pinImg: '/images/mapIcons/busPin.png'
    }
];

export const markersBicycleKosice = [
    {
        id: 6,
        title: "Placeholder bike marker",
        text: "<a href=\"https://slovnaftbajk.sk/mapa-stanic\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Placeholder station</a>",
        textImg: "VajnorskaBike.png",
        position: { lat: 48.718441637588555, lng: 21.24726480844095 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    },
];

export const markersScooterKosice = [
    {
        id: 9,
        title: "Placeholder scooter marker",
        text: "<a href=\"https://bolt.eu/sk-sk/scooters/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Parking for Bolt electric scooters</a>",
        textImg: "VajnorskaScooter.png",
        position: { lat: 48.71886178593277, lng: 21.246576241928857 },
        pinImg: '/images/mapIcons/scooterPin.png'
    }
];

export const markersCarKosice = [
    {
        id: 11,
        title: "Parking house Cassovar",
        text: "Open 24 hours.",
        textImg: "Garage.png",
        position: { lat: 48.7187641847961, lng: 21.246030018784968 },
        pinImg: '/images/mapIcons/carPin.png'
    }
];

export const markersTrainKosice = [
    {
        id: 14,
        title: "Košice Predmestie",
        text: "",
        textImg: "MestoTrain.png",
        position: { lat: 48.708717404903815, lng: 21.266022119363345 },
        pinImg: '/images/mapIcons/trainPin.png'
    },
    {
        id: 15,
        title: "Košice hlavná stanica",
        text: "",
        textImg: "MestoTrain.png",
        position: { lat: 48.7227409871214, lng: 21.26779652866653 },
        pinImg: '/images/mapIcons/trainPin.png'
    }
];

export const markersEatKosice = [
    {
        id: 16,
        title: "Lunch Time Cassovar",
        text: "<a href=\"https://lunchtimecassovar.business.site/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Menu</a>",
        textImg: "kosice/LunchTime.png",
        position: { lat: 48.71808879519077, lng: 21.246581239132627 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    },
    {
        id: 17,
        title: "Naše Bistro",
        text: "<a href=\"https://nasebistro.sk/jedla\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Menu</a>",
        textImg: "kosice/NaseBistro.png",
        position: { lat: 48.71864499119646, lng: 21.246860423542316 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    },
    {
        id: 18,
        title: "Reštaurácia U Kuchára",
        text: "<a href=\"http://ukuchara.sk/\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Menu</a>",
        textImg: "kosice/UKuchara.png",
        position: { lat: 48.718570882002794, lng: 21.24815596281899 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    },
    {
        id: 19,
        title: "Dirty Burger",
        text: "<a href=\"https://www.facebook.com/dirtyburgerkosice/?paipv=0&eav=AfZaMZew4mCFYRiRJOsG3HR_qypRnfumTemJK9MqJFjIcnNqRPBAzfr8LQttmzFV3sY&_rdr\" target=\"_blank\" rel=\"noreferrer\" class='customLink'>" +
            "Menu</a>",
        textImg: "kosice/DirtyBurger.png",
        position: { lat: 48.71853056858385, lng: 21.248637689089254 },
        pinImg: '/images/mapIcons/bicyclePin.png'
    }
];