import React from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";

export default function Breakfast() {
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Check out our monthly breakfasts "} text={"An opportunity for team members to gather in a casual setting and foster stronger relationships."}
                    progress={49} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="flex flex-col lg:grid lg:grid-cols-3 lg:gap-5 justify-items-center place-items-center mb-5">
                        <div>
                            <img src={envVariables.IMG_SRC+"/images/breakfast/Breakfast1.png"} alt="Xbox"
                                 className="breakfastImage"/>
                        </div>
                        <div className="col-span-2">
                            <a href="https://www.linkedin.com/posts/erstedigital_erste-digital-company-sports-days-in-slovakia-activity-7076873247899680770-5qRV?utm_source=share&utm_medium=member_desktop" target="_blank"
                               rel="noreferrer" className="customLink"><h1 className="breakfastHead">Company Sport Days</h1></a>
                            <p className="breakfastText">
                                At our regular company breakfast, we welcomed Danka Bartekova, a Slovak Olympic medalist,
                                and by noon, we were already training to run a marathon led by one of our colleagues
                                Simon Wahlandt, who is also a Slovak Champion in the Olympic triathlon.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col lg:grid lg:grid-cols-3 lg:gap-5 justify-items-center place-items-center">
                        <div>
                            <img src={envVariables.IMG_SRC+"/images/breakfast/Breakfast2.png"} alt="Xbox"
                                 className="breakfastImage"/>
                        </div>
                        <div className="col-span-2">
                            <a href="https://www.linkedin.com/posts/erstedigital_womeninit-makeitmatter-believeinyourself-activity-7061641209294856193-J3J8?utm_source=share&utm_medium=member_desktop" target="_blank"
                               rel="noreferrer" className="customLink"><h1 className="breakfastHead">Networking Breakfast</h1></a>
                            <p className="breakfastText">
                                We make a community matters as well. An important part of our IT working environment is
                                to exchange with people, and through several rounds of "Speed Dating," our colleagues had
                                an opportunity to connect, laugh, and start forming a supportive network together.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <PageNavigation previous="employeeBenefitsSecond" next="learning"/>
        </div>
    )
}