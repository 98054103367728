import React, {useContext, useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import NavBar from "../components/NavBar";
import {envVariables} from "../constants/Variables";
import {Button, TextField} from "@mui/material";
import {UserContext} from "../context/UserProvider";
import {CheckSurnameInput, completeUserStep, loginUser} from "../helperFunctions/UserInput";
import LoginFeedbackDialog from "../components/dialog/LoginFeedbackDialog";

function Journey() {
    const routeParams = useParams() as any;
    const userContext = useContext(UserContext)
    const [openLoginFeedback, setOpenLoginFeedback] = useState(false)
    const [verifiedPath, setVerifiedPath] = useState(false)
    const [tmpUser, setTmpUser] = useState("")
    const [openAlert, setOpenAlert] = useState(false)
    const [skip, setSkip] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        checkParams();
    }, []);

    const checkParams = async () => {
        if (routeParams.surname !== undefined) {
            console.log("Found params!")
            console.log(routeParams.surname)

            let axiosResult = await loginUser(routeParams.surname)
            userContext.setUserName(axiosResult.name)
            userContext.setFirstName(axiosResult.firstName)
            userContext.setIsAuth(axiosResult.isAuth)
            userContext.setRole(axiosResult.role)
            userContext.setId(axiosResult.id)
            userContext.setToken(axiosResult.token)
            userContext.setCouponUrl(axiosResult.couponUrl)
            userContext.setLocation(axiosResult.location!)
            userContext.setSteps(axiosResult.steps)
            setOpenLoginFeedback(true)
            if(userContext.steps[0] !== undefined) {
                completeUserStep(userContext.steps[0].id);
            }
        } else {
            console.log("No params found!")
        }
    }

    const handleClick = async () => {
        let axiosResult = await loginUser(tmpUser)
        userContext.setUserName(axiosResult.name)
        userContext.setFirstName(axiosResult.firstName)
        userContext.setIsAuth(axiosResult.isAuth)
        userContext.setRole(axiosResult.role)
        userContext.setId(axiosResult.id)
        userContext.setToken(axiosResult.token)
        userContext.setCouponUrl(axiosResult.couponUrl)
        userContext.setLocation(axiosResult.location!)
        userContext.setSteps(axiosResult.steps)
        setOpenLoginFeedback(true)
    }

    const handlePath = () => {
        if(userContext.isAuth) {
            navigate('/character', {replace: true})
        }
        userContext.setSkip(false);
        localStorage.setItem('skip', false.toString())
        setVerifiedPath(true)
    }

    const handleSkip = () => {
        if(userContext.isAuth) {
            navigate('/equipment', {replace: true})
        }
        setSkip(true);
        userContext.setSkip(true);
        localStorage.setItem('skip', true.toString())
        setVerifiedPath(true);
        if(userContext.steps[6] !== undefined) {
            completeUserStep(userContext.steps[6].id);
        }
    }

    const getNameInput = (e: any) => {
        setTmpUser(e.target.value)
    }

    const onChangeHandler = (event : any) => {
        getNameInput(event)
        if(CheckSurnameInput(event)) {
            setOpenAlert(false)
        } else {
            event.preventDefault()
            setOpenAlert(true)
        }
    }

    return (
        <div className="pageWrapper bg-secondary">
            {openLoginFeedback ? <LoginFeedbackDialog open={true} validated={userContext.isAuth} redirect={true} skip={skip}/> : null}
            <NavBar image={"/navbar/toDo.svg"} header={"Journey"}
                    text={"Here you will see progress of your journey :) Make sure that it will be 100%!"}
                    progress={0} category={"welcome"}/>
            <div className="contentWrapper">
                <div className="contentWrapperInner">
                    <h1 className="text-center text-xl xs:text-3xl md:text-4xl lg:text-xl 1.5lg:text-xl 2xl:text-4xl">
                        Welcome to Onboarding Portal!</h1>
                    <div className={"flex justify-center"}>
                        <img className="hidden lg:block w-[97%] lg:w-[60%] 1.5lg:w-[50%] xl:w-[60%] 1.5xl:w-[55%] 2xl:w-[70%] 3xl:w-[55%]"
                             src={envVariables.IMG_SRC+"/images/journey/JourneyPc.png"}
                             alt="Journey road"/>
                        <img className="block lg:hidden xs:w-[90%] md:w-[65%] max-w-[100%]"
                             src={envVariables.IMG_SRC+"/images/journey/JourneyMobile.png"}
                             alt="Journey road"/>
                    </div>
                </div>
            </div>
            <div className="lg:absolute lg:bottom-[0] w-full flex justify-center mt-5 mb-20 lg:mb-5">
                <div className="w-11/12 2xl:w-9/12 flex md:justify-end">
                    {verifiedPath ? (
                        <div>
                            <div className="flex justify-center items-center">
                                <TextField id="outlined-basic"
                                           label="Enter your surname"
                                           variant="outlined"
                                           error={openAlert}
                                           helperText={openAlert ? "This is input can contain only letters!" : ""}
                                           color="primary"
                                           className="pr-10"
                                           onChange={(event) => onChangeHandler(event)}
                                           size="small"/>
                                <Button variant="contained" color="warning"
                                        onClick={handleClick} disabled={openAlert} className="">Enter</Button>
                            </div>
                            <div>
                                <p className="text-center text-warning text-xs xs:text-base md:text-xl lg:text-xs
                                     1.5lg:text-xs xl:text-sm 1.5xl:test-base 2xl:text-base">
                                    *make sure you provided your real <b>surname</b> to embrace the full journey experience.
                                    Your <b>surname</b> can be shared with our HR, but you can run through this page even
                                    without sharing.
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className={"grid grid-cols-2 gap-2 md:flex md:justify-ends"}>
                            <Button className={"md:mr-5"} component={Link} to={"/character"} variant="outlined" color="warning">
                                I am just curious
                            </Button>
                            <Button className={"md:mr-5"} variant="outlined" color="warning" onClick={handleSkip}>
                                Skip onboarding
                            </Button>
                            <Button className="col-span-2" variant="contained" color="warning"
                                    onClick={handlePath}>
                                I accepted the offer and am starting in Erste
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
export default Journey;