export const infoSessions = [
    {
        date: "June, 2022",
        title: "Vesna VoiceBot Hologram",
        text: "This InfoSession was dedicated to presenting a unique cutting-edge technology showcased by Vesna - a Voicebot Hologram. The original idea was created during a group project where Erste Digital Innovations were directly involved. Later on, SLSP took over the project and developed a unique solution combining custom hologram with voicebot functionality and many other capabilities. By the way, did you know we created digital human from scratch for this case?",
        image: "Session1.png"
    },
    {
        date: "October, 2022",
        title: "Modern Software Visualization",
        text: "This session was dedicated to simplify complex models of our solutions to manageable pieces visualised even in VR to understand better what solutions talks to others, where are better integrations needed and what are logical silos. This helps us to use more microservices and split complex problems to manageable parts. Our colleagues demonstrated how we can utilize systems we already have to do the same job for any other project in the future.",
        image: "Session2.png"
    },
    {
        date: "March, 2022",
        title: "Quantum Computing",
        text: "We are doing also a lot of research and we take it seriously. This info session was to inform our colleagues that Quantum computing research project is starting and what the Quantum computing actually is, how far we are from production and how it can help us to solve future problems. Session was also used as an onboarding for colleagues interested in the topic...",
        image: "Session4.png"
    },
    {
        date: "August, 2022",
        title: "Deep Learning",
        text: "This InfoSession was dedicated to presenting a unique cutting-edge technology showcased by Vesna - a Voicebot Hologram. The original idea was created during a group project where Erste Digital Innovations were directly involved. Later on, SLSP took over the project and developed a unique solution combining custom hologram with voicebot functionality and many other capabilities.",
        image: "Session3.png"
    },

]