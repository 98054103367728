import React, {useContext, useEffect} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";
import {UserContext} from "../context/UserProvider";
import {completeUserStep} from "../helperFunctions/UserInput";

function BenefitsFirst() {
    const userContext = useContext(UserContext)

    useEffect(() => {
        if(userContext.steps[3] !== undefined) {
            completeUserStep(userContext.steps[3].id)
        }
    });
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Employee benefits"}
                    text={"Benefits provided when you become an employee"} progress={45} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className={"hidden lg:flex justify-center"}>
                        <img src={envVariables.IMG_SRC+"/images/benefits/Benefits.png"} alt="Employee benefits" className="max-h-[70vh] max-w-full"/>
                    </div>
                    <div className={"grid grid-cols-2 gap-4 lg:hidden"}>
                        <div className={"flex justify-center col-span-2"}>
                            <img src={envVariables.IMG_SRC+"/images/benefits/first/BenefitsHeader.png"} alt="Employee benefits" className="max-h-[70vh] max-w-full"/>
                        </div>
                        {(() => {
                            let images = []
                            for (let i = 1; i < 15; i++) {
                                images.push(<img src={envVariables.IMG_SRC+"/images/benefits/first/" + i + ".png"}
                                                 alt="Benefit"
                                                 className="max-w-[100%] lg:w-[75%] 1.5xl:w-[80%] 2xl:w-[100%] 3xl:w-[80%]" key={i}/>)
                            }
                            return images
                        })()}

                    </div>


                </div>
            </div>
            <PageNavigation skip={false} previous="puzzle" next="employeeBenefitsSecond"/>
        </div>
    )
}
export default BenefitsFirst;