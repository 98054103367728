import React, {useContext, useEffect} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import CustomOutfitDialog from "../components/dialog/CustomOutfitDialog";
import CustomGamesDialog from "../components/dialog/CustomGamesDialog";
import {UserContext} from "../context/UserProvider";
import {completeUserStep} from "../helperFunctions/UserInput";

function Outfit() {
    // const [windowWidth] = useState(window.innerWidth);
    const userContext = useContext(UserContext)

    useEffect(() => {
        if(userContext.steps[1] !== undefined) {
            completeUserStep(userContext.steps[1].id)
        }
    });
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Getting ready to office"}
                    text={"Select which outfit is, in your opinion, the most appropriate to wear in the office"}
                    progress={6} category={"start"}/>
            <CustomGamesDialog/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="grid grid-cols-2 justify-items-center gap-y-4 gap-x-4
                    lg:grid-cols-4">
                        <CustomOutfitDialog outfit={1} correct={true}/>
                        <CustomOutfitDialog outfit={2} correct={true}/>
                        <CustomOutfitDialog outfit={3} correct={false}/>
                        <CustomOutfitDialog outfit={4} correct={false}/>
{/*                        {windowWidth < 1024 ? (
                            <CustomOutfitDialog outfit={4} correct={false}/>
                        ) : (
                            <></>
                        )}*/}
                    </div>
                    <div>
                        <p className="smallText mt-4 lg:mt-4">*by the way, we used DALL-E as inspiration for image
                            creation</p>
                    </div>
                </div>
            </div>
            <PageNavigation skip={true} previous="character" next="directions" />
        </div>
    )
}
export default Outfit;