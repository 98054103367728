import {Link} from "react-router-dom";
import {Button} from "@mui/material";
import React from "react";

function PageNavigation(props: any) {
    return (
        <div className="lg:absolute lg:bottom-[0] w-full flex justify-center mt-5 mb-20 lg:mb-5">
            <div className="w-11/12 2xl:w-9/12 flex justify-between">
                <Button component={Link} to={'/' + props.previous} variant="outlined" color="warning">Back</Button>
                {props.skip ? (
                    <>
                        {props.choice ? (
                            <Button component={Link} to={'/' + props.next} variant="outlined" color="warning">Skip</Button>
                        ) : (
                            <Button component={Link} to={'/' + props.next} variant="contained" color="warning">Skip</Button>
                        )}
                    </>
                ) : (
                    <Button component={Link} to={'/' + props.next} variant="contained" color="warning">Next</Button>
                )}
            </div>
        </div>
    )
}

export default PageNavigation;