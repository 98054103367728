import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Journey from "./pages/JourneyPage";
import Crossroads from "./pages/CrossroadsPage";
import Box from "./pages/ChooseBoxPage";
import Map from "./pages/MapPage";
import BoxWait from "./pages/BoxWaitPage";
import Outfit from "./pages/OutfitPage";
import StreetView from "./pages/StreetViewPage";
import Workplace from "./pages/WorkplacePage";
import Puzzle from "./pages/PuzzlePage";
import BackgroundSelect from "./pages/BackgroundPage";
import Eat from "./pages/EatingPage";
import Tickets from "./pages/TicketChoicePage";
import Coloring from "./pages/ColoringPage";
import Metaverse from "./pages/MetaversePage";
import Print from "./pages/3dPrintPage";
import Feedback from "./pages/FeedbackPage";
import Entertainment from "./pages/EntertainmentPage";
import Football from "./pages/FootballPage";
import Xbox from "./pages/XboxPage";
import Structure from "./pages/StructurePage";
import InfoSessions from "./pages/InfoSessionsPage";
import EquipmentWait from "./pages/EquipmentWaitPage";
import TicketsWait from "./pages/TicketsWaitPage";
import BoxArrived from "./pages/BoxArrivedPage";
import Vr from "./pages/VrPage";
import Ideas from "./pages/ErsteIdeasPage";
import IdeasReason from "./pages/ErsteIdeasReasonPage";
import BenefitsFirst from "./pages/BenefitsFirstPage";
import BenefitsSecond from "./pages/BenefitsSecondPage";
import CharacterSelect from "./pages/CharacterSelectPage";
import CompanyValues from "./pages/CompanyValuesPage";
import CompanyStrategy from "./pages/CompanyStrategyPage";
import Expectations from "./pages/ExpectationsPage";
import FirstDay from "./pages/FirstDayAtWorkPage";
import UsefulLinks from "./pages/UsefulLinksPage";
import Quiz from "./pages/QuizPage";
import Team from "./pages/TeamPage";
import Learning from "./pages/LearningPage";
import Offices from "./pages/OfficesPage";
import BeerError from "./pages/BeerCouponErrorPage";
import Parking from "./pages/ParkingCardPage";
import Documents from "./pages/DocumentsPage";
import Breakfast from "./pages/BreakfastPage";
import Hardware from "./pages/HardwarePage";
import CertificateDownload from "./pages/CertificateDownloadPage";

function App() {

    return (
        <Routes>
            <Route path='/crossroads' element={<Crossroads/>} />
            <Route path='' element={<Journey/>} />
            <Route path='/:surname' element={<Journey/>} />
            <Route path='/journey' element={<Journey/>} />
            <Route path='/journey/:surname' element={<Journey/>} />
            <Route path='/box' element={<Box/>} />
            <Route path='/boxWait' element={<BoxWait/>} />
            <Route path='/boxArrived' element={<BoxArrived/>} />
            <Route path='/character' element={<CharacterSelect/>} />
            <Route path='/outfit' element={<Outfit/>} />
            <Route path='/directions' element={<Map/>} />
            <Route path='/directionsOffice' element={<StreetView/>} />
            <Route path='/vr' element={<Vr/>} />
            <Route path='/offices' element={<Offices/>} />
            <Route path='/workplace' element={<Workplace/>} />
            <Route path='/equipment' element={<Hardware/>} />
            <Route path="/equipment/:surname" element={<Hardware />} />
            <Route path='/equipmentWait' element={<EquipmentWait/>} />
            <Route path='/firstDay' element={<FirstDay/>} />
            <Route path='/foodBenefit' element={<Tickets/>} />
            <Route path='/foodBenefit/:surname' element={<Tickets/>} />
            <Route path='/foodBenefitWait' element={<TicketsWait/>} />
            <Route path='/eat' element={<Eat/>} />
            <Route path='/team' element={<Team/>} />
            <Route path='/puzzle' element={<Puzzle/>} />
            <Route path='/background' element={<BackgroundSelect/>} />
            <Route path='/employeeBenefits' element={<BenefitsFirst/>} />
            <Route path='/employeeBenefitsSecond' element={<BenefitsSecond/>} />
            <Route path='/learning' element={<Learning/>} />
            <Route path='/ideasReason' element={<IdeasReason/>} />
            <Route path='/ideas' element={<Ideas/>} />
            <Route path='/infoSessions' element={<InfoSessions/>} />
            <Route path='/usefulLinks' element={<UsefulLinks/>} />
            <Route path='/metaverse' element={<Metaverse/>} />
            <Route path='/entertainment' element={<Entertainment/>} />
            <Route path='/print' element={<Print/>} />
            <Route path='/football' element={<Football/>} />
            <Route path='/xbox' element={<Xbox/>} />
            <Route path='/structure' element={<Structure/>} />
            <Route path='/values' element={<CompanyValues/>} />
            <Route path='/coloring' element={<Coloring/>} />
            <Route path='/quiz' element={<Quiz/>} />
            <Route path='/feedback' element={<Feedback/>} />
            <Route path='/strategy' element={<CompanyStrategy/>} />
            <Route path='/expectations' element={<Expectations/>} />
            <Route path='/beerError' element={<BeerError/>} />
            <Route path='/parking' element={<Parking/>} />
            <Route path='/documents' element={<Documents/>} />
            <Route path='/breakfast' element={<Breakfast/>} />
            <Route path='/certificateDownload' element={<CertificateDownload/>} />
        </Routes>
    );
}
export default App;
