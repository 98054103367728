import React, {useContext} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {Button} from "@mui/material";
import CustomStoryDialog from "../components/dialog/CustomStoryDialog";
import {Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View} from "@react-pdf/renderer";
import {envVariables} from "../constants/Variables";
import {UserContext} from "../context/UserProvider";
import {Link} from "react-router-dom";
const TreeCertificate = (props : any) => (
    <Document>
        <Page size="A4" style={styles.page}>

            <View style={styles.section}>
                <Image src={envVariables.IMG_SRC+'/images/Certificate.png'} style={styles.pageBackground} />
                <View style={styles.section2}>

                    <Text style={styles.text1}>TREE CERTIFICATE</Text>
                    <Text style={styles.text2}>Congratulations {props.firstName + " " + props.name}, we planted a tree in your name!</Text>
                </View>
            </View>
        </Page>
    </Document>
);

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
    },
    section: {
        flexGrow: 1
    },
    section2: {
        marginTop: 200,
        marginHorizontal: 70,
        padding: 10
    },
    text1: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 10
    },
    text2: {
        textAlign: 'center',
    },
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        //display: 'block',
        height: '100%',
        width: '100%',
    },
});

export default function CertificateDownload() {
    const userContext = useContext(UserContext)
    const closeWindow = () => {
        window.close();
    }

    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Certificate"}
                    text={"Download your certificate here"} progress={95} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner flex justify-center">
                    <div className={"flex flex-col justify-center w-1/2"}>
                        <img src={envVariables.IMG_SRC+"/images/rewards/TreeCertificate.png"} alt="Tree certificate" className="max-w-xl"/>
                        <h2 className={""}>
                            Congratulations! You successfully completed the quiz.
                            We are going to plant a tree in your name!
                        </h2>
                        <PDFDownloadLink document={<TreeCertificate name={userContext.userName} firstName={userContext.firstName} />} fileName="TreeCertificate.pdf"
                                         className={"mb-5 ml-2 text-black"}>
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading document...' : 'Download your certificate here!'
                            }
                        </PDFDownloadLink>
                    </div>
                </div>
            </div>
            <div className="lg:absolute lg:bottom-[0] w-full flex justify-center mt-5 mb-20 lg:mb-5">
                <div className="w-11/12 2xl:w-9/12 flex justify-end">
                    <Button variant="contained" color="warning" onClick={closeWindow}>Return to main flow</Button>
                </div>
            </div>
        </div>
    )
}
