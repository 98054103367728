import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../context/UserProvider";
import DialogContentText from "@mui/material/DialogContentText";
import ChangeNameDialog from "./ChangeNameDialog";
import {CircularProgress} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {DialogTransition} from "../../helperFunctions/Transitions";
import BootstrapDialogTitle from "./BootstrapDialogTitle";
import {completeUserStep, setHardware, setMealAllowance} from "../../helperFunctions/UserInput";

const Transition = React.forwardRef(DialogTransition);

export default function CustomConfirmationDialog(props : any) {
    const [open, setOpen] = React.useState(false);
    const userContext = useContext(UserContext)
    const [sendingMail, setSendingMail] = useState(false)
    const [mailError, setMailError] = useState(false)
    const [successfulChoice, setSuccessfulChoice] = useState(false);
    let navigate = useNavigate()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmOrder = () => {
        setSendingMail(true)
        if(userContext.isAuth) {
            if(props.item === "hardware") {
                setHardware(props.selected, props.token)
                    .then(function(response) {
                        setMailError(false)
                        if(userContext.steps[4] !== undefined) {
                            completeUserStep(userContext.steps[4].id)
                        }
                        //console.log('SUCCESS!', response.status, response.text);
                        navigate('/'+props.redirect)
                    }, function(error) {
                        console.log('FAILED...', error);
                        setMailError(true)
                        setSendingMail(false)
                    });
            } else if(props.item === "food") {
                setMealAllowance(props.selected)
                    .then(function(response) {
                        setMailError(false);
                        if(userContext.steps[5] !== undefined) {
                            completeUserStep(userContext.steps[5].id);
                        }
                        console.log('SUCCESS!', response.status, response.text);
                        setSuccessfulChoice(true);
                        navigate('/'+props.redirect)
                    }, function(error) {
                        console.log('FAILED...', error);
                        setMailError(true);
                        setSendingMail(false);
                        setSuccessfulChoice(false);
                    });
            }
        } else {
            setMailError(true);
        }
    }

    const removeError = () => {
        setMailError(false);
        setSendingMail(false);
        setOpen(false);
        setSuccessfulChoice(false);
    }

    return (
        <div>
            <Button variant="contained" color={"warning"} onClick={handleClickOpen}>{props.buttonText}</Button>
            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {props.title}
                </BootstrapDialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" className="w-fit">
                        {sendingMail ? (
                            <div>
                                {mailError ? (
                                        <span className="flex justify-center text-center">Something went wrong,
                                            try again later please. We apologize for any inconvenience. You can manually send
                                            email to this address: Erstedigitalinnovations@erstegroup.com</span>
                                    ) : (
                                        <>
                                        {successfulChoice ? (
                                            <div>
                                                    <span className="flex justify-center text-center">Your choice has been
                                                        successfully submitted!
                                                        Click on the button below to continue your journey.
                                                    </span>
                                            </div>
                                        ) : (
                                            <div>
                                                <span className="flex justify-center text-center">Please wait, your mail is
                                                    being sent. Don't leave this page, you will be redirected when it is finished.
                                                </span>
                                                <div className="flex justify-center mt-5">
                                                    <CircularProgress color="primary"/>
                                                </div>
                                            </div>
                                        )}
                                        </>
                                    )}
                            </div>
                        ) : (
                            <span className="flex justify-center text-center">{props.text}</span>
                        )}
                        <br/>
                        <span className="font-bold flex justify-center">Provided name: {userContext.userName}</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="flex justify-between">
                    {sendingMail ? (
                        <>
                            {mailError ? (
                                <ChangeNameDialog customClickEvent={removeError}/>
                            ) : (
                                <>
                                    {successfulChoice ? (
                                        <Button component={Link} to={'/eat'} variant="contained" color="warning">Continue</Button>
                                    ) : (
                                        <></>
                                    )}
                                </>

                            )}
                        </>
                    ) : (
                        <>
                            <ChangeNameDialog/>
                            <Button onClick={handleConfirmOrder} variant="contained" color="warning" className={"startButton"}>Confirm order</Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}
