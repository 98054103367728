import axios, {AxiosResponse} from "axios";
import api from "../services/api";

export function CheckSurnameInput(event : any) {
    const ALPHA_REGEX = /^[A-Za-zÀ-ÖØ-öø-ÿ\u0100-\u017F]+$/;
    return ALPHA_REGEX.test(event.target.value);
}

export async function loginUser(userName: string) {
    let responseName = "Guest"
    let responseFirstName = "Guest"
    let responseAuth = false
    let responseRole = "guest"
    let responseToken = "0"
    let responseId = "0"
    let responseCouponUrl = ""
    let responseLocation = "bratislava"
    let responseSteps

    if (userName !== "") {
        responseName = userName
    } else {
        localStorage.setItem('userName', responseName);
        localStorage.setItem('firstName', responseFirstName);
        localStorage.setItem('isAuth', responseAuth.toString())
        localStorage.setItem('userRole', responseRole)
        localStorage.setItem('token', responseToken)
        localStorage.setItem('id', responseId)
        localStorage.setItem('couponUrl', responseCouponUrl)
        localStorage.setItem('location', responseLocation)
        localStorage.setItem('steps', JSON.stringify(responseSteps))

        return {name: responseName, firstName: responseFirstName, isAuth: responseAuth, role: responseRole, token: responseToken, id: responseId, couponUrl: responseCouponUrl, steps: responseSteps};
    }
    let userResponse: AxiosResponse<any>;
    try {
        userResponse = await api.axiosClient.post(`user/auth/signin`, {
            username: userName,
        })
        console.log(userResponse)
        responseFirstName = userResponse.data.data.user_info.first_name
        responseRole = userResponse.data.data.user_info.position
        responseToken = userResponse.data.accessToken
        responseId = userResponse.data.data.user_info.external_id
        responseCouponUrl = userResponse.data.data.user_info.coupon_url
        responseLocation = userResponse.data.data.user_info.location
        responseSteps = userResponse.data.data.user_steps
        localStorage.setItem('userName', userName);
        localStorage.setItem('firstName', responseFirstName);
        localStorage.setItem('isAuth', true.toString())
        localStorage.setItem('userRole', responseRole)
        localStorage.setItem('token', responseToken)
        localStorage.setItem('id', responseId)
        localStorage.setItem('couponUrl', responseCouponUrl)
        localStorage.setItem('location', responseLocation === undefined ? "bratislava" : responseLocation)
        localStorage.setItem('steps', JSON.stringify(responseSteps))

        return {name: userName, firstName: responseFirstName, isAuth: true, role: responseRole, token: responseToken, id: responseId, couponUrl: responseCouponUrl, location: responseLocation === undefined ? "bratislava" : responseLocation, steps: responseSteps};
    } catch (e) {
        return {name: userName, firstName: responseFirstName, isAuth: false, role: responseRole, token: responseToken, id: responseId, couponUrl: responseCouponUrl, location: responseLocation, steps: responseSteps};
    }
}

export async function getTeamInfo(userId : string) {
    let userResponse: AxiosResponse<any>;
    userResponse = await api.axiosClient.get(`/team/${userId}`)
    let members = userResponse.data.members
    let team = userResponse.data.team
    return {members: members, team: team}
}

export async function getTeamFunFact(userId : string) {
    let userResponse: AxiosResponse<any>;
    try {
        userResponse = await api.axiosClient.get(`team/fun-fact/${userId}`)
        return userResponse.data;
    } catch (e) {
        return undefined
    }
}

export async function getUserSteps(userId : string) {
    let userResponse: AxiosResponse<any>;
    try {
        await api.axiosClient.get(`user-steps/${userId}`).then(response => {
            return response.data
        }).catch(function(error) {
            console.log("Error")
            return undefined
        })
    } catch (e) {
        console.log("Error")
        return undefined
    }
}

export async function completeUserStep(stepId : string) {
    try {
        await api.axiosClient.post(`user-steps/complete-step`, {
            external_step_id: stepId
        }).then(() => {
            console.log("Step " + stepId + " was completed!")
        }).catch(function(error) {
            console.log("Error: step " + stepId + " could not be completed! " + error)
        })
    } catch (e) {
        console.log("Error: step " + stepId + " could not be completed! " + e)
    }
}

export async function getUserInfo() {
    let userResponse: AxiosResponse<any>;
    try {
        userResponse = await api.axiosClient.get(`user/`)
        return userResponse.data;
    } catch (e) {
        return undefined
    }
}

export async function setUserInfo(image : File, hobby : string, personalInfo : string, authToken : string) {
    let bodyFormData = new FormData()
    bodyFormData.append('image', image)
    bodyFormData.append('hobby', hobby)
    bodyFormData.append('personalInfo', personalInfo)
    axios({
        method: "post",
        url: "https://newcomerjourney.com:8443/api/user-steps/set-personal-info",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data",
                    "Authorization": authToken},
    })
        .then(function (response) {
            //handle success
            console.log(response);
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
}

export async function getAvailableHardware() {
    let userResponse: AxiosResponse<any>;
    try {
        userResponse = await api.axiosClient.get(`user/hardware/available`)
        return userResponse.data;
    } catch (e) {
        return undefined
    }
}

export async function getUserHardware() {
    let userResponse: AxiosResponse<any>;
    try {
        userResponse = await api.axiosClient.get(`user/hardware`)
        return userResponse.data;
    } catch (e) {
        return undefined
    }
}

export async function getAllHardware() {
    let userResponse: AxiosResponse<any>;
    try {
        userResponse = await api.axiosClient.get(`hardware`)
        return userResponse.data;
    } catch (e) {
        return undefined
    }
}

export async function getAllHardwareTypes() {
    let userResponse: AxiosResponse<any>;
    try {
        userResponse = await api.axiosClient.get(`hardware/types`)
        return userResponse.data;
    } catch (e) {
        return undefined
    }
}

export async function getHardwareById(id : string) {
    let userResponse: AxiosResponse<any>;
    try {
        userResponse = await api.axiosClient.get(`hardware/${id}`)
        return userResponse.data;
    } catch (e) {
        return undefined
    }
}

export async function getUserByUsername(username : string) {
    let userResponse: AxiosResponse<any>;
    try {
        userResponse = await api.axiosClient.get(`user/${username}`)
        return userResponse.data;
    } catch (e) {
        return undefined
    }
}

export async function getUserFiles(name : string) {
    let userResponse: AxiosResponse<any>;
    try {
        userResponse = await api.axiosClient.get(`user/files/${name}`)
        return userResponse.data;
    } catch (e) {
        return undefined
    }
}

export async function setFirstAccess() {
    let userResponse: AxiosResponse<any>;
    try {
        userResponse = await api.axiosClient.post(`user/set-first-access`)
        return userResponse.data;
    } catch (e) {
        return undefined
    }
}

export async function getMealAllowances() {
    let userResponse: AxiosResponse<any>;
    try {
        userResponse = await api.axiosClient.get(`meal-allowance`)
        return userResponse.data;
    } catch (e) {
        return undefined
    }
}

export async function getMealAllowanceById(id : string) {
    let userResponse: AxiosResponse<any>;
    try {
        userResponse = await api.axiosClient.get(`meal-allowance/${id}`)
        return userResponse.data;
    } catch (e) {
        return undefined
    }
}

export async function setMealAllowance(id : string) {
    let userResponse: AxiosResponse<any>;
    try {
        userResponse = await api.axiosClient.post(`user/set-meal-allowance`,{
            meal_allowance_id: id
        })
        return userResponse.data;
    } catch (e) {
        return undefined
    }
}

// TODO test if form data is sent properly
export async function setHardware(selectedHardware : any, authToken : string) {
/*    let userResponse: AxiosResponse<any>;
    try {
        userResponse = await api.axiosClient.post(`user/set-hardware`,{
            hardware: selectedHardware
        })
        return userResponse.data;
    } catch (e) {
        return undefined
    }*/
    let bodyFormData = new FormData()
    bodyFormData.append('hardware', selectedHardware)
    axios({
        method: "post",
        url: "https://newcomerjourney.com:8443/api/user/set-hardware",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data",
            "Authorization": authToken},
    })
        .then(function (response) {
            //handle success
            console.log(response);
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
}

export async function getUserCertificate(user_id : string) {
    let userResponse: AxiosResponse<any>;
    try {
        userResponse = await api.axiosClient.get(`user-steps/certificate/${user_id}`)
        return userResponse.data;
    } catch (e) {
        return undefined
    }
}



