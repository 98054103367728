import React, {useContext, useEffect, useState} from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";
import CustomConfirmationDialog from "../components/dialog/CustomConfirmationDialog";
import {Button} from "@mui/material";
import CustomStoryDialog from "../components/dialog/CustomStoryDialog";
import {UserContext} from "../context/UserProvider";
import {getEquipmentImage} from "../data/equipmentData";
import CustomTooltip from "../components/CustomTooltip";
import {getAvailableHardware, getUserHardware, loginUser} from "../helperFunctions/UserInput";
import {useParams} from "react-router-dom";
import LoginFeedbackDialog from "../components/dialog/LoginFeedbackDialog";

export default function Hardware() {
    const routeParams = useParams() as any;
    const userContext = useContext(UserContext)
    const [selectedCategory, setSelectedCategory] = useState('laptops')
    const [selectedLaptop, setSelectedLaptop] = useState<any>(undefined)
    const [selectedLaptopObject, setSelectedLaptopObject] = useState<any>(undefined)

    const [selectedLaptopAccessory, setSelectedLaptopAccessory] =
        useState<{id : number, name : string, description : string, fk_hardware_type_id : number}[]>([])
    const [hardware, setHardware] = useState<any[]>([])
    const [openLoginFeedback, setOpenLoginFeedback] = useState(false)

    const laptops = ["Dell Latitude 5540 STD i5 (sNice)", "Dell Latitude 5340 SUB i5 (sNice)", "Dell Precision 7680 ADV PRO i7 (sNice)",
        "Dell Precision 7680 ADV PRO i7 (Flex)", "MacBook Dyn (Flex)", "MacBook Pro (Flex)", "MacBook Pro+ (Flex)"];
    const appleAccessories = ["Magic mouse", "Magic keyboard (US layout)", "Headphones (Apple)", "Dell Essential Briefcase"];
    const phones = ["iPhone XR", "iPhone 13", "iPhone SE"]
    const accessory = ["Wireless Mouse", "Apple Mouse", "Wireless Keyboard", "Apple Keyboard", "Microsoft Headset", "Earpods", "Earphones"]
    const phoneAccessory = ["eStuff", "eSIM"]

    const fixedAccessories = ["iPhone 13", "Sketch crystal cover for iPhone", "Panzer glass 5D screen protector ", "20W charge brick", "Charging cable", "Sim card (physical)"]
    useEffect(() => {
        setSelectedLaptop(undefined)
        //getSelectedHardware()
        getHardwareData()
        checkParams()
    }, []);

    const checkParams = async () => {
        if (routeParams.surname !== undefined) {
            console.log("Found params!")
            console.log(routeParams.surname)

            let axiosResult = await loginUser(routeParams.surname)
            userContext.setUserName(axiosResult.name)
            userContext.setFirstName(axiosResult.firstName)
            userContext.setIsAuth(axiosResult.isAuth)
            userContext.setRole(axiosResult.role)
            userContext.setId(axiosResult.id)
            userContext.setToken(axiosResult.token)
            userContext.setCouponUrl(axiosResult.couponUrl)
            userContext.setLocation(axiosResult.location!)
            userContext.setSteps(axiosResult.steps)
            setOpenLoginFeedback(true)
        } else {
            console.log("No params found!")
        }
    }

    const getSelectedHardware = async () => {
        let selectedHardware = await getUserHardware()
        /*if(selectedHardware !== undefined) {
            selectedHardware.forEach((item : any) => {
                if(getHardwareCategory(item.name, "laptops")) {
                    setSelectedLaptop(item)
                    document.getElementById(item.id)!.classList.add("selectedEquipment")
                } else {
                    setSelectedLaptopAccessory([...selectedLaptopAccessory, item])
                    document.getElementById(item.id)!.classList.add("selectedEquipment")
                }
/!*                if(!isSelected(item)) {
                    if(isFixedAccessory(item)) {
                        setSelectedLaptopAccessory([...selectedLaptopAccessory, item])
                    }
                }*!/
            })
        }*/
    }

    const isSelected = (value : any) => {
        if(selectedLaptop !== undefined) {
            if(selectedLaptop.contains(value) || selectedLaptopAccessory.includes(value)) {
                return true
            }
        }
    }

    const isFixedAccessory = (value : any) => {
        return fixedAccessories.includes(value.name)
    }

    const getHardwareData = async () => {
        let availableHardware = await getAvailableHardware()
        setHardware(availableHardware)
        console.log(availableHardware)
        // for each in availableHardware
        // if isSelected(item) -> add class
        /*for (let i = 0; i < availableHardware.length; i++) {
            if(isFixedAccessory(availableHardware[i])) {
                if(availableHardware[i] !== undefined) {
                    if(selectedCategory !== "laptops") {
                        document.getElementById(availableHardware[i].id.toString())!.classList.add("selectedEquipment");
                    }
                    selectLaptopAccessory(availableHardware[i], availableHardware[i]);
                }
            }
        }*/
    }

    const getHardwareCategory = (name : string, category : string) => {
        if(category === "laptops") {
            return laptops.includes(name)
        } else if(category === "phones") {
            return phones.includes(name)
        } else if(category === "laptopAccessories") {
            return accessory.includes(name)
        } else if(category === "phoneAccessories") {
            return phoneAccessory.includes(name)
        } else {
            return false
        }
    }

    const toggleSelected = (target : any) => {
        target.classList.toggle("rounded-3xl");
        target.classList.toggle("border-2");
        target.classList.toggle("border-solid");
        target.classList.toggle("border-yellow-500");
    }

    const changeCategory = (value : string) => {
        setSelectedCategory(value)
    }

    const selectLaptop = (e : any, value : any) => {
        if(selectedLaptop !== undefined) {
            toggleSelected(e.currentTarget)
            if(e.currentTarget !== selectedLaptopObject) {
                toggleSelected(selectedLaptopObject)
                setSelectedLaptopObject(e.currentTarget)
                setSelectedLaptop(value)
            } else {
                setSelectedLaptop(undefined)
                setSelectedLaptopObject(undefined)
            }
        } else {
            toggleSelected(e.currentTarget)
            setSelectedLaptop(value)
            setSelectedLaptopObject(e.currentTarget)
        }
    }

    const selectLaptopAccessory = (e : any, value : any) => {
        if(selectedLaptopAccessory.includes(value)) {
            document.getElementById(value.id)!.classList.add("selectedEquipment")
            setSelectedLaptopAccessory(selectedLaptopAccessory.filter((element) => {
                return element !== value;
            }))
        } else {
            if(selectedLaptopAccessory.length === 0) {
                setSelectedLaptopAccessory([value])
            } else {
                setSelectedLaptopAccessory([...filterLaptopAccessory(value), value])
                //setSelectedLaptopAccessory([...selectedLaptopAccessory, value])
                document.getElementById(value.id)!.classList.add("selectedEquipment")
            }
        }
    }

    const filterLaptopAccessory = (value : any) => {
        let toFilter: any[] = []
        selectedLaptopAccessory.forEach(accessory => {
            if(accessory.fk_hardware_type_id === value.fk_hardware_type_id) {
                document.getElementById(accessory.id.toString())!.classList.remove("selectedEquipment")
                toFilter.push(accessory)
            }
        })

        let newList : any[] = []
        selectedLaptopAccessory.forEach(accessory => {
            if(!toFilter.includes(accessory)) {
                newList.push(accessory)
            }
        })
        return newList
    }

    const laptopAccessoryToString = () => {
        let string = ""
        selectedLaptopAccessory.forEach((item : any) => {
            string += item.name + ", "
        })
        return string
    }

    const createIdArray = () => {
        let idArray : string[] = []
        if(selectedLaptop !== undefined) {
            idArray.push(selectedLaptop.id.toString())
        }
        selectedLaptopAccessory.forEach((item : any) => {
            idArray.push(item.id.toString())
        })
        return idArray
    }

    const isApple = (accessoryName : string) : boolean => {
        return appleAccessories.includes(accessoryName)
    }

    return (
        <div className="pageWrapper bg-secondary">
            {openLoginFeedback ? <LoginFeedbackDialog open={true} validated={userContext.isAuth} redirect={false}/> : null}
            <NavBar image={"/navbar/toDo.svg"} header={"Choose a gadget"}
                    text={"Since we have an open office, you can sit anywhere and use equipment on the table. But you" +
                        " still need your own computer for work. Here you an choose your preferred type. Subject to change, only Pilot version."}
                    progress={21} category={"start"}/>
            <CustomStoryDialog title="Attention!"
                               text="You got familiar with your setup, so now it’s time to choose gadgets! Please,
                                pay attention when you’ll choose your preference and provide your real surname. Subject to change, only Pilot version!
                                 Additionally some options might not be currently available. Workplace management will contact you if something like that happens."/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner overflow-auto">
                    {selectedCategory === 'laptops' &&
                        <div>
                            <div>
                                <Button variant="contained" color="warning" className="equipmentButton startButton"
                                        onClick={() => changeCategory('laptops')}>Laptops</Button>
                                <Button variant="outlined" color="warning" className="equipmentButton"
                                        onClick={() => changeCategory('laptopAccessories')}>Laptop accessories
                                </Button>
                            </div>
                            <div className="equipmentWrapper">
                                {(() => {
                                    if(hardware !== undefined) {
                                        let content : any = []
                                        hardware.forEach(item => {
                                            if(getHardwareCategory(item.name, selectedCategory)) {
                                                content.push(
                                                    <div className={"laptopWrapper"}>
                                                        <img id={item.id}
                                                             src={envVariables.IMG_SRC + "/images/equipment/" + getEquipmentImage(item.name)}
                                                             alt={item.name} onClick={(e) => selectLaptop(e, item)}
                                                             className={`equipmentIconLarge ${selectedLaptop === item ? 'selectedEquipment' : ''}`} key={item.id}/>
                                                        <CustomTooltip
                                                            text={item.description}/>
                                                    </div>)
                                            }})
                                        return content
                                    }
                                })()}
                                <p className={"text-red-500 font-bold"}>
                                    Please be aware that flex devices need to have
                                    specific business justification and they also do not have standard enrolment
                                    program. Assistance of your manager and team is needed for the initial setup and
                                    configuration.
                                </p>
                            </div>
                        </div>
                    }
                    {selectedCategory === 'laptopAccessories' &&
                        <div>
                            <div>
                                <Button variant="outlined" color="warning" className="equipmentButton"
                                        onClick={() => changeCategory('laptops')}>Laptops</Button>
                                <Button variant="contained" color="warning" className="equipmentButton startButton"
                                        onClick={() => changeCategory('laptopAccessories')}>Accessories
                                </Button>
                            </div>
                            <div className="pt-5 lg:flex xl:block ">
                                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 1.5lg:grid-cols-6 xl:grid-cols-6 gap-y-5">
                                    {(() => {
                                        if(hardware !== undefined) {
                                            let content : any = []
                                            hardware.forEach(item => {
                                                if(!getHardwareCategory(item.name, "laptops") && !isApple(item.name)) {
                                                    content.push(
                                                        <img id={item.id} src={envVariables.IMG_SRC+"/images/equipment/" + getEquipmentImage(item.name)}
                                                             alt={item.name} onClick={(e) => selectLaptopAccessory(e, item)}
                                                             className={`equipmentIconSmall ${selectedLaptopAccessory.indexOf(item) !== -1 ? 'selectedEquipment' : ''}`} key={item.id}/>)
                                                }})
                                            return content
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                    }

                    <div>
                        <h2 className="mt-2 mb-1 text-base md:text-lg 2xl:text-2xl">Your selected equipment:</h2>
                        <ul className="list-disc mt-0 text-sm md:text-base 2xl:text-lg">
                            <li>
                                <code className="font-bold">Laptop:</code> {" "}
                                {selectedLaptop === undefined ? '' : selectedLaptop.name + ', '}
                            </li>
                            <li>
                                <code className="font-bold">Accessories:</code> {" "}
                                {!selectedLaptopAccessory.length ? '' : laptopAccessoryToString()}
                            </li>
                        </ul>
                    </div>
                    <div className="flex justify-end lg:absolute bottom-[10%] lg:right-[4.166666666666666%] 2xl:right-[12.5%] xl:bottom-20">
                        <CustomConfirmationDialog
                            radio={true}
                            buttonText="Order the equipment"
                            title="Confirm that you have entered correct surname"
                            text="After you confirm your surname, request will be sent to our HR
                                  team and your equipment will be waiting for you in the office."
                            item="hardware"
                            redirect={"equipmentWait"}
                            selected={createIdArray()}
                            token={userContext.token}/>
                    </div>
                </div>
            </div>
            {userContext.skip ?
                <PageNavigation choice={true} skip={true} previous="journey" next="foodBenefit"/> :
                <PageNavigation choice={true} skip={true} previous="workplace" next="firstDay"/>
            }
        </div>
    )
}