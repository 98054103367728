export const directionsDataBratislava = [
    {
        title: "1. Enter the building",
        description: "Please arrive at specific time on your first day and approach the front desk and introduce\n         yourself as the new employee. You will receive a guest card so that you can enter the building.",
        image: "Door.png",
        gMapLat: 48.17237272501446,
        gMapLng: 17.14272799278202,
        gMapZoom: 17,
        gMapHeading: 38,
        gMapPitch: 25
    },
    {
        title: "2. Use the elevator",
        description: "When you are in the elevator, select 9th floor.",
        image: "Elevator.png",
        gMapLat: 48.17237272501446,
        gMapLng: 17.14272799278202,
        gMapZoom: 17,
        gMapHeading: 38,
        gMapPitch: 25
    },
    {
        title: "3. Use the telephone",
        description: "When you arrive at 9th floor, use the telephone and call reception to let you in.",
        image: "OfficeEntrance.png",
        gMapLat: 48.17237272501446,
        gMapLng: 17.14272799278202,
        gMapZoom: 17,
        gMapHeading: 38,
        gMapPitch: 25
    }
]

export const directionsDataKosice = [
    {
        title: "1. Enter the building",
        description: "Please arrive at specific time on your first day and approach the welcome desk and introduce\n         yourself as the new employee. You will receive a guest card so that you can enter the building.",
        image: "Door.png",
        gMapLat: 48.7187419,
        gMapLng: 21.2464782,
        gMapZoom: 17,
        gMapHeading: 270,
        gMapPitch: 25
    },
    {
        title: "2. Use the elevator",
        description: "When you are in the elevator, select 6th floor.",
        image: "kosice/Elevator.png",
        gMapLat: 48.7187419,
        gMapLng: 21.2464782,
        gMapZoom: 17,
        gMapHeading: 38,
        gMapPitch: 25
    },
    {
        title: "3. Ring the bell",
        description: "When you arrive at 6th floor, ring the bell and somebody will let you in.",
        image: "kosice/Door.jpeg",
        gMapLat: 48.7187419,
        gMapLng: 21.2464782,
        gMapZoom: 17,
        gMapHeading: 38,
        gMapPitch: 25
    }
]

export const directionsDataVienna = [
    {
        title: "1. Enter the building",
        description: "Please arrive at specific time on your first day and approach the front desk and introduce\n         yourself as the new employee. You will receive a guest card so that you can enter the building.",
        image: "vienna/Door.png",
        gMapLat: 48.1868584,
        gMapLng: 16.3817631,
        gMapZoom: 17,
        gMapHeading: 251,
        gMapPitch: 20
    },
    {
        title: "2. Use the elevator",
        description: "Go to to block where you homebase is and find elevators. When you are in the elevator, select floor of your team's home base.",
        image: "vienna/ViennaMap.png",
        gMapLat: 48.1868584,
        gMapLng: 16.3817631,
        gMapZoom: 17,
        gMapHeading: 251,
        gMapPitch: 20
    },
    {
        title: "3. Use the telephone",
        description: "When you arrive at the selected floor, use the telephone and call reception to let you in.",
        image: "vienna/reception.png",
        gMapLat: 48.1868584,
        gMapLng: 16.3817631,
        gMapZoom: 17,
        gMapHeading: 251,
        gMapPitch: 20
    },
]