import * as React from 'react';
import Tooltip, {tooltipClasses, TooltipProps} from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import {envVariables} from "../constants/Variables";
import {styled} from "@mui/material";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#dff2fd',
        color: '#00497b',
        boxShadow: theme.shadows[1],
        fontSize: 15,
    },
}));

export default function CustomTooltip(props : any) {
    return (
        <LightTooltip enterTouchDelay={200} placement="bottom" TransitionComponent={Zoom} title={props.text} arrow className={'text-2xl text-primary'}>
            <img src={envVariables.IMG_SRC+"/images/equipment/Info.png"}
                 alt="Info"
                 className={"laptopInfo"}/>
        </LightTooltip>
    );
}