import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {envVariables} from "../constants/Variables";
import {Link, useHref} from "react-router-dom";
import {useContext} from "react";
import {UserContext} from "../context/UserProvider";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LogoutIcon from '@mui/icons-material/Logout';
import {Collapse, Divider} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function CustomDrawerMenu(props : any) {
    const userContext = useContext(UserContext)
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [openWelcome, setOpenWelcome] = React.useState(props.welcome);
    const [openStart, setOpenStart] = React.useState(props.start);
    const [openTeam, setOpenTeam] = React.useState(props.team);
    const [openCulture, setOpenCulture] = React.useState(props.culture);

    // const pageNumbersAuth = ["box", "character", "outfit", "directions", "directionsOffice", "workplace", "equipment",
    //     "puzzle", "eat", "foodBenefit", "coloring", "infoSessions", "ideas", "ideasReason", "structure", "metaverse",
    //     "entertainment", "feedback"]
    // const pageNumbersNotAuth = ["character", "outfit", "directions", "directionsOffice", "workplace",
    //     "puzzle", "eat", "coloring", "infoSessions", "ideas", "ideasReason", "structure", "metaverse", "entertainment",
    //     "feedback"]

    const welcomePagesNames = ["Your journey", "Choose your character"]
    const welcomePagesLinks = ["journey", "character"]

    const startPagesNamesAuth = ["Dress code",  "Directions to the office", "Getting to your workplace", "VR Workplace",
        "Choose gadget", "Tech setup", "First day at work", "Card/Money",  "Eating & chilling"]
    const startPagesLinksAuth = ["outfit", "directions", "directionsOffice", "vr",  "equipment", "workplace", "firstDay",
        "foodBenefit", "eat"]

    const startPagesNames = ["Dress code",  "Directions to the office",  "Getting to your workplace", "VR Workplace", "Tech setup", "First day at work",
         "Eating & chilling"]
    const startPagesLinks = ["outfit", "directions", "directionsOffice", "vr", "workplace", "firstDay",  "eat"]

    const teamPagesNamesAuth = ["About my team", "Solve a puzzle"]
    const teamPagesLinksAuth = ["team", "puzzle"]

    const teamPagesNames = ["Solve a puzzle"]
    const teamPagesLinks = ["puzzle"]

    /*const culturePagesNames = ["Company benefits", "Breakfast", "Diversity", "Erste Ideas", "Info sessions",
        "Metaverse immersion", "Entertainment in the office", "Company culture", "Company values", "Company strategy", "Coloring therapy"]
    const culturePagesLinks = ["employeeBenefits", "breakfast", "diversity", "ideas", "infoSessions", "metaverse",
        "entertainment", "culture", "values", "strategy", "coloring"]*/
    const culturePagesNames = ["Company benefits", "Erste Ideas", "Info sessions", "Useful links",
        "Metaverse immersion", "Entertainment in the office", "Company structure", "Company values", "Coloring therapy",
        "Test yourself", "Feedback"]
    const culturePagesLinks = ["employeeBenefits", "ideas", "infoSessions", "usefulLinks", "metaverse",
        "entertainment", "structure", "values", "coloring", "quiz", "feedback"]

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    const resetStorage = () => {
        localStorage.setItem("userName", "Guest");
        userContext.setUserName("Guest");
        localStorage.setItem("isAuth", "false");
        userContext.setIsAuth(false);
        localStorage.setItem("character", "unicorn");
        userContext.setCharacter("unicorn");
        localStorage.setItem("userRole", "guest");
        userContext.setRole("guest");
        localStorage.setItem("id", "0");
        userContext.setId("0");
        localStorage.setItem("token", "0");
        userContext.setToken("0");
    }

    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
        >
            <div className={"flex justify-end"}>
                <HighlightOffIcon onClick={toggleDrawer(anchor, false)} className={"cursor-pointer pt-1.5 pr-1.5"}/>
            </div>
            <List>
                <ListItem key={"logout"} disablePadding>
                    <ListItemButton onClick={resetStorage}>
                        <ListItemIcon className="text-primary">
                            <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText className="" primary={"Logout"} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={"box"} disablePadding>
                    <ListItemButton component={Link} to={'https://www.newcomerjourney.com/VirtualBox'}>
                        <ListItemIcon className="">
                            <img src={envVariables.IMG_SRC+"/images/navbar/box.png"} alt="Virtual Box"
                                 className="w-[24px] h-[24px]"/>
                        </ListItemIcon>
                        <ListItemText className="" primary={"Virtual Box"} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={"journey"} disablePadding>
                    <ListItemButton component={Link} to={'/journey'}>
                        <ListItemIcon className="">
                            <img src={envVariables.IMG_SRC+"/images/navbar/maps.svg"} alt="Main menu"
                                 className="w-[24px] h-[24px]"/>
                        </ListItemIcon>
                        <ListItemText className="" primary={"Journey overview"} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />

            <List>
                <ListItemButton onClick={() => setOpenWelcome(!openWelcome)}
                                className={"bg-welcome text-white hover:bg-welcome hover:text-white rounded-lg mb-2 mx-1"}>
                    <ListItemText primary="Welcome" />
                    {openWelcome ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openWelcome} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            welcomePagesNames.map((text, index) => (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton component={Link} to={'/'+ welcomePagesLinks[index]} sx={{ pl: 4 }}>
                                        <ListItemText className="" primary={text} />
                                    </ListItemButton>
                                </ListItem>
                            ))
                        }
                    </List>
                </Collapse>
                {userContext.isAuth ? (
                    <>
                        <ListItemButton onClick={() => setOpenStart(!openStart)}
                                        className={"bg-start text-white hover:bg-start hover:text-white rounded-lg mb-2 mx-1"}>
                            <ListItemText primary="Before I start..." />
                            {openStart ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openStart} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    startPagesNamesAuth.map((text, index) => (
                                        <ListItem key={text} disablePadding>
                                            <ListItemButton component={Link} to={'/'+ startPagesLinksAuth[index]} sx={{ pl: 4 }}>
                                                <ListItemText className="" primary={text} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Collapse>
                        <ListItemButton onClick={() => setOpenTeam(!openTeam)}
                                        className={"bg-team text-white hover:bg-team hover:text-white rounded-lg mb-2 mx-1"}>
                            <ListItemText primary="Team & I" />
                            {openTeam ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openTeam} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    teamPagesNamesAuth.map((text, index) => (
                                        <ListItem key={text} disablePadding>
                                            <ListItemButton component={Link} to={'/'+ teamPagesLinksAuth[index]} sx={{ pl: 4 }}>
                                                <ListItemText className="" primary={text} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Collapse>
                    </>
                ) : (
                    <>
                        <ListItemButton onClick={() => setOpenStart(!openStart)}
                                        className={"bg-start text-white hover:bg-start hover:text-white rounded-lg mb-2 mx-1"}>
                            <ListItemText primary="Before I start..." />
                            {openStart ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openStart} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    startPagesNames.map((text, index) => (
                                        <ListItem key={text} disablePadding>
                                            <ListItemButton component={Link} to={'/'+ startPagesLinks[index]} sx={{ pl: 4 }}>
                                                <ListItemText className="" primary={text} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Collapse>
                        <ListItemButton onClick={() => setOpenTeam(!openTeam)}
                                        className={"bg-team text-white hover:bg-team hover:text-white rounded-lg mb-2 mx-1"}>
                            <ListItemText primary="Team & I" />
                            {openTeam ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openTeam} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    teamPagesNames.map((text, index) => (
                                        <ListItem key={text} disablePadding>
                                            <ListItemButton component={Link} to={'/'+ teamPagesLinks[index]} sx={{ pl: 4 }}>
                                                <ListItemText className="" primary={text} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Collapse>
                    </>
                )}


                <ListItemButton onClick={() => setOpenCulture(!openCulture)}
                                className={"bg-primary text-white hover:bg-primary hover:text-white rounded-lg mb-2 mx-1"}>
                    <ListItemText primary="Company Culture" />
                    {openCulture ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openCulture} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            culturePagesNames.map((text, index) => (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton component={Link} to={'/'+ culturePagesLinks[index]} sx={{ pl: 4 }}>
                                        <ListItemText className="" primary={text} />
                                    </ListItemButton>
                                </ListItem>
                            ))
                        }
                    </List>
                </Collapse>

            </List>

        </Box>
    );

    return (
        <div>
            {(['right'] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                    <img onClick={toggleDrawer(anchor, true)} src={envVariables.IMG_SRC+"/images/navbar/menu.png"}
                         alt="Journey"
                         className="w-[100%] cursor-pointer"/>
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );
}