import React from 'react';
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";

export default function BeerError() {
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Team & I"}
                    text={"Communication is a very important  thing to work in team."}
                    progress={36} category={"team"}/>
            <div className="contentWrapper">
                <div className="contentWrapperInner">
                    {/*<h1 className={"text-center"}>We are sorry but your manager haven't created any dates for a beer. You will have to wait*/}
                    {/*    until you start working and come to an agreement in person.</h1>*/}
                    <div className={"flex justify-center h-[50%] mb-10"}>
                        <img src={envVariables.IMG_SRC+'/images/BeerError.png'} alt="Member" className="h-[100%]"/>
                    </div>
                    <h3 className={"text-center"}>Or your manager forgot to set up dates in the application.</h3>
                    <p className={"text-center"}>You can either go back or continue to puzzle page.</p>
                </div>
            </div>
            <PageNavigation previous="team" next="puzzle" skip={true} />
        </div>
    )
}