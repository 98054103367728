import React from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";

function UsefulLinks() {
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Useful Links"}
                    text={"Here we provide you with all useful links, where you can access more information."}
                    progress={60} category={"start"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className={"flex"}>
                        <div className={"flex items-center mr-4"}>
                            <img src={envVariables.IMG_SRC+"/images/ExclamationTriangle.png"} alt="Warning" className="h-[50%] lg:h-full lg:max-w-full"/>
                        </div>
                        <p>
                            Please send these links to your new email address and access from the company PC. You can
                            access them from the phone as well, but honestly, it is a pain due to our high security
                            standards ;)
                        </p>
                    </div>
                    <div className="hidden lg:grid grid-cols-5 gap-x-4 gap-y-0 bg-gray-100 px-4 pb-4 mt-4 rounded-xl">
                        <h4>Open Network</h4>
                        <h4>My Links</h4>
                        <h4>Car Sharing</h4>
                        <h4>C&R Finder</h4>
                        <h4>Self service</h4>

                        <p>Our Intranet.</p>
                        <p>You can drag and drop useful links to your profile.</p>
                        <p>For effective business trip travelling.</p>
                        <p>To find colleagues based on skills and responsibilities.</p>
                        <p>If you forget your password or accidentally block your account,
                            this is the place where you can fix it.</p>

                        <img src={envVariables.IMG_SRC+"/images/usefulLinks/Intranet.png"} alt="Intranet" className="linksImage"/>
                        <img src={envVariables.IMG_SRC+"/images/usefulLinks/MyLinks.png"} alt="My Links" className="linksImage"/>
                        <img src={envVariables.IMG_SRC+"/images/usefulLinks/CarSharing.png"} alt="Car Sharing" className="linksImage"/>
                        <img src={envVariables.IMG_SRC+"/images/usefulLinks/CRFinder.png"} alt="C&R Finder" className="linksImage"/>
                        <img src={envVariables.IMG_SRC+"/images/usefulLinks/SelfService.png"} alt="SelfService" className="linksImage"/>


                        <div className={"flex"}>
                            <img src={envVariables.IMG_SRC+"/images/LinkIcon.png"} alt="Link" className="urlImage"/>
                            <a href="https://intranet.ersteopen.net/Portal.Node/portal" target="_blank"
                               rel="noreferrer" className="customLink">Link</a>
                        </div>
                        <div className={"flex"}>
                            <img src={envVariables.IMG_SRC+"/images/LinkIcon.png"} alt="Link" className="urlImage"/>
                            <a href="https://intranet.ersteopen.net/Portal.Node/userpages/links" target="_blank"
                               rel="noreferrer" className="customLink">Link</a>
                        </div>
                        <div className={"flex"}>
                            <img src={envVariables.IMG_SRC+"/images/LinkIcon.png"} alt="Link" className="urlImage"/>
                            <a href="https://erstegroup.sharepoint.com/sites/EDHR/SitePages/Easy,-sustainable-and-cost-effective-traveling.aspx" target="_blank"
                               rel="noreferrer" className="customLink">Link</a>
                        </div>
                        <div className={"flex"}>
                            <img src={envVariables.IMG_SRC+"/images/LinkIcon.png"} alt="Link" className="urlImage"/>
                            <a href="https://ersteideas.ersteapps.com/crfindersearch" target="_blank"
                               rel="noreferrer" className="customLink">Link</a>
                        </div>
                        <div className={"flex"}>
                            <img src={envVariables.IMG_SRC+"/images/LinkIcon.png"} alt="Link" className="urlImage"/>
                            <a href="https://selfservice.sparkasse.at/" target="_blank"
                               rel="noreferrer" className="customLink">Link</a>
                        </div>

                    </div>

                    <div className={"lg:hidden grid grid-cols-1 gap-4"}>
                        <div className={"bg-gray-100 p-5 rounded-xl"}>
                            <h4 className={"mt-0"}>Open Network</h4>
                            <p>Our Intranet.</p>
                            <img src={envVariables.IMG_SRC+"/images/usefulLinks/Intranet.png"} alt="Intranet" className="linksImageMobile"/>
                            <div className={"flex"}>
                                <img src={envVariables.IMG_SRC+"/images/LinkIcon.png"} alt="Link" className="urlImageMobile"/>
                                <a href="https://intranet.ersteopen.net/Portal.Node/portal" target="_blank"
                                   rel="noreferrer" className="customLink">Link</a>
                            </div>
                        </div>
                        <div className={"bg-gray-100 p-5 rounded-xl"}>
                            <h4 className={"mt-0"}>My Links</h4>
                            <p>You can drag and drop useful links to your profile.</p>
                            <img src={envVariables.IMG_SRC+"/images/usefulLinks/MyLinks.png"} alt="My Links" className="linksImageMobile"/>
                            <div className={"flex"}>
                                <img src={envVariables.IMG_SRC+"/images/LinkIcon.png"} alt="Link" className="urlImageMobile"/>
                                <a href="https://intranet.ersteopen.net/Portal.Node/userpages/links" target="_blank"
                                   rel="noreferrer" className="customLink">Link</a>
                            </div>
                        </div>
                        <div className={"bg-gray-100 p-5 rounded-xl"}>
                            <h4 className={"mt-0"}>Car Sharing</h4>
                            <p>For effective business trip travelling.</p>
                            <img src={envVariables.IMG_SRC+"/images/usefulLinks/CarSharing.png"} alt="Car Sharing" className="linksImageMobile"/>
                            <div className={"flex"}>
                                <img src={envVariables.IMG_SRC+"/images/LinkIcon.png"} alt="Link" className="urlImageMobile"/>
                                <a href="https://erstegroup.sharepoint.com/sites/EDHR/SitePages/Easy,-sustainable-and-cost-effective-traveling.aspx" target="_blank"
                                   rel="noreferrer" className="customLink">Link</a>
                            </div>
                        </div>
                        <div className={"bg-gray-100 p-5 rounded-xl"}>
                            <h4 className={"mt-0"}>C&R Finder</h4>
                            <p>To find colleagues based on skills and responsibilities.</p>
                            <img src={envVariables.IMG_SRC+"/images/usefulLinks/CRFinder.png"} alt="C&R Finder" className="linksImageMobile"/>
                            <div className={"flex"}>
                                <img src={envVariables.IMG_SRC+"/images/LinkIcon.png"} alt="Link" className="urlImageMobile"/>
                                <a href="https://ersteideas.ersteapps.com/crfindersearch" target="_blank"
                                   rel="noreferrer" className="customLink">Link</a>
                            </div>
                        </div>
                        <div className={"bg-gray-100 p-5 rounded-xl"}>
                            <h4 className={"mt-0"}>Self service</h4>
                            <p>If you forget your password or accidentally block your account, this is the place where you can fix it.</p>
                            <img src={envVariables.IMG_SRC+"/images/usefulLinks/SelfService.png"} alt="Self service" className="linksImageMobile"/>
                            <div className={"flex"}>
                                <img src={envVariables.IMG_SRC+"/images/LinkIcon.png"} alt="Link" className="urlImageMobile"/>
                                <a href="https://selfservice.sparkasse.at/" target="_blank"
                                   rel="noreferrer" className="customLink">Link</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageNavigation previous="infoSessions" next="metaverse"/>
        </div>
    )
}
export default UsefulLinks;