import React from 'react'
import {useNavigate} from 'react-router-dom'
import {envVariables} from "../constants/Variables";

function Crossroads() {
    const navigate = useNavigate()

    const handleFirstClick = () => {
        window.open('https://newcomerjourney.com', '_blank', 'noopener,noreferrer');
    }

    const handleSecondClick = () => {
        window.open('https://erstedigital.com', '_blank', 'noopener,noreferrer');
    }

    const handleThirdClick = () => {
        navigate('/', {replace: true})
    }

    const handleFourthClick = () => {
        navigate('/character', {replace: true})
    }

    return (
        <div>
            <div className="w-screen flex justify-center md:justify-end bg-primary py-2 md:py-5">
                <div className="w-11/12 2xl:w-9/12">
                    <img src={envVariables.IMG_SRC+"/images/ErsteLogo.png"} alt="Erste Digital logo"
                         className="h-[40px] md:h-[60px] 2xl:h-[80px]"/>
                </div>
            </div>
            <div className="pt-5 md:pt-10 flex justify-center">
                <div className="flex-col justify-center md:grid md:grid-cols-2 md:gap-10 md:pt-14">
                    <div className="crossroadsSection bg-gradient-to-r from-[#ADB2EB] to-[#EC7C8C]"
                         onClick={handleFirstClick}>
                        <h1 className="crossroadsHeader">Get to know my future team
                            <code className="text-sm text-white">(better to use your phone)</code></h1>
                    </div>
                    <div className="crossroadsSection bg-gradient-to-r from-[#7169FC] to-[#EE3598]"
                         onClick={handleSecondClick}>
                        <h1 className="crossroadsHeader">Erste Digital web page</h1>
                    </div>
                    <div className="crossroadsSection bg-gradient-to-r from-[#4DBC4D] to-[#1459E1]"
                         onClick={handleThirdClick}>
                        <h1 className="crossroadsHeader">I accepted the offer and will start working for Erste</h1>
                    </div>
                    <div className="crossroadsSection bg-gradient-to-r from-[#4DBC4D] to-[#1459E1]"
                         onClick={handleFourthClick}>
                        <h1 className="crossroadsHeader">I am just curious about Erste Digital</h1>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Crossroads;