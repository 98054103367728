import React, {useEffect, useState} from "react";
import NavBar from "../components/NavBar";
import {Button, Skeleton} from "@mui/material";
import {Link} from "react-router-dom";
import {envVariables} from "../constants/Variables";

function BoxWait() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, []);

    return (
        <div className="pageWrapper bg-[url('/public/images/backgrounds/WaitBgImg.png')] bg-secondary lg:overflow-hidden">
            <NavBar image={"/navbar/toDo.svg"} header={"CHOOSE YOUR BOX"}
                    text={"Wait for your box"} progress={5}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="flex justify-center lg:h-1/3 mb-10">
                    { loading ? (
                        <Skeleton variant="rounded" className="w-1/2 h-full"/>
                    ) : (
                        <img className="waitImage" src={envVariables.IMG_SRC+"/images/clock.png"} alt="Clock"/>
                    )}
                    </div>
                    <h1 className="text-center text-xl xs:text-3xl">
                        { loading ? <Skeleton variant="rounded" /> :
                        'Thanks! Now relax and wait for your box!' }
                    </h1>
                    <p className="text-center text-warning">
                        { loading ? <Skeleton variant="rounded" /> :
                            'If you are still curious, just go farther and get to know more about the company' }
                    </p>
                    <div className="flex justify-center mt-5 1.5lg:mt-10 mb-10 lg:mb-0">
                        {loading ? <Skeleton variant="rounded" className="w-32 h-12"/> :
                            (<>
                                <Button component={Link} to={'/character'} variant="outlined" color="warning" className="mr-10">I
                                    am curious</Button>
                                <Button component={Link} to={'/boxArrived'} variant="contained" color="warning">Box has arrived</Button>
                            </>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BoxWait;