import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useState} from "react";
import {DialogTransition} from "../../helperFunctions/Transitions";
import ChangeNameDialog from "./ChangeNameDialog";
import {Link} from "react-router-dom";

const Transition = React.forwardRef(DialogTransition);

export default function LoginFeedbackDialog(props : any) {
    const [open, setOpen] = useState(props.open);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className="">{"Login status"}</DialogTitle>
                <DialogContent>
                    {props.validated ? (
                        <DialogContentText id="alert-dialog-slide-description">
                            <span className="">
                                <b className={"text-green-500"}>You have been logged in successfully!</b> Click
                                "Continue" button to continue your journey.</span>
                        </DialogContentText>
                    ) : (
                        <DialogContentText id="alert-dialog-slide-description">
                            <span className=""><b className={"text-red-500"}>Login failed!</b> If you are registered in
                                our newcomer program, please click on "Change surname" and provide us with your
                                correct <b>SURNAME</b>. If you want to continue as guest, click on "Continue" button.
                            </span>
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    {!props.validated && (
                        <ChangeNameDialog />
                    )}
                    {props.redirect ? (
                        <>
                            {props.skip ? (
                                <Button component={Link} to={'/equipment'} color={"warning"} variant={"contained"} onClick={handleClose}>Continue</Button>
                            ) : (
                                <Button component={Link} to={'/character'} color={"warning"} variant={"contained"} onClick={handleClose}>Continue</Button>
                            )}
                        </>
                    ) : (
                        <Button color={"warning"} variant={"contained"} onClick={handleClose}>Continue</Button>
                    )}

                </DialogActions>
            </Dialog>
        </div>
    );
}
