import React from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";

function CompanyValues() {
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"Company values"}
                    text={"#believeinyourself"} progress={81} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <h1 className={"text-center italic text-xl 1.5xl:text-2xl 2xl:text-3xl"}>“No age, gender, social class or nationality shall be excluded from
                        the benefits that a savings bank offers every depositor.”</h1>
                    <div className="valuesWrapper">
                        {(() => {
                            let images = []
                            for (let i = 1; i < 7; i++) {
                                images.push(<img src={envVariables.IMG_SRC+"/images/companyValues/" + i + ".png"}
                                                 alt="Benefit"
                                                 className="max-w-[100%] lg:w-[75%] 1.5xl:w-[80%] 2xl:w-[100%] 3xl:w-[80%]" key={i}/>)
                            }
                            return images
                        })()}
                    </div>
                </div>
            </div>
            <PageNavigation skip={false} previous="structure" next="coloring"/>
        </div>
    )
}
export default CompanyValues;