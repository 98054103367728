import React from "react";
import NavBar from "../components/NavBar";
import PageNavigation from "../components/PageNavigation";
import {envVariables} from "../constants/Variables";

function Print() {
    return (
        <div className="pageWrapper bg-secondary">
            <NavBar image={"/navbar/toDo.svg"} header={"3D Printing"}
                    text={"You can 3D print whatever you want in our office"} progress={69} category={"primary"}/>
            <div className={`contentWrapper`}>
                <div className="contentWrapperInner">
                    <div className="entertainmentTopicWrapper">
                        <div className="col-span-2">
                            <img src={envVariables.IMG_SRC+"/images/print.png"} alt="3D printing"
                                 className="entertainmentImage"/>
                        </div>
                        <div>
                            <h1 className="entertainmentHead">Cool things to 3D print which are actually useful</h1>
                            <p className="entertainmentText">
                                What can 3D printers make? Designers use 3D printers to quickly create product models
                                and prototypes, but they're increasingly being used to make final products, as well.
                                Among the items we made so far are promo stuff, Erste coins, toys for kids, but also,
                                shield holders for hospitals during pandemic. Creativity has no limits, so bring your
                                ideas and print them.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <PageNavigation previous="entertainment" next="football"/>
        </div>
    )
}
export default Print;