import React, {useContext} from 'react'
import { Link } from 'react-router-dom'
import LinearProgressWithLabel from './LinearProgressWithLabel';
import {UserContext} from "../context/UserProvider";
import ChangeNameDialog from "./dialog/ChangeNameDialog";
import {envVariables} from "../constants/Variables";
import CustomDrawerMenu from "./CustomDrawerMenu";

const categories : Record<string, string> = {
    "welcome" : "Welcome",
    "start": "Before I start...",
    "team" : "Team & I",
    "primary" : "Company Culture"
}
function getCategoryName(key: string) {
    return categories[key];
}

function NavBar(props: any) {
    const userContext = useContext(UserContext)

    return (
        <div className={`w-screen flex justify-center py-4 bg-`+props.category}>
            <div className="w-11/12 2xl:w-9/12 grid grid-cols-12 gap-y-0 gap-x-2 lg:gap-2 items-center justify-items-center order-1">
                <div className="col-span-1 hidden lg:block">
                    {/*<img src={envVariables.IMG_SRC+"/images"+props.image} alt="Navbar" className="w-[100%]"/>*/}
                    <img src={envVariables.IMG_SRC+"/images/ErsteLogo.png"} alt="Navbar" className="w-[100%]"/>
                </div>
                <div className="col-span-8 lg:col-span-5 2xl:col-span-4 w-full 2xl:pl-8 order-2">
                    <h1 className="text-white text-base md:text-xl lg:text-2xl mt-0 mb-0 lg:mb-1">{props.header}</h1>
                    <p className="text-white text-[10px] md:text-[15px] lg:text-[17px] m-0">{props.text}</p>
                </div>
                <div className="order-3 hidden 2xl:block"></div>
                <div className="col-span-12 lg:col-span-4 w-full order-6 lg:order-4 flex justify-between lg:block mt-2 lg:mt-0">
                    <h1 className="text-white text-sm lg:text-xl mt-0 mb-0 text-justify flex items-center  lg:block">
                        Your progress</h1>
                    <div className="flex items-center w-[60%] lg:w-[100%]">
                        <LinearProgressWithLabel color={"success"} value={props.progress} />
                    </div>

                    <div className="justify-between items-center pt-2 hidden lg:flex">
                        <p className={"text-secondary font-bold"}>{getCategoryName(props.category)}</p>
                        <div className={"flex items-center"}>
                            <ChangeNameDialog/>
                            <p className="ml-2 text-white text-sm lg:text-base">Provided name: <code className="font-bold text-white">
                                {userContext.userName}</code></p>
                        </div>


                    </div>

                </div>
                <div className="col-span-2 lg:col-span-1 order-4 lg:order-5">
                    <Link to="/"><img src={envVariables.IMG_SRC+"/images/navbar/home.png"} alt="Main menu"
                                                className="w-[100%]"/></Link>
                </div>
                <div className="col-span-2 lg:col-span-1 order-5 lg:order-6">
                    {/*<CustomDrawerMenu welcome={true} start={true} team={true} culture={true}/>*/}
                    {props.category === "welcome" && <CustomDrawerMenu welcome={true} start={false} team={false} culture={false}/>}
                    {props.category === "start" && <CustomDrawerMenu welcome={false} start={true} team={false} culture={false}/>}
                    {props.category === "team" && <CustomDrawerMenu welcome={false} start={false} team={true} culture={false}/>}
                    {props.category === "primary" && <CustomDrawerMenu welcome={false} start={false} team={false} culture={true}/>}
                </div>
            </div>
        </div>
    )
}

export default NavBar;