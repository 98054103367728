export const characterData = [
    {
        title: "UNICORN",
        titleColor: "text-[#80407d]",
        description: "work dedicated employee",
        descriptionColor: "text-[#51b7b7]",
        text: "I always give 100% at Work: 10% Monday, 23% Tuesday, 40% Wednesday, 22% Thursday, and 5% Friday.",
        textColor: "text-black",
        author: "-Anonymous",
        background: "bg-[url('/public/images/characters/UnicornBg.png')]",
        mobileBackground: "bg-[url('/public/images/characters/UnicornBgMobile.png')]"
    },
    {
        title: "SLOTH",
        titleColor: "text-[#FEFBD0]",
        description: "highly productive coder",
        descriptionColor: "text-[#c0ec92]",
        text: "Doing nothing is very hard to do ... you never know when you’re finished...",
        textColor: "text-white",
        author: "-Leslie Nielson",
        background: "bg-[url('/public/images/characters/SlothBg.png')]",
        mobileBackground: "bg-[url('/public/images/characters/SlothBgMobile.png')]"
    },
    {
        title: "BEE",
        titleColor: "text-[#3D2E29]",
        description: "hard-working employee",
        descriptionColor: "text-[#F8BA2B]",
        text: "Success isn't always about greatness, it's about consistency. Consistent, hard work gains success. Greatness will come. ",
        textColor: "text-black",
        author: "-Dwayne “The Rock” Johnson",
        background: "bg-[url('/public/images/characters/BeeBg.png')]",
        mobileBackground: "bg-[url('/public/images/characters/BeeBgMobile.png')]"
    }
];